<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="委託契約書編集" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- 基本情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">基本情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.activeStartDate | dateJpYMDddFormat}}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.activeEndDate | dateJpYMDddFormat }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>更新中</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-checkbox">
                  <input
                      id="isHq-cb"
                      type="checkbox"
                      v-model="form.isUpdate"
                  />
                  <label class="c-checkbox__label" for="isHq-cb">
                    <span class="c-checkbox__box"></span>
                    更新中
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 担当者情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">担当者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.registMemberName"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="12"
                    :isValid.sync="validate.registMemberName.isValid"
                    :errMsg.sync="validate.registMemberName.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.generateMemberName"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="12"
                    :isValid.sync="validate.generateMemberName.isValid"
                    :errMsg.sync="validate.generateMemberName.errMsg"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 受渡確認票 / 一括登録通知配布先 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">受渡確認票 / 一括登録通知配布先</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送信フラグ</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-checkbox">
                  <input
                      id="is-delivery-confirm-send-flag"
                      type="checkbox"
                      v-model="form.entrustDeliveryConfirmation.isDeliveryConfirmSendFlag"
                  />
                  <label class="c-checkbox__label" for="is-delivery-confirm-send-flag">
                    <span class="c-checkbox__box"></span>
                    送信する
                  </label>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（排出）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.generateDeliveryConfirmMail"
                    placeholder="入力してください"
                    tooltip="メールアドレスを入力してください"
                    :validation="validateMail"
                    :isValid.sync="validate.generateDeliveryConfirmMail.isValid"
                    :errMsg.sync="validate.generateDeliveryConfirmMail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（運搬）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.collectDeliveryConfirmMail"
                    placeholder="入力してください"
                    tooltip="メールアドレスを入力してください"
                    :validation="validateMail"
                    :isValid.sync="validate.collectDeliveryConfirmMail.isValid"
                    :errMsg.sync="validate.collectDeliveryConfirmMail.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>送付先email（処分）</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.disposalDeliveryConfirmMail"
                    placeholder="入力してください"
                    tooltip="メールアドレスを入力してください"
                    :validation="validateMail"
                    :isValid.sync="validate.disposalDeliveryConfirmMail.isValid"
                    :errMsg.sync="validate.disposalDeliveryConfirmMail.errMsg"
                />
              </div>
            </div>

            <template v-for="(anyMail, anyMailIndex) in form.entrustDeliveryConfirmation.anyMailList">
              <div class="c-inputWrap" :key="`anyMail-item-${anyMailIndex}`">
                <div class="c-inputWrap__label">
                  <label>送付先email{{anyMailIndex + 1}}</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="l-fex start">
                    <InputText
                        :value.sync="form.entrustDeliveryConfirmation.anyMailList[anyMailIndex]"
                        placeholder="入力してください"
                        tooltip="メールアドレスを入力してください"
                        :validation="validateMail"
                        :isValid.sync="validate.anyMailList[anyMailIndex].isValid"
                        :errMsg.sync="validate.anyMailList[anyMailIndex].errMsg"
                        class="c-inline"
                    />
                </div>
              </div>
            </div>
            </template>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報1</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo1"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo1.isValid"
                    :errMsg.sync="validate.qrInfo1.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報2</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo2"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo2.isValid"
                    :errMsg.sync="validate.qrInfo2.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報3</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo3"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo3.isValid"
                    :errMsg.sync="validate.qrInfo3.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報4</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo4"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo4.isValid"
                    :errMsg.sync="validate.qrInfo4.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>QR情報5</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="form.entrustDeliveryConfirmation.qrInfo5"
                    placeholder="入力してください"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.qrInfo5.isValid"
                    :errMsg.sync="validate.qrInfo5.errMsg"
                />
              </div>
            </div>
          </div>
        </div>

        <!-- 排出事業者情報 -- generateFuncs.js ======================================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">排出事業者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.generateCompanyName }}
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業所名</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !tempVal.generateCompany }">
                <div class="c-inputWrap__items__cols wrap">
                  <div
                    class="c-checkbox"
                    v-for="gf in generateFactoryIdList"
                    :key="gf.id"
                    :class="{ disabled: initialgenerateCompanyIds.includes(gf.id) }"
                  >
                    <input
                      :id="'formEdit-' + gf.id"
                      type="checkbox"
                      name="gf-type"
                      :value="gf"
                      v-model="tempVal.generateFactory"
                      @change="changeGenerateFactoryHandler"
                    />
                    <label class="c-checkbox__label" :for="'formEdit-' + gf.id">
                      <span class="c-checkbox__box"></span>
                      {{ gf.name }}
                    </label>
                  </div>
                </div>
                <div
                  v-if="validate.generateFactoryIdList.errMsg.length"
                  class="c-input__errMsg"
                >
                  {{ validate.generateFactoryIdList.errMsg }}
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業場名</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !tempVal.generateFactory || !tempVal.generateFactory.length }">
                <button
                  class="c-btn secondary func add l-item"
                  v-if="tempVal.generateFactory.length"
                  @click="isGenerateStoreSelectModalShow = true"
                >
                  排出事業場選択
                </button>
                <div
                  v-if="validate.generateFactoryIdList.errMsg.length"
                  class="c-input__errMsg"
                >
                  {{ validate.generateFactoryIdList.errMsg }}
                </div>
              </div>
            </div>
            <div class="c-inputWrap" v-for="(gs,index) in form.generateStoreList"  :key="gs.id">
              <div class="c-inputWrap__label">
                <label>{{ gs.name }}</label>
              </div>
              <div class="c-inputWrap__items">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>利用開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="gs.contractStartDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :disabled="gs.entrustGenerateStoreId ? true : false"
                  :isValid.sync="
                    validate.generateStore[index].contractStartDate.isValid
                  "
                  :errMsg.sync="
                    validate.generateStore[index].contractStartDate.errMsg
                  "
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>利用終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="gs.contractEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.generateStore[index].contractEndDate.isValid
                  "
                  :errMsg.sync="
                    validate.generateStore[index].contractEndDate.errMsg
                  "
                />
              </div>
            </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 廃棄物情報 -- entrustWaste.js ================================================================ -->
        <div
          v-for="(entrustWaste, entrustWasteIndex) in form.entrustWasteList"
          :key="`entrust-waste-${entrustWasteIndex}`"
          class="l-contents l-area"
        >
          <div class="l-box title-wrap u-space-btwn">
            <h3 class="c-lead icon">廃棄物情報{{ entrustWasteIndex + 1 }}</h3>
            <div
              v-if="!entrustWaste.wasteTypeName"
              class="c-btn secondary sm delete"
              @click="() => {
                removeWasteInfoHandler(entrustWasteIndex);
                isStartDateRequired = true;
              }"
            >
              削除
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label" :class="{ required: !entrustWaste.wasteTypeName }">
                <label>廃棄物区分</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="entrustWaste.wasteTypeName">
                  {{ entrustWaste.wasteTypeName  }}
                </template>
                <div v-else class="c-inputWrap__items__cols wrap" :class="{ disabled: entrustWasteIndex > 0 }">
                  <div class="c-radio" v-for="wt in wasteTypeList" :key="wt.id">
                    <input
                        :id="'waste-type-' + entrustWasteIndex + '-' + wt.id"
                        type="radio"
                        :name="`waste-type-${entrustWasteIndex}`"
                        :value="wt.id"
                        v-model="entrustWaste.wasteTypeId"
                        @change="syncWasteTypeId"
                    />
                    <label
                        class="c-radio__label"
                        :for="'waste-type-' + entrustWasteIndex + '-' + wt.id"
                    >
                      <span class="c-radio__box"></span>
                      {{ wt.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>マニフェスト区分</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="entrustWaste.manifestTypeName">
                  {{ entrustWaste.manifestTypeName  }}
                </template>
                <div v-else class="c-inputWrap__items__cols wrap">
                  <div
                      class="c-radio"
                      v-for="mt in manifestTypeList"
                      :key="mt.id"
                      :class="{ disabled: entrustWaste.wasteTypeId !== wasteType.INDUSTRIAL_WASTE }"
                  >
                    <input
                        :id="'manifest-type-' + entrustWasteIndex + '-' + mt.id"
                        type="radio"
                        :name="`manifest-type-${entrustWasteIndex}`"
                        :value="mt.id"
                        v-model="entrustWaste.manifestTypeId"
                    />
                    <label
                        class="c-radio__label"
                        :for="'manifest-type-' + entrustWasteIndex + '-' + mt.id"
                    >
                      <span class="c-radio__box"></span>
                      {{ mt.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物種類</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="entrustWaste.manifestTypeName && jwnetItemsOpt.length">
                  {{ jwnetItemsOpt.filter(i => i.id === entrustWaste.jwnetItemTypeId)[0].name  }}
                </template>
                <Selectbox
                  v-else
                  :value.sync="entrustWaste.jwnetItemTypeId"
                  :validation="validateJustBlank"
                  :change="
                    () => {
                      changeJwnetItemTypeHandler(entrustWaste);
                      checkStartDateAble();
                    }
                  "
                  :isValid.sync="
                    validate.wasteInfo[entrustWasteIndex].jwnetItemTypeId
                      .isValid
                  "
                  :errMsg.sync="
                    validate.wasteInfo[entrustWasteIndex].jwnetItemTypeId.errMsg
                  "
                >
                  <option
                    v-for="item in jwnetItemsOpt"
                    :key="'jwnet-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>CBA品目</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustWaste.jwnetItemTypeId }">
                <Selectbox
                  :value.sync="entrustWaste.cbaItemId"
                  :validation="validateJustBlank"
                  :change="
                    () => changeCbaItemTypeHandler(entrustWaste, entrustWasteIndex)
                  "
                  :isValid.sync="
                    validate.wasteInfo[entrustWasteIndex].cbaItemId.isValid
                  "
                  :errMsg.sync="
                    validate.wasteInfo[entrustWasteIndex].cbaItemId.errMsg
                  "
                >
                  <option
                    v-for="item in entrustWaste.cbaItemOpts"
                    :key="'jwnet-item-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物名称</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustWaste.cbaItemId }">
                <Selectbox
                  :value.sync="entrustWaste.wasteNameId"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.wasteInfo[entrustWasteIndex].wasteNameId.isValid
                  "
                  :errMsg.sync="
                    validate.wasteInfo[entrustWasteIndex].wasteNameId.errMsg
                  "
                >
                  <option
                    v-for="item in entrustWaste.wasteNameOpts"
                    :key="'wn-' + item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>構成種類</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustWaste.jwnetItemTypeId }">
                <div
                  v-for="(
                    constitutionType, constitutionIndex
                  ) in entrustWaste.constitutionItemIdList"
                  :key="constitutionIndex"
                  class="l-flex start"
                >
                  <template>
                    <Selectbox
                      v-if="constitutionIndex === 0"
                      class="l-area"
                      :value.sync="constitutionType.constitutionItemId"
                      :validation="validateJustBlank"
                      :isValid.sync="
                        validate.wasteInfo[entrustWasteIndex].constitutionItemId
                          .isValid
                      "
                      :errMsg.sync="
                        validate.wasteInfo[entrustWasteIndex].constitutionItemId
                          .errMsg
                      "
                    >
                      <option
                        v-for="item in constitutionItemList"
                        :key="'sc-' + item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </Selectbox>
                    <Selectbox
                      v-else
                      class="l-area"
                      :value.sync="constitutionType.constitutionItemId"
                      :validation="validateJustBlank"
                    >
                      <option
                        v-for="item in constitutionItemList"
                        :key="'sc-' + item.id"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </Selectbox>
                  </template>
                  <div
                    v-if="constitutionIndex"
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="
                      removeConstitutionHandler(entrustWaste, constitutionType)
                    "
                  >
                    削除
                  </div>
                </div>
                <div
                  class="c-btn secondary add sm"
                  @click="addConstitutionHandler(entrustWaste)"
                >
                  構成種類を追加
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>予定数量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="entrustWaste.plansQuantity"
                    placeholder="予定数量"
                    tooltip="整数5桁、小数点1桁、小数部3桁で入力してください"
                    :validation="validateQuantity"
                    :isValid.sync="validate.wasteInfo[entrustWasteIndex].plansQuantity.isValid"
                    :errMsg.sync="validate.wasteInfo[entrustWasteIndex].plansQuantity.errMsg"
                  />
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.plansWasteUnitTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="opt in wasteUnitOpt"
                      :key="'unit-opt-1-' + opt.id"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <span>/</span>
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.plansWasteTermTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="term in termTypes"
                      :key="'term-1-' + term.id"
                      :value="term.id"
                    >
                      {{ term.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>上限数量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="entrustWaste.limitQuantity"
                    placeholder="上限数量"
                    tooltip="半角数値で入力してください"
                    :validation="validateNumber"
                  />
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.limitWasteUnitTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="opt in wasteUnitOpt"
                      :key="'unit-opt-2-' + opt.id"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <span>/</span>
                  <Selectbox
                    class="sm"
                    :value.sync="entrustWaste.limitWasteTermTypeId"
                    :validation="validateJustBlank"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="term in termTypes"
                      :key="'term-2-' + term.id"
                      :value="term.id"
                    >
                      {{ term.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>荷姿</label>
              </div>
              <div class="c-inputWrap__items">
                <div
                  v-for="(
                    shapeType, shapeIndex
                  ) in entrustWaste.shapeTypeIdList"
                  :key="shapeIndex"
                  class="l-flex start"
                >
                  <template>
                    <Selectbox
                      v-if="shapeIndex === 0"
                      class="l-area"
                      :value.sync="shapeType.shapeTypeId"
                      :validation="validateJustBlank"
                      :isValid.sync="
                        validate.wasteInfo[entrustWasteIndex].shapeTypeId
                          .isValid
                      "
                      :errMsg.sync="
                        validate.wasteInfo[entrustWasteIndex].shapeTypeId.errMsg
                      "
                    >
                      <option
                        v-for="shape in shapeOpt"
                        :key="'shape-' + shapeIndex + '-' + shape.id"
                        :value="shape.id"
                      >
                        {{ shape.name }}
                      </option>
                    </Selectbox>
                    <Selectbox
                      v-else
                      class="l-area"
                      :value.sync="shapeType.shapeTypeId"
                      :validation="validateJustBlank"
                    >
                      <option
                        v-for="shape in shapeOpt"
                        :key="'shape-' + shapeIndex + '-' + shape.id"
                        :value="shape.id"
                      >
                        {{ shape.name }}
                      </option>
                    </Selectbox>
                  </template>
                  <div
                    v-if="shapeIndex"
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="() => {
                      removeShapeHandler(entrustWaste, shapeType);
                      isStartDateRequired = true;
                    }"
                  >
                    削除
                  </div>
                </div>
                <div
                  class="c-btn secondary add sm"
                  @click="() => {
                    addShapeHandler(entrustWaste);
                    isStartDateRequired = true;
                  }"
                >
                  荷姿を追加
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分方法</label>
              </div>
              <div class="c-inputWrap__items">
                <div
                  v-for="(
                    wayType, wayTypeIndex
                  ) in entrustWaste.disposalWayType"
                  :key="wayTypeIndex"
                  class="l-flex start"
                >
                  <template>
                    <Selectbox
                      v-if="wayTypeIndex === 0"
                      class="l-area"
                      :value.sync="wayType.disposalWayTypeId"
                      :validation="validateJustBlank"
                      :isValid.sync="
                        validate.wasteInfo[entrustWasteIndex].disposalWayTypeId
                          .isValid
                      "
                      :errMsg.sync="
                        validate.wasteInfo[entrustWasteIndex].disposalWayTypeId
                          .errMsg
                      "
                    >
                      <option :value="null"></option>
                      <option
                        v-for="wayType in disposalWayTypes"
                        :key="'way-' + wayTypeIndex + '-' + wayType.id"
                        :value="wayType.id"
                      >
                        {{ wayType.name }}
                      </option>
                    </Selectbox>
                    <Selectbox
                      v-else
                      class="l-area"
                      :value.sync="wayType.disposalWayTypeId"
                      :validation="validateJustBlank"
                    >
                      <option :value="null"></option>
                      <option
                        v-for="wayType in disposalWayTypes"
                        :key="'way-' + wayTypeIndex + '-' + wayType.id"
                        :value="wayType.id"
                      >
                        {{ wayType.name }}
                      </option>
                    </Selectbox>
                  </template>
                  <div
                    v-if="wayTypeIndex"
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="() => {
                      removeWayTypeHandler(entrustWaste, wayType);
                      isStartDateRequired = true;
                    }"
                  >
                    削除
                  </div>
                </div>
                <div
                  class="c-btn secondary add sm"
                  @click="() => {
                      addWayTypeHandler(entrustWaste);
                      isStartDateRequired = true;
                    }"
                >
                  処分方法を追加
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>適正処理に必要な情報</label>
              </div>
              <div class="c-inputWrap__items">
                <MyTextarea
                  :value.sync="entrustWaste.processInfo"
                  rows="6"
                  placeholder="入力してください"
                  :validation="validateJustBlank"
                ></MyTextarea>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>WDS</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="!entrustWaste.wdsFileName">
                <input :id="'file-upload-' + entrustWasteIndex"
                       type="file"
                       @change="(e) => uploadWDSFile(e, entrustWasteIndex)"
                />
                <label
                  class="c-btn secondary upload sm"
                  :for="'file-upload-' + entrustWasteIndex"
                >
                  WDSアップロード
                </label>
                </template>
                <template v-else>
                  <div class="l-flex start">
                    <div class="l-area contract-file">
                      {{ entrustWaste.wdsFileName }}
                    </div>
                    <label class="c-btn secondary delete sm contract-file-delete-button"
                      @click="deleteWDS(entrustWasteIndex)"
                    >
                      削除
                    </label>
                  </div>
                </template>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>利用開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="entrustWaste.activeStartDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :disabled="entrustWaste.wasteTypeName ? true : false"
                  :isValid.sync="validate.wasteInfo[entrustWasteIndex].activeStartDate.isValid"
                  :errMsg.sync="validate.wasteInfo[entrustWasteIndex].activeStartDate.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>利用終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="entrustWaste.activeEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.wasteInfo[entrustWasteIndex].activeEndDate.isValid"
                  :errMsg.sync="validate.wasteInfo[entrustWasteIndex].activeEndDate.errMsg"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="l-box">
          <button class="c-btn secondary func add" @click="addWasteInfoHandler">
            廃棄物情報を追加
          </button>
        </div>

        <!-- 運搬委託契約先情報 -- entrustCollect.js ======================================== -->
        <div
          v-for="(
            entrustCollect, entrustCollectIndex
          ) in form.entrustCollectCompanyInfo"
          :key="`entrust-collect-${entrustCollectIndex}`"
          class="l-contents l-area"
        >
          <div class="l-box title-wrap u-space-btwn">
            <h3 class="c-lead icon">運搬委託契約先情報{{ entrustCollectIndex + 1 }}</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label" :class="{ required: !entrustCollect.collectCompanyName }">
                <label>運搬事業者</label>
              </div>
              <div class="c-inputWrap__items">
                <template v-if="entrustCollect.collectCompanyName">
                  {{ entrustCollect.collectCompanyName }}
                </template>
                <template v-else>
                  <Selectbox
                    :value.sync="entrustCollect.collectCompanyId"
                    :validation="validateJustBlank"
                    :change="() => changeCollectCompanyIdHandler(entrustCollect)"
                    :isValid.sync="validate.entrustCollectCompanyInfo[entrustCollectIndex].collectCompanyId.isValid"
                    :errMsg.sync="validate.entrustCollectCompanyInfo[entrustCollectIndex].collectCompanyId.errMsg"
                  >
                    <option
                        v-for="collect in collectCompanyList"
                        :key="'collect-' + entrustCollectIndex + '-' + collect.id"
                        :value="collect.id"
                    >
                      {{ collect.name }}
                    </option>
                  </Selectbox>
                </template>
              </div>
            </div>

            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.INDUSTRIAL_WASTE">
              <div class="c-inputWrap__label">
                <label>運搬許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled: !entrustCollect.collectCompanyId,
                  }"
                  @click="clickShowCollectLicenceModalHandler(entrustCollect)"
                >
                  運搬許可証番号選択
                </button>
                <ul
                  v-if="entrustCollect.collectLicenceInfo.length"
                  class="c-commaList"
                >
                  <li
                    v-for="cl in entrustCollect.collectLicenceInfo"
                    :key="cl.id"
                  >
                    {{ cl.licenceNo }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.COMMON_WASTE">
              <div class="c-inputWrap__label">
                <label>運搬許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled: !entrustCollect.collectCompanyId,
                  }"
                  @click="clickShowCollectCommonLicenceModalHandler(entrustCollect)"
                >
                  運搬許可証番号選択
                </button>
                <ul
                  v-if="entrustCollect.commonCollectLicenceInfo.length"
                  class="c-commaList"
                >
                  <li
                    v-for="cl in entrustCollect.commonCollectLicenceInfo"
                    :key="cl.id"
                  >
                    {{ cl.licenceNo }}
                  </li>
                </ul>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="entrustCollect.contractStartDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractStartDate.isValid
                  "
                  :errMsg.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractStartDate.errMsg
                  "
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="entrustCollect.contractEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractEndDate.isValid
                  "
                  :errMsg.sync="
                    validate.entrustCollectCompanyInfo[entrustCollectIndex]
                      .contractEndDate.errMsg
                  "
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約自動更新</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      :id="`collect-auto-update-${entrustCollectIndex}-true`"
                      type="radio"
                      :name="`collect-auto-update-${entrustCollectIndex}`"
                      :value="true"
                      v-model="entrustCollect.isAutoUpdate"
                    />
                    <label
                      class="c-radio__label"
                      :for="`collect-auto-update-${entrustCollectIndex}-true`"
                    >
                      <span class="c-radio__box"></span>
                      有
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      :id="`collect-auto-update-${entrustCollectIndex}-false`"
                      type="radio"
                      :name="`collect-auto-update-${entrustCollectIndex}`"
                      :value="false"
                      v-model="entrustCollect.isAutoUpdate"
                    />
                    <label
                      class="c-radio__label"
                      :for="`collect-auto-update-${entrustCollectIndex}-false`"
                    >
                      <span class="c-radio__box"></span>
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬単価</label>
              </div>
              <div class="c-inputWrap__items l-flex start" v-if="form.entrustCollectCompanyInfo && form.entrustCollectCompanyInfo[entrustCollectIndex].entrustCollectUnitPriceList && form.entrustCollectCompanyInfo[entrustCollectIndex].entrustCollectUnitPriceList.filter(e => e.id && e.unitPrice).length">
                <div class="c-text text-link open-modal-link"
                @click="showCollectUnitListModal(entrustCollectIndex)">
                  運搬単価一覧
                </div>
                <label class="c-btn secondary delete sm contract-file-delete-button"
                @click="removeCollectUnitPriceList(entrustCollectIndex)">
                  削除
                </label>
              </div>
              <div v-else>
                <div class="c-inputWrap__items">
                  <input
                      value=null
                      :id="'csv-file-' + entrustCollectIndex"
                      type="file"
                      accept="text/csv"
                      @change="(e) => fileChangeCollect(e, entrustCollectIndex)"
                      @click="(e) => e.target.value = null"
                  />
                  <label
                      class="c-btn secondary upload sm"
                      :for="'csv-file-' + entrustCollectIndex"
                  >
                    CSVアップロード
                  </label>
                  <div
                      v-if="entrustCollect.collectUnitPriceFileName"
                      class="l-flex start"
                  >
                    <div class="l-area contract-file">
                      {{entrustCollect.collectUnitPriceFileName}}
                    </div>
                    <label
                      class="c-btn secondary delete sm contract-file-delete-button"
                      @click="removeCollectUnitPriceFile(entrustCollectIndex)">
                      削除
                    </label>
                  </div>
                  <template v-if="csvCollectErrorMsgs.length > 0 && csvCollectErrorMsgs[entrustCollectIndex]">
                    <p v-for="(msg, i) in csvCollectErrorMsgs[entrustCollectIndex]" :key="'collect-err-msg-' + i" class="c-input__errMsg">{{ msg }}</p>
                  </template>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約書PDF</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                  :id="`collect-pdf-upload-${entrustCollectIndex}`"
                  type="file"
                  accept="application/pdf"
                  @change="(e) => uploadCollectContractPdf(e, entrustCollectIndex)"
                />
                <label
                  class="c-btn secondary upload sm"
                  :for="`collect-pdf-upload-${entrustCollectIndex}`"
                >
                  PDFアップロード
                </label>
                <div
                    v-for="(file, index) in entrustCollect.entrustCollectContractFileList" 
                    :key="file.contractFileName"
                    class="l-flex start"
                >
                  <!-- 容量制限エラーメッセージ表示領域 -->
                  <span v-if="file.contractFileMsg" class="c-input__errMsg">{{ file.contractFileMsg }}</span>
                  <div class="l-area contract-file">
                    {{file.contractFileName}}
                  </div>
                  <label
                    class="c-btn secondary delete sm contract-file-delete-button"
                    @click="removeContractFile(entrustCollectIndex, index)"
                  >
                    削除
                  </label>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <MyTextarea
                  :value.sync="entrustCollect.remarks"
                  rows="6"
                  placeholder="入力してください"
                  :validation="validateJustBlank"
                ></MyTextarea>
              </div>
            </div>
          </div>
        </div>

        <!-- 処分委託契約先情報  -- disposal.js ======================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">処分委託契約先情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分事業者</label>
              </div>
              <div class="c-inputWrap__items">
                {{ form.entrustDisposalCompanyInfo.disposalCompanyName }}
              </div>
            </div>
            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.INDUSTRIAL_WASTE">
              <div class="c-inputWrap__label">
                <label>処分許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled:
                      !form.entrustDisposalCompanyInfo.disposalCompanyId,
                  }"
                  @click="isDisposalLicenceSelectModalShow = true"
                >
                  処分許可証番号選択
                </button>
                <ul
                  v-if="
                    form.entrustDisposalCompanyInfo.disposalLicenceInfo.length
                  "
                  class="c-commaList"
                >
                  <li
                    v-for="gs in form.entrustDisposalCompanyInfo
                      .disposalLicenceInfo"
                    :key="gs.id"
                  >
                    {{ gs.licenceNo }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="c-inputWrap" v-if="form.entrustWasteList.length > 0 && form.entrustWasteList[0].wasteTypeId === wasteType.COMMON_WASTE">
              <div class="c-inputWrap__label">
                <label>処分許可証番号</label>
              </div>
              <div class="c-inputWrap__items">
                <button
                  class="c-btn secondary func add l-item"
                  :class="{
                    disabled:
                      !form.entrustDisposalCompanyInfo.disposalCompanyId,
                  }"
                  @click="isDisposalCommonLicenceSelectModalShow = true"
                >
                  処分許可証番号選択
                </button>
                <ul
                  v-if="
                    form.entrustDisposalCompanyInfo.commonDisposalLicenceInfo.length
                  "
                  class="c-commaList"
                >
                  <li
                    v-for="gs in form.entrustDisposalCompanyInfo
                      .commonDisposalLicenceInfo"
                    :key="gs.id"
                  >
                    {{ gs.licenceNo }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約開始日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="
                    form.entrustDisposalCompanyInfo.contractStartDate
                  "
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractStartDate.isValid"
                  :errMsg.sync="validate.contractStartDate.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="form.entrustDisposalCompanyInfo.contractEndDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.contractEndDate.isValid"
                  :errMsg.sync="validate.contractEndDate.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>契約自動更新</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      id="auto-update-true"
                      type="radio"
                      name="auto-update"
                      :value="true"
                      v-model="form.entrustDisposalCompanyInfo.isAutoUpdate"
                    />
                    <label class="c-radio__label" for="auto-update-true">
                      <span class="c-radio__box"></span>
                      有
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="auto-update-false"
                      type="radio"
                      name="auto-update"
                      :value="false"
                      v-model="form.entrustDisposalCompanyInfo.isAutoUpdate"
                    />
                    <label class="c-radio__label" for="auto-update-false">
                      <span class="c-radio__box"></span>
                      無
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分単価</label>
              </div>
              <div class="c-inputWrap__items l-flex start" v-if="form.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList && form.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList.filter(e => e.id && e.unitPrice).length > 0">
                <div class="c-text text-link open-modal-link"
                @click="showDisposalUnitListModal()">
                  処分単価一覧
                </div>
                <label class="c-btn secondary delete sm contract-file-delete-button"
                @click="removeDisposalUnitPriceList()">
                  削除
                </label>
              </div>
              <div v-else>
                <div class="c-inputWrap__items">
                  <input
                      value=null
                      :id="'disposal-csv-file'"
                      type="file"
                      accept="text/csv"
                      @change="(e) => fileChangeDisposal(e)"
                      @click="(e) => e.target.value = null"
                  />
                  <label
                      class="c-btn secondary upload sm"
                      :for="'disposal-csv-file'"
                  >
                    CSVアップロード
                  </label><div
                    v-if="form.entrustDisposalCompanyInfo.disposalUnitPriceFileName"
                    class="l-flex start"
                >
                  <div class="l-area contract-file">
                    {{ form.entrustDisposalCompanyInfo.disposalUnitPriceFileName }}
                  </div>
                  <label
                      class="c-btn secondary delete sm contract-file-delete-button"
                      @click="removeDisposalUnitPriceFile()"
                  >
                    削除
                  </label>
                </div>
                <template v-if="csvDisposalErrorMsgs.length > 0">
                  <p v-for="(msg, i) in csvDisposalErrorMsgs" :key="'disposal-err-msg-' + i" class="c-input__errMsg">{{ msg }}</p>
                </template>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>契約書PDF</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                    id="disposal-pdf-upload"
                    type="file"
                    accept="application/pdf"
                    @change="(e) => uploadDisposalContractPdf(e)"
                />
                <label
                    class="c-btn secondary upload sm"
                    for="disposal-pdf-upload"
                >
                  PDFアップロード
                </label>
                <div
                    v-for="(file, index) in form.entrustDisposalCompanyInfo.entrustDisposalContractFileList"
                    :key="file.contractFileName"
                    class="l-flex start"
                >
                <span v-if="file.contractFileMsg" class="c-input__errMsg">{{ file.contractFileMsg }}</span>
                <div class="l-area contract-file">
                  {{file.contractFileName}}
                </div>
                    <label
                      class="c-btn secondary delete sm contract-file-delete-button"
                      @click="removeDisposalContractFile(index)"
                    >
                      削除
                    </label>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>備考</label>
              </div>
              <div class="c-inputWrap__items">
                <MyTextarea
                  :value.sync="form.entrustDisposalCompanyInfo.remarks"
                  rows="6"
                  placeholder="入力してください"
                  :validation="validateJustBlank"
                ></MyTextarea>
              </div>
            </div>
          </div>
        </div>

        <!-- 経路情報  -- collectRoute.js ======================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">経路情報</h3>
          </div>
          <div
            v-for="(
              entrustCollect, entrustCollectRouteIndex
            ) in form.entrustCollectCompanyInfo"
            :key="`entrust-collect-${entrustCollectRouteIndex}`"
            class="l-box"
          >
            <div class="c-inputWrap sub-title">
              <div>
                <label>区間{{ entrustCollect.sectionNo }}</label>
              </div>
            </div>
            <!-- １区間目用 -->
            <div class="c-inputWrap" v-if="entrustCollectRouteIndex === 0">
              <div class="c-inputWrap__label required">
                <label>積み込み場所</label>
              </div>
              <div class="c-inputWrap__items">
                <ul>
                  <li>{{ form.generateStoreList.map(s => s.name).join("、") }}</li>
                </ul>
              </div>
            </div>

            <!-- 2区間目以後用 -->
            <div class="c-inputWrap" v-else-if="entrustCollectRouteIndex > 0 && form.entrustCollectCompanyInfo[entrustCollectRouteIndex - 1].collectLicenceInfo.length > 0">
              <div class="c-inputWrap__label required">
                <label>積み込み場所</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  {{ getShipmentLabel(entrustCollectRouteIndex) }}
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>運搬会社/事務所</label>
              </div>
              <div class="c-inputWrap__items" :class="{ disabled: !entrustCollect.collectCompanyId }" v-if="entrustCollect.companyOpts.length">
                {{ entrustCollect.companyOpts.filter(i=> i.id === entrustCollect.collectFactoryId)[0].name }}
                <template v-if="form.entrustWasteList && form.entrustWasteList[0].wasteTypeId === wasteType.INDUSTRIAL_WASTE">- JWNETID({{ entrustCollect.companyOpts.filter(i=> i.id === entrustCollect.collectFactoryId)[0].jwnetId }})</template>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>運搬方法</label>
              </div>
              <div class="c-inputWrap__items" v-if="collectWayTypeList.length && entrustCollect.collectWayTypeId">
                {{ collectWayTypeList.filter(i=> i.id === entrustCollect.collectWayTypeId)[0].name }}
              </div>
            </div>

            <!-- 最後の区間は処分区間情報 -->
            <div class="c-inputWrap" v-if="entrustCollectRouteIndex === (form.entrustCollectCompanyInfo.length - 1)">
              <div class="c-inputWrap__label required">
                <label>運搬先</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area" v-if="destinationTypeOpts.length">
                  {{ destinationTypeOpts.filter(d => d.id === entrustCollect.destinationTypeId)[0].name }}
                </div>
                <template v-if="entrustCollect.destinationTypeId === destinationType.DISPOSAL_SITE && disposalSiteOpts.length">
                  <template>
                    {{ disposalSiteOpts.filter(i=> i.id === form.entrustDisposalCompanyInfo.disposalSiteId)[0].name }} - JWNETID({{ disposalSiteOpts.filter(i=> i.id === form.entrustDisposalCompanyInfo.disposalSiteId)[0].jwnetId }}) - 事業場コード({{ disposalSiteOpts.filter(i=> i.id === form.entrustDisposalCompanyInfo.disposalSiteId)[0].storeCode }})
                  </template>
                </template>
              </div>
            </div>

            <!-- 積み替え保管区間の情報 -->
            <div class="c-inputWrap" v-else-if="entrustCollectRouteIndex !== (form.entrustCollectCompanyInfo.length - 1)">
              <div class="c-inputWrap__label required">
                <label>運搬先</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area" v-if="destinationTypeOpts.length">
                  {{ destinationTypeOpts.filter(d => d.id === entrustCollect.destinationTypeId)[0].name }}
                  - JWNETID({{ destinationTypeOpts.filter(d => d.id === entrustCollect.destinationTypeId)[0].jwnetId }})
                </div>
                <template v-if="entrustCollect.destinationTypeId === destinationType.TRANSSHIPMENT && entrustCollect.transShipmentOpts.length && entrustCollect.transshipmentStorageId">
                  <template>
                    {{ entrustCollect.transShipmentOpts.filter(i=> i.id === entrustCollect.transshipmentStorageId)[0].name }} - JWNETID({{ entrustCollect.transShipmentOpts.filter(i=> i.id === entrustCollect.transshipmentStorageId)[0].jwnetId }}) - 事業場コード({{ entrustCollect.transShipmentOpts.filter(i=> i.id === entrustCollect.transshipmentStorageId)[0].storeCode }})
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>

        <!-- 最終処分場情報 ======================================== -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">最終処分場情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>最終処分場</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-btn secondary func small l-item" @click="isFinalDisposalSiteSelectListModalShow = true;" :class="{ disabled: form.entrustDisposalCompanyInfo.disposalSiteId == null}">最終処分場情報設定</div>
            </div>
          </div>
          <template v-for="item in form.entrustDisposalCompanyInfo.finalDisposalSiteList">
            <div v-if="null !== item.finalDisposalSiteId" class="c-disposalSiteList__item" :key="item.finalDisposalSiteId">
              <span class="name">{{ item.finalDisposalSiteName }}</span>
              <span class="address">住所 : {{ item.addressInfo.prefecturesName }} {{ item.addressInfo.address }} {{ item.addressInfo.buildingName }}</span>
              <span class="code">事業所コード : {{ item.finalDisposalStoreCode }}</span>
            </div>
          </template>
        </div>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <router-link :to="`/entrust/${entrustId}`" class="c-btn secondary small back">戻る</router-link>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="formHandler(false)">登録</div>
          <div class="c-btn primary" @click="formHandler(true)">登録してルート一覧へ</div>
        </div>
      </div>
    </main>

    <!-- 排出事業場設定モーダル -->
    <GenerateStoreSelectModal
      :isShow.sync="isGenerateStoreSelectModalShow"
      :generateFactory="tempVal.generateFactory"
      :default="form.generateStoreList"
      :editForm="form"
      @setSelectedStores="setSelectedStores"
    />

    <!-- 運搬許可証設定設定モーダル -->
    <CollectLicenceSelectModal
      :isShow.sync="isCollectLicenceSelectModalShow"
      :targetEntrustCollect="targetEntrustCollect"
      :default="targetEntrustCollect.collectLicenceInfo"
      :initialCollectLicenseIds="initialCollectLicenseIds"
      @setSelectedCollectLicence="setCollectLicence"
    />

    <!-- 一般廃棄物運搬許可証設定設定モーダル -->
    <CollectCommonLicenceSelectModal
      :isShow.sync="isCollectCommonLicenceSelectModalShow"
      :targetEntrustCollectCommon="targetEntrustCollectCommon"
      :default="targetEntrustCollectCommon.commonCollectLicenceInfo"
      :initialCollectCommonLicenseIds="initialCollectCommonLicenseIds"
      @setSelectedCollectLicence="setCollectCommonLicence"
    />

    <!-- 処分許可証設定設定モーダル -->
    <DisposalLicenceSelectModal
      :isShow.sync="isDisposalLicenceSelectModalShow"
      :disposalCompanyId="form.entrustDisposalCompanyInfo.disposalCompanyId"
      :default="form.entrustDisposalCompanyInfo.disposalLicenceInfo"
      :initialDisposalLicenseIds="initialDisposalLicenseIds"
      @setSelectedDisposalLicence="setDisposalLicence"
    />

    <!-- 一般廃棄物処分許可証設定設定モーダル -->
    <DisposalCommonLicenceSelectModal
      :isShow.sync="isDisposalCommonLicenceSelectModalShow"
      :disposalCompanyId="form.entrustDisposalCompanyInfo.disposalCompanyId"
      :default="form.entrustDisposalCompanyInfo.commonDisposalLicenceInfo"
      :initialDisposalCommonLicenseIds="initialDisposalCommonLicenseIds"
      @setSelectedDisposalLicence="setDisposalCommonLicence"
    />

    <!-- 最終処分場設定モーダル -->
    <FinalDisposalSiteSelectListModal
      :isShow.sync="isFinalDisposalSiteSelectListModalShow"
      :form.sync="form.entrustDisposalCompanyInfo.finalDisposalSiteList"
      :disposalSiteId.sync="form.entrustDisposalCompanyInfo.disposalSiteId"
    />

    <!-- 開始日設定モーダル -->
    <div class="c-modal md" v-show="isStartDateModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s">有効開始日設定</h5>
          <span
            class="c-modal__head__close"
            @click="isStartDateModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body no-scroll">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有効開始日</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols">
                <InputDate
                  :value.sync="form.activeStartDate"
                  placeholder="選択してください"
                  :validation="validateJustBlank"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn primary small"
            :class="{ disabled: !form.activeStartDate }"
            @click="confirmStartDateHandler"
          >
            確認
          </div>
        </div>
      </div>
    </div>

    <!-- 登録確認モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          委託契約書を編集します。<br />
          <template v-if="isStartDateRequired">
            内容は有効開始日以降の予約から反映されます。<br />
          </template>
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="submit">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <!-- 運搬単価一覧モーダル -->
    <CollectUnitListModal
      :isShow.sync="isCollectUnitListModalShow"
      :entrust="entrust"
      :collectCompany="currentCollectCompany"
    />
    <!-- 処分単価一覧モーダル -->
    <DisposalUnitListModal
      :isShow.sync="isDisposalUnitListModalShow"
      :entrust="entrust"
    />

    <AppFooter />
  </div>
</template>

<style scoped>
.contract-file {
  margin-top: 2rem;
  white-space: pre-wrap;
  word-break: break-all;
  width: 40rem;
}
.contract-file-delete-button {
  align-self: center;
}
.open-modal-link {
  cursor: pointer;
  padding: 5px 5px 0px 0px;
  line-height: 2.5rem;
  margin-bottom: none;
}
</style>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import MyTextarea from "@/components/parts/input/MyTextarea";
import GenerateStoreSelectModal from "./components/GenerateStoreSelectModal";
import DisposalLicenceSelectModal from "./components/DisposalLicenceSelectModal";
import CollectLicenceSelectModal from "./components/CollectLicenceSelectModal";
import DisposalCommonLicenceSelectModal from "./components/DisposalCommonLicenceSelectModal";
import CollectCommonLicenceSelectModal from "./components/CollectCommonLicenceSelectModal";
import validation from "@/mixin/validation";
import callApi from "./mixins/callApi";
import formValue from "./mixins/formValue";
import validate from "./mixins/validate";
import generateFuncs from "./mixins/generateFuncs";
import entrustWaste from "./mixins/entrustWaste";
import disposal from "./mixins/disposal";
import entrustCollect from "./mixins/entrustCollect";
import collectRoute from "./mixins/collectRoute";
import { API_URL, DOMAIN, COOKIE_INFO } from "@/const/index";
import pdfUpload from "./mixins/pdfUpload";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import FinalDisposalSiteSelectListModal from "./components/FinalDisposalSiteSelectListModal";
import CollectUnitListModal from "../detail/components/CollectUnitListModal.vue";
import DisposalUnitListModal from "../detail/components/DisposalUnitListModal.vue";
import csvUploadCollect from "@/pages/entrust/common/csvUploadCollect";
import csvUploadDisposal from "@/pages/entrust/common/csvUploadDisposal";
import anyMail from "@/pages/entrust/common/anyMail";
import common from "@/mixin/common";
import countByte from "@/pages/entrust/common/countByte";

export default {
  name: "entrust-edit",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    InputDate,
    MyTextarea,
    GenerateStoreSelectModal,
    DisposalLicenceSelectModal,
    CollectLicenceSelectModal,
    DisposalCommonLicenceSelectModal,
    CollectCommonLicenceSelectModal,
    FinalDisposalSiteSelectListModal,
    CollectUnitListModal,
    DisposalUnitListModal,
  },
  mixins: [
    validation,
    callApi,
    formValue,
    validate,
    generateFuncs,
    entrustWaste,
    disposal,
    entrustCollect,
    collectRoute,
    pdfUpload,
    csvUploadCollect,
    csvUploadDisposal,
    anyMail,
    common,
    countByte,
  ],
  data() {
    return {
      entrustId: null,
      isStartDateModalShow: false,
      isGenerateStoreSelectModalShow: false,
      isCollectLicenceSelectModalShow: false,
      isDisposalLicenceSelectModalShow: false,
      isCollectCommonLicenceSelectModalShow: false,
      isDisposalCommonLicenceSelectModalShow: false,
      isConfirmModalShow: false,
      gotoRoute: false,
      isStartDateRequired: false, // 有効開始日が必要かどうか
      errMsgs: [],
      wasteType: DOMAIN.WASTE_TYPE,
      manifestType: DOMAIN.MANIFEST_TYPE,
      postData: null,
      destinationType: DOMAIN.DESTINATION_TYPE,
      destinationTypeOpts: [
        { id: DOMAIN.DESTINATION_TYPE.TRANSSHIPMENT, name: "積替保管" },
        { id: DOMAIN.DESTINATION_TYPE.DISPOSAL_SITE, name: "処分場" },
      ],
      resettable: false,
      isRemoveWasteInfo: false,
      isRemoveCollectSection: false,
      processing: false,

      // 202202 CS 委託契約
      /**
       * 最終処分場モーダル開閉状態
       */
      isFinalDisposalSiteSelectListModalShow: false,
      initialgenerateCompanyIds: [],
      initialCollectLicenseIds: [],
      initialCollectCommonLicenseIds: [],
      initialDisposalLicenseIds: [],
      initialDisposalCommonLicenseIds: [],
      isCollectUnitListModalShow: false,
      isDisposalUnitListModalShow: false,
      currentCollectCompany: {},
    };
  },

  methods: {

    // 廃棄物情報を複数登録した場合に実行
    syncWasteTypeId() {
      this.errMsgs = [];
      // 産業廃棄物に変更すると、処分事業場をリセット
      if (this.form.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE) {
        // 許可書が未設定の場合、運搬先選択肢の表示は処分事業者に紐づく処分場情報で表示する
        if (this.form.entrustDisposalCompanyInfo.disposalLicenceInfo.length === 0) {
          this.disposalSiteOpts = this.originalDisposalSiteByCompanyIdOpts;
        } {
          this.disposalSiteOpts = this.originalDisposalSiteByLicenceIdOpts;
        }
      } else {
        // 有価・一般に変更すると
        if (this.form.entrustCollectCompanyInfo.length > 1) {
          // 廃棄物区分を産業廃棄物から有価、一般廃棄物に変更時に廃棄物情報のサイズは２以上ならエラーとする
          this.errMsgs.push("有価、一般廃棄物を指定する場合は、運搬区間の複数指定はできません。");
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        this.disposalSiteOpts = this.originalDisposalSiteByCompanyIdOpts;
        // 既に設定した許可証をリセット
        this.form.entrustCollectCompanyInfo.forEach(ecc => {
          ecc.collectLicenceInfo = [];
          ecc.transshipmentStorageId = null;
        });
        this.form.entrustDisposalCompanyInfo.disposalLicenceInfo = [];
      }

      // 廃棄物情報の廃棄物区分を初期値に戻して処分事業場も戻します
      if (this.form.entrustWasteList[0].wasteTypeId === this.originalWasteTypeId) {
        this.form.entrustDisposalCompanyInfo.disposalSiteId = this.originalDisposalSiteId;
      } else {
        this.form.entrustDisposalCompanyInfo.disposalSiteId = null;
      }
 
      if (this.form.entrustWasteList.length < 2) return;
        const wasteTypeId = this.form.entrustWasteList[0].wasteTypeId;
        this.form.entrustWasteList.forEach(waste => waste.wasteTypeId = wasteTypeId);
      },

    // 開始日input活性化
    // 廃棄物種類, 運搬許可証, 処分許可証, 経路の運搬会社, 経路の運搬先のどれか変更で活性化
    checkStartDateAble() {
      if (this.resettable) this.isStartDateRequired = true;
    },

    //運搬先を変更すると
    changeDisposalSite() {
      //最終処分場をリセット
      if (!this.isOriginDisposalSiteId) {
        this.form.entrustDisposalCompanyInfo.finalDisposalSiteList = [];
      }
      this.isOriginDisposalSiteId = false;
      this.checkStartDateAble();
    },

    // post用にデータをフォーマット
    formatPostData() {
      return new Promise((resolve) => {
        // 重複荷姿をフィルータする
        this.form.entrustWasteList.forEach(e => {
        const uniqueShapeTypeIds = [...new Set(e.shapeTypeIdList.filter(s => s.shapeTypeId).map(s => s.shapeTypeId))];
          e.shapeTypeIdList = uniqueShapeTypeIds.map(id => ({ shapeTypeId: id }));
        });
        const newData = JSON.parse(JSON.stringify(this.form)); // deep copy

        // 排出事業者情報
        delete newData.generateCompanyName; // 表示用項目を削除
        newData.generateStoreList = newData.generateStoreList.map(s => {
            return {
              entrustGenerateStoreId: s.entrustGenerateStoreId,
              generateStoreId: s.id,
              contractStartDate: s.contractStartDate,
              contractEndDate: s.contractEndDate,
            };
          });
        // 廃棄物情報
        newData.entrustWasteList.forEach(ew => {
          delete ew.wasteTypeName;
          delete ew.manifestTypeName;
          delete ew.cbaItemOpts;
          delete ew.wasteNameOpts;

          // 廃棄物情報-処分方法
          if (ew.disposalWayType.length === 0 || ew.disposalWayType[0].disposalWayTypeId === null) {
            ew.disposalWayType = null
          }
        });
        // 運搬事業者情報
        newData.entrustCollectCompanyInfo.forEach(ec => {
          delete ec.collectCompanyName;
          delete ec.companyOpts;
          delete ec.transShipmentOpts;
          if (ec.collectLicenceInfo.length === 0 || ec.collectLicenceInfo[0].collectLicenceId === null) {
            ec.collectLicenceInfo = null;
          }
          if (ec.entrustCollectContractFileList.length === 0 || !ec.entrustCollectContractFileList[0].contractFileName) {
            ec.entrustCollectContractFileList = null;
          }

          if (ec.entrustCollectUnitPriceList) {
            // 作成したリストに対して、単価と単価単位のどちらとも値を取得できているもののみを更新対象とする
            ec.entrustCollectUnitPriceList = ec.entrustCollectUnitPriceList.filter(cp => cp.unitPrice && cp.wasteUnitTypeName);
          }
        });
        // 処分事業者情報
        delete newData.entrustDisposalCompanyInfo.disposalLicenceInfo.disposalCompanyName;
        if (newData.entrustDisposalCompanyInfo.disposalLicenceInfo.length === 0 || newData.entrustDisposalCompanyInfo.disposalLicenceInfo[0].disposalLicenceId === null) {
          newData.entrustDisposalCompanyInfo.disposalLicenceInfo = null;
        }
        if (newData.entrustDisposalCompanyInfo.entrustDisposalContractFileList.length === 0 || !newData.entrustDisposalCompanyInfo.entrustDisposalContractFileList[0].contractFileName) {
          newData.entrustDisposalCompanyInfo.entrustDisposalContractFileList = null
        }
        // 最終処分場情報
        // 取得データに最終処分場の情報がなかった場合（最終処分が実装される前に登録されたデータ）、要素を追加する
        if (!newData.entrustDisposalCompanyInfo.finalDisposalSiteList) {
          newData.entrustDisposalCompanyInfo.finalDisposalSiteList = [];
        }
        newData.entrustDisposalCompanyInfo.finalDisposalSiteList =
          newData.entrustDisposalCompanyInfo.finalDisposalSiteList.map((m) => {
            return {
              finalDisposalSiteId: m.finalDisposalSiteId,
            };
          });

        if (newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList) {
          // 作成したリストに対して、単価と単価単位のどちらとも値を取得できているもののみを更新対象とする
          newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList = newData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList.filter(cp => cp.unitPrice && cp.wasteUnitTypeName);
        }
        resolve(newData);
      });
    },

    formHandler(gotoRoute) {
      this.errMsgs = [];
      // 廃棄物情報が１つもない状態または運搬区間情報が１つもない状態はエラーとする
      if(this.form.entrustWasteList.length < 1 || this.form.entrustCollectCompanyInfo.length < 1) {
        if (this.form.entrustWasteList.length < 1) {
          this.errMsgs.push("廃棄物情報を登録してください。");
        }
        if (this.form.entrustCollectCompanyInfo.length < 1) {
          this.errMsgs.push("運搬委託契約先情報を登録してください。");
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }


      if (!this.runValidate()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      } else {
        if(!this.overlapCheck()) {
          this.processing = false;
          this.errMsgs.push("排出事業場と廃棄物情報の期間は全く重ならないので、各利用期間を確認してください。");
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
      }

      if (this.csvCollectErrorMsgs.filter( e => e.length > 0).length > 0 || this.csvDisposalErrorMsgs.length > 0) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // 委託契約ファイルにはエラーが発生したかどうか確認
      const hasInValidCollectContractFiles = this.form.entrustCollectCompanyInfo.some(ec =>
          ec.entrustCollectContractFileList &&
          ec.entrustCollectContractFileList.some(f => f.contractFileMsg != null)
      );
      const hasInvalidDisposalContractFiles = this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList && this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList.some(f => f.contractFileMsg != null)
      if (hasInValidCollectContractFiles || hasInvalidDisposalContractFiles) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // 委託契約書突合APIの実行
      this.formatPostData().then((res) => {
        this.postData = res;
        if (this.form.entrustWasteList.filter(ew => ew.entrustWasteInfoId === null).length > 0) this.isStartDateRequired = true;
        if (this.form.entrustCollectCompanyInfo.filter(ecc => ecc.entrustCollectCompanyId === null).length > 0) this.isStartDateRequired = true;

        // 廃棄物区分は産業廃棄物の場合、突合チェックを必須とする
        if (this.form.entrustWasteList[0].wasteTypeId === this.wasteType.INDUSTRIAL_WASTE) {
          axios.post(API_URL.ENTRUST.MATCHING, this.postData)
            .then(() => {
              // 確認
              this.isConfirmModalShow = true;
              this.gotoRoute = gotoRoute;
            })
            .catch((err) => {
              this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
              this.isConfirmModalShow = false;
              window.scrollTo({ top: 0, behavior: "smooth" });
            });
        } else {
          this.isConfirmModalShow = true;
          this.gotoRoute = gotoRoute;
        }
      });
    },

    // 有効開始日設定モーダル内 確認押下
    confirmStartDateHandler() {
      axios
        .get(`${API_URL.ENTRUST.CHECK_START_DATE}?entrustId=${this.entrustId}&startDate=${this.form.activeStartDate}`)
        .then(() => {
          // 確認モーダル
          this.isConfirmModalShow = true;
          this.postData.activeStartDate = this.form.activeStartDate;
        })
        .catch((err) => {
          // モーダル閉じてエラー表示
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.isStartDateModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    // 確認モーダル内 OK押下
    submit() {
      this.putEntrust();
    },

    putEntrust() {
      const putData = JSON.parse(JSON.stringify(this.postData)); // deep copy
 
      // 運搬情報
      putData.entrustCollectCompanyInfo.forEach(ec => {
        // 許可書
        if (ec.collectLicenceInfo !== null) {
          ec.collectLicenceInfo.forEach(cl => {
            delete cl.licenceNo;
          });
        }
        // 運搬単価
        if (ec.entrustCollectUnitPriceList !== null) {
          ec.entrustCollectUnitPriceList = ec.entrustCollectUnitPriceList.filter(cup => cup.unitPrice != null);
        }
      });

      // 処分許可証
      if (putData.entrustDisposalCompanyInfo.disposalLicenceInfo != null) {
        putData.entrustDisposalCompanyInfo.disposalLicenceInfo.forEach(dl => {
          delete dl.licenceNo;
        });
      }
      // 処分単価
      if (putData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList !== null) {
        putData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList = putData.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList.filter(dup => dup.unitPrice != null);
      }

      // 有効開始日
      if (!putData.activeStartDate) {
        delete putData.activeStartDate;
      }

      // 送付先メール情報のレスポンスへ変換
      putData.entrustDeliveryConfirmation.generateDeliveryConfirmMail = putData.entrustDeliveryConfirmation.generateDeliveryConfirmMail ? putData.entrustDeliveryConfirmation.generateDeliveryConfirmMail : null
      putData.entrustDeliveryConfirmation.collectDeliveryConfirmMail = putData.entrustDeliveryConfirmation.collectDeliveryConfirmMail ? putData.entrustDeliveryConfirmation.collectDeliveryConfirmMail : null
      putData.entrustDeliveryConfirmation.disposalDeliveryConfirmMail = putData.entrustDeliveryConfirmation.disposalDeliveryConfirmMail ? putData.entrustDeliveryConfirmation.disposalDeliveryConfirmMail : null

      putData.entrustDeliveryConfirmation.anyMail1 = putData.entrustDeliveryConfirmation.anyMailList[0] ? putData.entrustDeliveryConfirmation.anyMailList[0] : null
      putData.entrustDeliveryConfirmation.anyMail2 = putData.entrustDeliveryConfirmation.anyMailList[1] ? putData.entrustDeliveryConfirmation.anyMailList[1] : null
      putData.entrustDeliveryConfirmation.anyMail3 = putData.entrustDeliveryConfirmation.anyMailList[2] ? putData.entrustDeliveryConfirmation.anyMailList[2] : null
      putData.entrustDeliveryConfirmation.anyMail4 = putData.entrustDeliveryConfirmation.anyMailList[3] ? putData.entrustDeliveryConfirmation.anyMailList[3] : null
      putData.entrustDeliveryConfirmation.anyMail5 = putData.entrustDeliveryConfirmation.anyMailList[4] ? putData.entrustDeliveryConfirmation.anyMailList[4] : null
      delete putData.entrustDeliveryConfirmation.anyMailList

      // 登録担当者名と引き渡し担当者名を全角に変換
      putData.registMemberName = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(putData.registMemberName));
      putData.generateMemberName = this.toFullWidthString(this.removeWhitespaceIfOnlyWhitespace(putData.generateMemberName));

      this.processing = true; // 2重submit防止
      axios
        .put(API_URL.ENTRUST.EDIT + this.entrustId, putData)
        .then(() => {
          this.$cookies.set(COOKIE_INFO.MESSAGE, "委託契約書を編集しました。");
          if (this.gotoRoute) {
            this.$router.push("/route");
          } else {
            this.$router.push("/entrust");
          }
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isConfirmModalShow = false;
          this.isStartDateModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    removeContractFile(collectIndex, fileIndex) {
      const removedFileList = this.form.entrustCollectCompanyInfo[collectIndex].entrustCollectContractFileList.filter((v, i) => {
        return i !== fileIndex
      });


      this.form.entrustCollectCompanyInfo[collectIndex].entrustCollectContractFileList = removedFileList;

    },
    removeDisposalContractFile(fileIndex) {
      const removedFileList = this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList.filter((v, i) => {
        return i !== fileIndex
      });

      this.form.entrustDisposalCompanyInfo.entrustDisposalContractFileList = removedFileList;

    },
    // 「運搬単価一覧」ボタンを押す時
    showCollectUnitListModal(entrustCollectIndex) {
      this.currentCollectCompany = this.entrust.entrustCollectCompanyInfo[entrustCollectIndex]
      this.isCollectUnitListModalShow = true
    },
    showDisposalUnitListModal() {
      this.isDisposalUnitListModalShow = true
    },
    removeDisposalUnitPriceList() {
      this.form.entrustDisposalCompanyInfo.entrustDisposalUnitPriceList = []
    },
    removeCollectUnitPriceList(entrustCollectIndex) {
      this.form.entrustCollectCompanyInfo[entrustCollectIndex].entrustCollectUnitPriceList = []
    }
  },

  mounted() {
    this.entrustId = Number(this.$route.params.id);
    this.getEntrustApi(this.entrustId).then(() => this.resettable = true);
    this.getGeneratorChoiceApi();
    this.getWasteTypeApi();
    this.getManifestTypeApi();
    this.getJwnetItemsApi();
    this.getConstitutionItemApi();
    this.getWasteUnitApi();
    this.getTermTypeApi();
    this.getShapeApi();
    this.getDisposalWayTypesApi();
    this.getDisposalCompanyListApi();
    this.getCollectCompanyListApi();
    this.getCollectCollectWayTypeApi();
  },
};
</script>

<style scoped lang="scss">
.sub-title {
  border-bottom: 1px solid #cbd0d8;
  margin: 2rem 0;
  font-size: 1.6rem;
}

.c-disposalSiteList {
  .stretch {
    flex: 1;
  }

  .c-infoList__row {
    display: flex;
  }

  &__item {
    + .c-disposalSiteList__item {
      border-top: 1px solid #ccd0d7;
      padding-top: 5px;
    }

    .c-inputWrap + & {
      margin-top: 15px;
    }

    display: flex;
    margin-top: 10px;

    span {
      + span {
        margin-left: 1em;
      }
      &.name {
        width: 10em;
      }
      &.address {
        width: 25em;
      }
      &.code {
        width: 13em;
      }
    }

  }
}

.c-inline{
  display: inline;
}

.c-inline-block{
  display: inline-block;
}
</style>
