<template>
  <div>
    <div class="l-container l-area">
      <ManifestProgressBar
          class="l-box"
          :manifestStatusId="formValue.manifestInfo.manifestStatusInfo.id"
      />
      <ToastMessage :toastMsg.sync="toastMsg" />
      <ErrorMessage
        :errMsgs="[formValue.edittableErrorMessage]"
        v-if="formValue.isEdittable && formValue.edittableErrorMessage"
      />
      <ErrorMessage :errMsgs="errMsgs" />

      <!-- マニフェスト情報 -->
      <div v-if="isJwnetReferring" class="l-contents">
        <div class="c-status info-red"></div>
        <span>このマニフェストはJWNETへの情報照会中です</span>
        <div class="c-jwnet-alert">
          <span>しばらく待ってからマニフェストを操作して下さい</span>
        </div>
      </div>
      <div
        class="l-contents"
        v-if="
          isInProgress &&
          (waitPendingStatus.includes(
            formValue.manifestInfo.manifestStatusInfo.id
          ) ||
          (waitCancelPendingStatus.includes(formValue.manifestInfo.manifestStatusInfo.id)) ||
            formValue.manifestInfo.manifestStatusInfo.id ===
              requestPendingStatus ||
            formValue.manifestInfo.manifestStatusInfo.id ===
              finalDisposalReported ||
            isDeliveryDeadline ||
            isDeliveryExpired ||
            isDeliveryReportDeadline ||
            isDeliveryReportExpired)
        "
      >
        <div
          v-if="
            waitPendingStatus.includes(
              formValue.manifestInfo.manifestStatusInfo.id
            )
          "
        >
          <div class="c-status info"></div>
          <span>修正承認待ち</span>
        </div>
        <div
          v-else-if="
            waitCancelPendingStatus.includes(
              formValue.manifestInfo.manifestStatusInfo.id
            )
          "
        >
          <div class="c-status info"></div>
          <span>取消承認待ち</span>
        </div>
        <div
          v-else-if="
            formValue.manifestInfo.manifestStatusInfo.id === requestPendingStatus
          "
        >
          <div class="c-status info"></div>
          <template v-if="canApproveManifestFixRequest">
            <span @click="displayManifestFixApproveModal" class="approve-label">修正承認依頼中</span>
          </template>
          <template v-else>
            <span>修正承認依頼中</span>
          </template>
        </div>
        <div
          v-else-if="
            formValue.manifestInfo.manifestStatusInfo.id === finalDisposalReported
          "
        >
          <div class="c-status info"></div>
          <span>最終報告が上がっています。確認してください。</span>
        </div>
        <div v-else-if="isDeliveryDeadline">
          <div class="c-status info-yellow"></div>
          <span>交付期限</span>
        </div>
        <div v-else-if="isDeliveryExpired">
          <div class="c-status info-red"></div>
          <span>交付期限切れ</span>
        </div>
        <div v-else-if="isDeliveryReportDeadline">
          <div class="c-status info-yellow"></div>
          <span>報告期限</span>
        </div>
        <div v-else-if="isDeliveryReportExpired">
          <div class="c-status info-red"></div>
          <span>報告期限切れ</span>
        </div>
      </div>

      <div class="l-contents">
        <div class="l-box">
          <h3 class="c-lead icon">マニフェスト情報</h3>
        </div>
        <div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>マニフェスト番号</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                {{ formValue.manifestInfo.manifestNo }}
              </div>
            </div>
          </div>
          <div class="c-inputWrap l-area">
            <div class="c-inputWrap__label">
              <label>引き渡し日</label>
            </div>
            <div class="c-inputWrap__items">
              {{ formValue.manifestInfo.collectionDate | dateJpYMDFormat }}
              {{ formValue.manifestInfo.collectionTime }}
            </div>
          </div>

          <div class="c-accordion">
            <input
              id="manifest-info-acc"
              class="c-accordion__cb"
              type="checkbox"
            />
            <div class="c-accordion__head">
              <label
                class="c-accordion__head__lbl"
                for="manifest-info-acc"
              ></label>
              <div class="c-accordion__head__text">詳細</div>
            </div>
            <div class="c-accordion__body for-form">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>交付日</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ formValue.manifestInfo.issueDate | dateJpYMDFormat }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>排出事業者名称</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.generateCompanyInfo
                      ? formValue.manifestInfo.generateCompanyInfo.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>排出事業場名称</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.generateStoreInfo
                      ? formValue.manifestInfo.generateStoreInfo.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>担当者</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>登録担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{
                        formValue.manifestInfo.registMember
                          ? formValue.manifestInfo.registMember.name
                          : "" | blankText
                      }}
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>引き渡し担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{
                        formValue.manifestInfo.generateMember
                          ? formValue.manifestInfo.generateMember.name
                          : "" | blankText
                      }}
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                  <div v-if="formValue.manifestInfo.proxyCollectMember != null" class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>運搬代理登録担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{ formValue.manifestInfo.proxyCollectMember.name }}
                    </div>
                  </div>
                  <div v-else-if="formValue.manifestInfo.proxyDisposalMember != null" class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>処分代理登録担当者</label>
                    </div>
                    <div class="c-inputWrap__items">
                      {{ formValue.manifestInfo.proxyDisposalMember.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬費用（総額）</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.collectCost ? formValue.collectCost : "" | moneyText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分費用</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.disposalCost
                      ? formValue.disposalCost
                      : "" | moneyText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>有価売却額</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.valuablesCost
                      ? formValue.valuablesCost
                      : "" | moneyText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>最終修正日</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.manifestInfo.manifestUpdateDate | dateJpYMDFormat
                  }}
                </div>
              </div>
            </div>
            <!-- / c-accordion__body -->
          </div>
          <!-- / c-accordion -->
        </div>
      </div>
      <!-- /.l-contents -->

      <!-- 廃棄物情報 -->
      <div class="l-contents l-area">
        <div class="l-box">
          <h3 class="c-lead icon">廃棄物情報</h3>
        </div>
        <div>
          <div class="c-inputWrap l-area">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ formValue.routeInfo.jwnetItem ? formValue.routeInfo.jwnetItem.name: "" | blankText }}
            </div>
          </div>

          <div class="c-accordion">
            <input
              id="disposal-info-acc"
              class="c-accordion__cb"
              type="checkbox"
            />
            <div class="c-accordion__head">
              <label
                class="c-accordion__head__lbl"
                for="disposal-info-acc"
              ></label>
              <div class="c-accordion__head__text">詳細</div>
            </div>
            <div class="c-accordion__body for-form">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>ルート</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols onTop l-area">
                    <div v-if="formValue.routeInfo">
                      <template v-if="isCollector">
                        {{ formValue.routeInfo.routeName }}
                      </template>
                      <template v-else>
                        <router-link :to="`/route/${formValue.routeInfo.routeId}?isDisplaySideMenu=false`" target="_blank" class="c-text text-link">
                          {{ formValue.routeInfo.routeName }}
                        </router-link>
                      </template>
                      <div>
                        <dl class="c-dl">
                          <dt>排出事業場：</dt>
                          <dd>
                            {{ formValue.routeInfo.generateStore && formValue.routeInfo.generateStore.name | blankText }}
                          </dd>
                        </dl>
                        <dl class="c-dl">
                          <dt>運搬業者：</dt>
                          <dd>
                            {{ formValue.routeInfo.collectCompanys && formValue.routeInfo.collectCompanys.map(cc => cc.name).join(",") | blankText }}
                          </dd>
                        </dl>
                        <dl class="c-dl">
                          <dt>処分場：</dt>
                          <dd>
                            {{ formValue.routeInfo.disposalSite && formValue.routeInfo.disposalSite.name | blankText }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap" v-if="formValue.anyGenerateStoreInfo.anyGenerateStoreName">
                <div class="c-inputWrap__label">
                  <label>排出事業場名</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.anyGenerateStoreInfo.anyGenerateStoreName | blankText
                  }}
                </div>
              </div>
              <div class="c-inputWrap" v-if="formValue.anyGenerateStoreInfo.anyGenerateStoreAddress">
                <div class="c-inputWrap__label">
                  <label>排出事業場住所</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap__items__cols onTop l-area">
                    <div>
                      <div>
                        <dl class="c-dl">
                          <dt>郵便番号：</dt>
                          <dd>
                            {{ formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode ? formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode : "" | blankText }}
                          </dd>
                        </dl>
                        <dl class="c-dl">
                          <dt>都道府県：</dt>
                          <dd>
                            {{ formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId && prefectures.filter(p => p.id === formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId).length > 0 ? prefectures.find(p => p.id === formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId).name : "" | blankText }}
                          </dd>
                        </dl>
                        <dl class="c-dl">
                          <dt>市区町村：</dt>
                          <dd>
                            {{ formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address | blankText }}
                          </dd>
                        </dl>
                        <dl class="c-dl">
                          <dt>番地建物名号室：</dt>
                          <dd>
                            {{ formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName | blankText }}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>廃棄物名称</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.wasteName
                      ? formValue.wasteInfo.wasteName.name
                      : "" | blankText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>詳細名称</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ formValue.wasteInfo.detailWasteName | blankText }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>廃棄物数量</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.wasteQuantity
                      ? formValue.wasteInfo.wasteQuantity
                      : "" | formatFloat
                  }}
                  {{
                    formValue.wasteInfo.wasteUnitType
                      ? formValue.wasteInfo.wasteUnitType.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>廃棄物確定数量</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.fixedWasteQuantity
                      ? formValue.wasteInfo.fixedWasteQuantity
                      : "" | formatFloat
                  }}
                  {{
                    formValue.wasteInfo.fixedWasteQuantityUnitType
                      ? formValue.wasteInfo.fixedWasteQuantityUnitType.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>数量確定者</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.quantityConfirmBusinessType
                      ? formValue.wasteInfo.quantityConfirmBusinessType.name
                      : "" | blankText
                  }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>荷姿</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.shapeType
                      ? formValue.wasteInfo.shapeType.name
                      : "" | blankText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>荷姿数量</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.shapeQuantity
                      ? formValue.wasteInfo.shapeQuantity
                      : "" | blankText
                  }}
                </div>
              </div>

              <!--運搬単価-->
              <template v-if="formValue.wasteInfo.collectUnitPriceList && formValue.wasteInfo.collectUnitPriceList.length">
                <div
                    v-for="(collectUnitPrice, index) in formValue.wasteInfo.collectUnitPriceList"
                    :key="'collectUnitPrice-' + index"
                    class="c-inputWrap"
                >
                  <div class="c-inputWrap__label">
                    <label>
                      運搬単価（区間{{ collectUnitPrice.sectionNo }}）
                    </label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ collectUnitPrice.collectUnitPrice | moneyText }}
                    {{ collectUnitPrice.collectPriceWasteUnitType ? "/ " + collectUnitPrice.collectPriceWasteUnitType.name : '' }}
                  </div>
                </div>
              </template>
              <div v-else class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬単価</label>
                </div>
                <div class="c-inputWrap__items">
                  -
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分単価</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.disposalUnitPrice
                      ? formValue.wasteInfo.disposalUnitPrice
                      : "" | moneyText
                  }}
                  {{
                    formValue.wasteInfo.disposalPriceWasteUnitType
                      ? "/ " + formValue.wasteInfo.disposalPriceWasteUnitType.name
                      : ""
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>有価単価</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.valuablesUnitPrice
                      ? formValue.wasteInfo.valuablesUnitPrice
                      : "" | moneyText
                  }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>処分方法</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    formValue.wasteInfo.disposalWayType ? formValue.wasteInfo.disposalWayType.name
                      : "" | blankText
                  }}
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>有害物質名</label>
                </div>
                <div class="c-inputWrap__items">
                  <div
                    v-for="(harmfulItem, itemIndex) in formValue.harmfulList"
                    :key="`hmfl-item-${itemIndex}`"
                    class="l-flex start"
                  >
                    {{ harmfulItem.name }}
                  </div>
                  <div v-if="formValue.harmfulList.length === 0">-</div>
                </div>
              </div>
              <div class="c-inputWrap" v-for="(contactInfo, index) in formValue.contactNoInfo" :key="contactInfo.id">
                <div class="c-inputWrap__label">
                  <label>連絡番号{{ index+1 }}</label>
                </div>
                <div class="c-inputWrap__items">
                  <div>
                    {{ contactInfo.contactNo ? contactInfo.contactNo : "-" }}
                  </div>
                </div>
              </div>

              <div class="c-inputWrap" v-for="(taInfo, index) in formValue.remarksInfo" :key="'ta' + taInfo.id">
                <div class="c-inputWrap__label">
                  <label>備考{{ index+1 }}</label>
                </div>
                <div class="c-inputWrap__items">
                  <div>
                    {{ taInfo.detail ? taInfo.detail : "-" }}
                  </div>
                </div>
              </div>

              <!-- 添付ファイル -->
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>添付ファイル</label>
                </div>
                <div class="c-inputWrap__items">
                  <template v-if="!formValue.manifestInfo || !formValue.manifestInfo.attachedFileName">
                    <div>-</div>
                  </template>
                  <template v-else>
                    <div class="l-flex start">
                      <div class="upload-filename">
                        <!-- アップロード済みのファイルはダウロードリンク表示 -->
                        <a
                          class="c-text text-link c-entrustText__text"
                          @click="downloadManifestAttachedFile(formValue.manifestInfo.attachedFileName)"
                        >
                          <span class="upload-filename__text">
                            {{ formValue.manifestInfo.attachedFileName }}
                          </span>
                        </a>
                      </div>
                    </div>
                  </template>
                </div>
              </div>

              <!-- WDS -->
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>WDS</label>
                </div>
                <div
                    class="c-inputWrap__items"
                    v-if="formValue.routeInfo.wdsFileName"
                >
                  <a
                      class="c-text text-link c-entrustText__text"
                      @click="
                      downloadWDS(
                        formValue.routeInfo.routeId,
                        formValue.routeInfo.wdsFileName
                      )
                    "
                  >
                    {{ formValue.routeInfo.wdsFileName }}
                  </a>
                </div>
                <div class="c-inputWrap__items" v-else>-</div>
              </div>

              <!-- トン換算係数 -->
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>トン換算係数</label>
                </div>
                <div class="c-inputWrap__items">
                  <div>{{ formValue.manifestInfo.conversionFactorKg ? formValue.manifestInfo.conversionFactorKg : "-" | formatFloat }} / kg</div>
                  <div>{{ formValue.manifestInfo.conversionFactorM3 ? formValue.manifestInfo.conversionFactorM3 : "-" | formatFloat }} / m3</div>
                  <div>{{ formValue.manifestInfo.conversionFactorLiter ? formValue.manifestInfo.conversionFactorLiter : "-" | formatFloat }} / リットル</div>
                  <div>{{ formValue.manifestInfo.conversionFactorUnitNumber ? formValue.manifestInfo.conversionFactorUnitNumber : "-" | formatFloat }} / 個・台</div>
                </div>
              </div>

              <!-- ルートラベル -->
              <div
                  v-for="manifestLabel in formValue.labelInfo.manifestLabels"
                  :key="`${manifestLabel.labelHeader}-${manifestLabel.labelId}`"
                  class="c-inputWrap"
              >
                <div class="c-inputWrap__label">
                  <label>{{ manifestLabel.labelHeader }}</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ manifestLabel.labelValue }}
                  {{ manifestLabel.labelUnit }}
                </div>
              </div>
            </div>
            <!-- / c-accordion__body -->
          </div>
          <!-- / c-accordion -->
        </div>
      </div>
      <!-- /.l-contents -->

      <!-- 運搬終了報告 -->
      <template v-if="formValue.collectReportList.length > 0">
        <CollectReportList
          v-for="(list, index) in formValue.collectReportList"
          :key="`collectReport-${index + 1}`"
          :index="index + 1"
          :total="formValue.collectReportList.length"
          :wasteType="formValue.wasteType"
          :list="list"
          :type="disposalReportDisplayType.AfterReport"
        />
      </template>
      <template v-else>
        <CollectReportList :index="0" :total="0" :wasteType="formValue.wasteType" :list="null" :type="disposalReportDisplayType.BeforeReport" />
      </template>

      <!-- 処分終了報告 -->
      <template v-if="formValue.disposalReportInfo">
        <InterDisposalList
          :wasteType="formValue.wasteType"
          :list="formValue.disposalReportInfo"
          :type="disposalReportDisplayType.AfterReport"
          :disposalRegistDate="formValue.disposalReportInfo.disposalReportDate ? formValue.disposalReportInfo.disposalReportDate : ''"
        />
      </template>
      <template v-else>
        <InterDisposalList :wasteType="formValue.wasteType" :list="null" :type="disposalReportDisplayType.BeforeReport" :finalDisposalRegistDate="''" />
      </template>

      <!-- 最終処分終了報告 -->
      <template v-if="formValue.finalDisposalReportInfo && formValue.finalDisposalReportInfo.length > 0">
        <DisposalReportList
          :wasteType="formValue.wasteType"
          :finalDisposalSiteList="formValue.finalDisposalReportInfo"
          :type="disposalReportDisplayType.AfterReport"
          :isFinalDisposalSiteNG="formValue.isFinalDisposalSiteNG"
        />
      </template>
      <template v-else-if="formValue.finalDisposalReportDate && formValue.disposalReportInfo && formValue.disposalReportInfo.isSelfReportOrNotNeedReport === true">
        <DisposalReportList
          :finalDisposalReportDate="formValue.finalDisposalReportDate"
          :type="disposalReportDisplayType.SelfReportOrNotNeedReport"
        />
      </template>
      <template v-else>
        <DisposalReportList :wasteType="formValue.wasteType" :list="null" :type="disposalReportDisplayType.BeforeReport" />
      </template>
    </div>

    <FloatButtons>
      <div class="left">
        <div class="c-btn secondary small back" @click="backHandler">一覧へ戻る</div>
      </div>
      <div class="center" v-if="isInProgress">
        <template>
          <div v-if="isGenerator" class="c-btn secondary small" :class="{ disabled: !formValue.isEdittable || isDisabledFixButtonAndCancelButton || isJwnetReferring }" @click="cancelConfirmHandler">取消</div>
          <router-link v-if="isGenerator" :to="`/manifest/edit/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`"
                       class="c-btn secondary small" :class="{ disabled: !formValue.isEdittable || isDisabledFixButtonAndCancelButton || isExpiredManifest || isJwnetReferring }">
            修正
          </router-link>
          <router-link v-if="isCollector && formValue.isCollectReportable"
                       :to="`/manifest/collect/report/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`"
                       class="c-btn secondary small"
                      :class="{ disabled: isJwnetReferring }">
            終了報告
          </router-link>
          <div v-if="isCollector && isSealedStatusAndFinalDisposalReportedOrReportFixable"
                       @click="deleteCollectReportHandler"
                       class="c-btn secondary small" :class="{ disabled: !formValue.isEdittable || isJwnetReferring }">
            終了報告取消
          </div>
          <router-link v-if="isCollector && isSealedStatusAndFinalDisposalReportedOrReportFixable"
                       :to="`/manifest/collect/report/edit/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`"
                       class="c-btn secondary small" :class="{ disabled: !formValue.isEdittable || isJwnetReferring }">
            終了報告修正
          </router-link>
          <div class="c-btn secondary small" v-if="isGenerator && formValue.isFinalDisposalSiteNG" @click="cancelFinalDisposalSiteAlertConfirmHandler">アラート解除</div>
          <div class="c-btn primary small" @click="showChecksheetDownloadModal">受渡確認表印刷</div>
          <div v-if="formValue.manifestInfo.manifestStatusInfo.id === finalDisposalReported && isGenerator"
               class="c-btn primary small" @click="isCompleteConfirmModalShow = true">
            完了
          </div>
          <template v-if="isGenerator">
            <div v-if="waitPendingStatus.includes(formValue.manifestInfo.manifestStatusInfo.id)"
                 class="c-btn primary small" @click="approveConfirmOpenHandler(true)"
                 :class="{ disabled: isJwnetReferring }">
              報告修正承認
            </div>
            <div v-else-if="waitCancelPendingStatus.includes(formValue.manifestInfo.manifestStatusInfo.id)"
                 class="c-btn primary small" @click="approveConfirmOpenHandler(false)"
                 :class="{ disabled: isJwnetReferring }">
              報告取消承認
            </div>
          </template>
        </template>
      </div>
      <div class="center" v-else-if="isCompleted">
        <template>
          <div class="c-btn secondary small" v-if="isGenerator && isShowButtonForComplete && !isDeleted" :class="{ disabled: !formValue.isEdittable || isUnderDelete || isJwnetReferring }" @click="cancelConfirmHandler">取消</div>
          <router-link v-if="isGenerator && isShowButtonForComplete && !isDeleted" :to="`/manifest/edit/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`"
                       class="c-btn secondary small" :class="{ disabled: !formValue.isEdittable || isUnderDelete || isExpiredManifest || isJwnetReferring }">
            修正
          </router-link>
          <div v-if="isCollector && formValue.isCollectReportFixable"
               @click="deleteCollectReportHandler"
               class="c-btn secondary small" :class="{ disabled: !formValue.isEdittable || isJwnetReferring }">
            終了報告取消
          </div>
          <router-link v-if="isCollector && formValue.isCollectReportFixable"
                       :to="`/manifest/collect/report/edit/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`"
                       class="c-btn secondary small"
                       :class="{ disabled: isJwnetReferring }">
            終了報告修正
          </router-link>
          <div class="c-btn secondary small" v-if="formValue.isFinalDisposalSiteNG" @click="cancelFinalDisposalSiteAlertConfirmHandler">アラート解除</div>
          <div class="c-btn primary small" @click="showChecksheetDownloadModal">受渡確認表印刷</div>
        </template>
      </div>
      <div v-else>
        <div v-if="isDisposer" class="c-btn primary small" @click="showChecksheetDownloadModal">受渡確認表印刷</div>
        <router-link v-if="isCollector && formValue.isCollectReportable"
                       :to="`/manifest/collect/report/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`"
                       class="c-btn secondary small"
                       :class="{ disabled: isJwnetReferring }">
            終了報告
        </router-link>
      </div>
    </FloatButtons>

    <!-- 取消確認モーダル -->
    <div class="c-modal" v-show="isCancelConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを取り消します 。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn secondary small"
              @click="isCancelConfirmModalShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click.once="cancelHandler">OK</div>
        </div>
      </div>
    </div>

    <!-- アラート解除確認モーダル -->
    <div class="c-modal" v-show="isCancelAlertFinalDisposalSiteNGShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          最終処分場チェックエラーを解除します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
              class="c-btn secondary small"
              @click="isCancelAlertFinalDisposalSiteNGShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" :class="{ disabled: isCallApiProcess}" @click="cancelFinalDisposalSiteAlert">OK</div>
        </div>
      </div>
    </div>

    <!-- マニフェスト修正承認依頼モーダル -->
    <div class="c-modal md" v-show="isApproveConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div v-if="reportFixApprove" class="c-modal__content">
        <div class="c-modal__head">
          <h5 class="c-lead-s" v-text="waitPendingStatus.includes(formValue.manifestInfo.manifestStatusInfo.id) ? '終了報告の修正' : '終了報告の取消'">終了報告の修正</h5>
          <span
            class="c-modal__head__close"
            @click="isApproveConfirmModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body scroll">
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>修正前</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols onTop l-area">
                <div v-if="reportFixApprove.beforeCollectReportFixInfo">
                  <dl class="c-dl">
                    <dt>運搬終了日</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                            .collectionReportDate | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬区間</dt>
                    <dd>
                      {{ reportFixApprove.beforeCollectReportFixInfo.section }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                          .collectMemberName
                          ? reportFixApprove.beforeCollectReportFixInfo
                              .collectMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬報告担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                          .reportMemberName
                          ? reportFixApprove.beforeCollectReportFixInfo
                              .reportMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬方法</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                          .collectWayType
                          ? reportFixApprove.beforeCollectReportFixInfo
                              .collectWayType.name
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>車両番号</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo.carNo
                          ? reportFixApprove.beforeCollectReportFixInfo.carNo
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬量</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                          .collectQuantity
                          ? reportFixApprove.beforeCollectReportFixInfo
                              .collectQuantity
                          : "" | blankText
                      }}
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                          .collectWasteUnitType
                          ? reportFixApprove.beforeCollectReportFixInfo
                              .collectWasteUnitType.name
                          : ""
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>有価物収拾量</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                          .valuablesQuantity
                          ? reportFixApprove.beforeCollectReportFixInfo
                              .valuablesQuantity
                          : "" | blankText
                      }}
                      {{
                        reportFixApprove.beforeCollectReportFixInfo
                          .valuablesUnitType
                          ? reportFixApprove.beforeCollectReportFixInfo
                              .valuablesUnitType.name
                          : ""
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>備考</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeCollectReportFixInfo.remark
                          ? reportFixApprove.beforeCollectReportFixInfo.remark
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                </div>
                <div v-if="reportFixApprove.beforeDisposalReportFixInfo">
                  <dl class="c-dl">
                    <dt>処分終了日</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo
                            .disposalReportDate | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>廃棄物受領日</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo
                          .wasteReceiptDate
                          ? reportFixApprove.beforeDisposalReportFixInfo
                              .wasteReceiptDate
                          : "" | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>受入量</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo
                          .acceptQuantity
                          ? reportFixApprove.beforeDisposalReportFixInfo
                              .acceptQuantity
                          : "" | blankText
                      }}
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo
                          .acceptWasteInitType
                          ? reportFixApprove.beforeDisposalReportFixInfo
                              .acceptWasteInitType.name
                          : ""
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo
                          .collectMemberName
                          ? reportFixApprove.beforeDisposalReportFixInfo
                              .collectMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>車両番号</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo.carNo
                          ? reportFixApprove.beforeDisposalReportFixInfo.carNo
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>処分担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo
                          .disposalMemberName
                          ? reportFixApprove.beforeDisposalReportFixInfo
                              .disposalMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>処分報告担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo
                          .reportMemberName
                          ? reportFixApprove.beforeDisposalReportFixInfo
                              .reportMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>備考</dt>
                    <dd>
                      {{
                        reportFixApprove.beforeDisposalReportFixInfo.remark
                          ? reportFixApprove.beforeDisposalReportFixInfo.remark
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>{{ isFixingApproveConfirmFlag ? "修正後" : "取消内容"}}</label>
            </div>
            <div class="c-inputWrap__items">
              <div class="c-inputWrap__items__cols onTop l-area">
                <div v-if="reportFixApprove.afterCollectReportFixInfo">
                  <dl class="c-dl">
                    <dt>運搬終了日</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                            .collectionReportDate | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬区間</dt>
                    <dd>
                      {{ reportFixApprove.afterCollectReportFixInfo.section }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                          .collectMemberName
                          ? reportFixApprove.afterCollectReportFixInfo
                              .collectMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬報告担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                          .reportMemberName
                          ? reportFixApprove.afterCollectReportFixInfo
                              .reportMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬方法</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                          .collectWayType
                          ? reportFixApprove.afterCollectReportFixInfo
                              .collectWayType.name
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>車両番号</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo.carNo
                          ? reportFixApprove.afterCollectReportFixInfo.carNo
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬量</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                          .collectQuantity
                          ? reportFixApprove.afterCollectReportFixInfo
                              .collectQuantity
                          : "" | blankText
                      }}
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                          .collectWasteUnitType
                          ? reportFixApprove.afterCollectReportFixInfo
                              .collectWasteUnitType.name
                          : ""
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>有価物収拾量</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                          .valuablesQuantity
                          ? reportFixApprove.afterCollectReportFixInfo
                              .valuablesQuantity
                          : "" | blankText
                      }}
                      {{
                        reportFixApprove.afterCollectReportFixInfo
                          .valuablesUnitType
                          ? reportFixApprove.afterCollectReportFixInfo
                              .valuablesUnitType.name
                          : ""
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>備考</dt>
                    <dd>
                      {{
                        reportFixApprove.afterCollectReportFixInfo.remark
                          ? reportFixApprove.afterCollectReportFixInfo.remark
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                </div>
                <div v-if="reportFixApprove.afterDisposalReportFixInfo">
                  <dl class="c-dl">
                    <dt>処分終了日</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo
                            .disposalReportDate | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>廃棄物受領日</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo
                          .wasteReceiptDate
                          ? reportFixApprove.afterDisposalReportFixInfo
                              .wasteReceiptDate
                          : "" | dateJpYMDFormat
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>受入量</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo
                          .acceptQuantity
                          ? reportFixApprove.afterDisposalReportFixInfo
                              .acceptQuantity
                          : "" | blankText
                      }}
                      {{
                        reportFixApprove.afterDisposalReportFixInfo
                          .acceptWasteInitType
                          ? reportFixApprove.afterDisposalReportFixInfo
                              .acceptWasteInitType.name
                          : ""
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>運搬担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo
                          .collectMemberName
                          ? reportFixApprove.afterDisposalReportFixInfo
                              .collectMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>車両番号</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo.carNo
                          ? reportFixApprove.afterDisposalReportFixInfo.carNo
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>処分担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo
                          .disposalMemberName
                          ? reportFixApprove.afterDisposalReportFixInfo
                              .disposalMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>処分報告担当者</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo
                          .reportMemberName
                          ? reportFixApprove.afterDisposalReportFixInfo
                              .reportMemberName
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                  <dl class="c-dl">
                    <dt>備考</dt>
                    <dd>
                      {{
                        reportFixApprove.afterDisposalReportFixInfo.remark
                          ? reportFixApprove.afterDisposalReportFixInfo.remark
                          : "" | blankText
                      }}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDenyReasonModalShow = true"
          >
            否認
          </div>
          <div class="c-btn primary small" :class="{ disabled: isCallApiProcess}" @click="onClickApprove">承認</div>
        </div>
      </div>
    </div>
    <!-- .c-modal -->

    <!-- 否認理由モーダル -->
    <div v-if="isDenyReasonModalShow" class="c-modal sm">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          <div>
            <MyTextarea
              :value.sync="denyReason"
              rows="6"
              placeholder="否認理由を入力してください"
              :validation="validateJustBlank"
              id="deny-reason"
            />
          </div>
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDenyReasonModalShow = false"
          >
            戻る
          </div>
          <div
            class="c-btn primary small"
            :class="{ disabled: !denyReason.length || isCallApiProcess}"
            @click="onClickDenial"
          >
            送信
          </div>
        </div>
      </div>
    </div>

    <!-- 完了確認モーダル -->
    <div v-if="isCompleteConfirmModalShow" class="c-modal md">
      <div class="c-modal__overlay"></div>
      <div
        v-if="
          formValue.manifestInfo.manifestStatusInfo.id === finalDisposalReported
        "
        class="c-modal__content"
      >
        <div class="c-modal__head">
          <h5 class="c-lead-s">完了確認</h5>
          <span
            class="c-modal__head__close"
            @click="isCompleteConfirmModalShow = false"
          ></span>
        </div>
        <div class="c-modal__body no-scroll">
          マニフェストを完了にします。よろしいですか。
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isCompleteConfirmModalShow = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" :class="{ disabled: isCallApiProcess}" @click="completeHandler">完了</div>
        </div>
      </div>
    </div>

    <!-- 完了確認モーダル -->
    <div v-if="isDisplayCollectReportDeleteApproveModal" class="c-modal md">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body no-scroll">
          終了報告を取り消します。<br />
          よろしいですか。
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayCollectReportDeleteApproveModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="confirmCollectReportDeleteHandler">OK</div>
        </div>
      </div>
    </div>

    <!-- 登録モーダル -->
    <div class="c-modal lg" v-show="isChecksheetDownloadModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">

        <div class="c-modal__head">
          <h5 class="c-lead-s">受渡確認票印刷ダウンロード</h5>
          <span class="c-modal__head__close" @click="closeModalHandler"></span>
        </div>
        <div class="c-modal__body">
          <p class="u-txt-center">受け渡し確認票をダウンロードする形式を選択してください</p>
          <p class="u-txt-center" v-if="checksheetDownloadMessage">{{ checksheetDownloadMessage }}</p>

          <div
          v-for="cdt in checksheetDownloadTypes"
          :key="cdt.id"
          class="c-modal__foot"
          >
            <div>
              <div class="c-btn primary download-sm" :class="{ disabled: isDisableChecksheetDownload }" @click="checksheetDownloadHandler(cdt.value)">{{ cdt.label }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeModalHandler">閉じる</div>
        </div>
      </div>
    </div>

    <template v-if="canApproveManifestFixRequest">
      <ManifestFixApproveModal
        v-show="isDisplayManifestFixApproveModal"
        :onClose="closeManifestFixApproveModal"
        :manifestEditRequest="manifestEditRequest"
        :isJwnetReferring="isJwnetReferring"
        :modalErrMsgs="modalErMsgs"
        :onApprove="onClickManifestEditRequestApprove"
        :onDenial="onClickManifestEditRequestDenial"
      />
    </template>
  </div>

</template>

<style scoped>
  .approve-label {
    cursor: pointer;
  }
  .c-jwnet-alert {
    margin: 0 3.4rem;
  }
</style>

<script>
import axios from "axios";
import moment from "moment";
import MyTextarea from "@/components/parts/input/MyTextarea";
import validation from "@/mixin/validation";
import callApi from "../mixins/callApi";
import route from "../mixins/route";
import idToName from "../mixins/idToName";
import textarea from "../mixins/textarea";
import harmfulType from "../mixins/harmfulType";
import validate from "../mixins/validate";
import formatEditData from "../mixins/formatEditData";
import { saveAs } from "file-saver";
import { API_URL, DOMAIN } from "@/const";
import CollectReportList, {
  CollectReportDisplayType,
} from "./CollectReportList.vue";
import InterDisposalList, {
  InterDisposalReportDisplayType,
} from "./InterDisposalList.vue";
import DisposalReportList, {
  DisposalReportDisplayType,
} from "./DisposalReportList.vue";
import ManifestProgressBar from "./ManifestProgressBar";
import ManifestFixApproveModal from "./ManifestFixApproveModal";
import ToastMessage from "@/components/parts/ToastMessage";
import FloatButtons from "@/components/layout/FloatButtons";
import {REPORT_DEADLINE} from "@/const/dateLimit";
import downloadManifestAttachedFile from "@/pages/manifest/common/downloadManifestAttachedFile";
import commonApi from "@/mixin/commonApi";

export default {
  name: "manifest-detail-after-issue",
  props: {
    manifestId: {
      type: Number,
    },
    isOwnedManifest: {
      type: Boolean,
    },
    isFirstCollectCompany: {
      type: Boolean,
    },
    formValue: {
      type: Object,
      required: true,
    },
    afterUpdateFunc: {
      type: Function,
      required: true,
    },
  },
  components: {
    FloatButtons,
    ToastMessage,
    ManifestProgressBar,
    ManifestFixApproveModal,
    CollectReportList,
    InterDisposalList,
    DisposalReportList,
    MyTextarea,
  },
  mixins: [
    commonApi,
    validation,
    callApi,
    route,
    idToName,
    textarea,
    harmfulType,
    validate,
    formatEditData,
    downloadManifestAttachedFile,
  ],

  data() {
    return {
      backPath: null,
      isApproveConfirmModalShow: false,
      isFixingApproveConfirmFlag: false, // 修正承認確認モーダル表示フラグ、false:取消承認、true:修正承認
      isDenyReasonModalShow: false,
      isCompleteConfirmModalShow: false,
      reportFixApprove: null,
      isApproved: true,
      denyReason: "",
      errMsgs: [],
      // マニフェスト修正依頼APIのエラーメッセージ
      modalErMsgs: [],
      waitCancelPendingStatus: [
        DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_DELETE_PENDING,
        DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_DELETE_PENDING,
      ],
      waitPendingStatus: [
        DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_FIX_PENDING,
        DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_FIX_PENDING,
        DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORT_FIX_PENDING,
      ],
      requestPendingStatus: DOMAIN.MANIFEST_STATUS.MANIFEST_FIX_PENDING,
      finalDisposalReported: DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORTED,
      isDeliveryDeadline: false,
      isDeliveryExpired: false,
      isDeliveryReportDeadline: false,
      isDeliveryReportExpired: false,
      isShowButtonForComplete: false,
      isCompleted: false, // 一覧画面遷移用
      collectReportDisplayType: CollectReportDisplayType,
      disposalReportDisplayType: DisposalReportDisplayType,
      interDisposalReportDisplayType: InterDisposalReportDisplayType,
      isCancelConfirmModalShow: false,
      isCancelAlertFinalDisposalSiteNGShow: false,
      toastMsg: "",
      isGenerator: this.$store.getters["userInfo/isGenerator"],
      isCollector: this.$store.getters["userInfo/isCollector"],
      isDisposer: this.$store.getters["userInfo/isDisposer"],
      isDisplayManifestFixApproveModal: false,
      isDisplayCollectReportDeleteApproveModal: false,
      manifestEditRequest: {},
      isChecksheetDownloadModalShow: false,
      checksheetDownloadTypes: [
        {
          label: "PDFダウンロード(複数一括)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF,
        },
        {
          label: "PDFダウンロード(単票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET,
        },
        {
          label: "PDFダウンロード(連票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET,
        },
        {
          label: "EXCELダウンロード",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.EXCEL_ZIP,
        },
      ],
      isDisableChecksheetDownload: false,
      checksheetDownloadMessage: "",
      isExpiredManifest: false,
      isCallApiProcess: false,
    };
  },
  computed: {
    isInProgress() {
      const statusId = this.formValue.manifestInfo.manifestStatusInfo.id;
      return statusId >= DOMAIN.MANIFEST_STATUS.UNDER_ISSUE && statusId < DOMAIN.MANIFEST_STATUS.COMPLETE;
    },
    isManifestFixPending() {
      return this.formValue.manifestInfo.manifestStatusInfo.id === this.requestPendingStatus;
    },
    isFirstCollectCompanyMember() {
      const firstCollectCompanyId = this.formValue.collectReportList ? this.formValue.collectReportList[0] ? this.formValue.collectReportList[0].collectCompany.id : null : null;
      return firstCollectCompanyId && firstCollectCompanyId === this.$store.getters["userInfo/get"].groupInfo.collectCompanyId;
    },
    canApproveManifestFixRequest() {
      return this.isManifestFixPending &&
        this.isCollector &&
        this.isFirstCollectCompanyMember &&
        this.manifestEditRequest &&
        this.manifestEditRequest.manifestEditRequestStatus === 1;
    },
    //取消ボタンと編集ボタンの非活性条件
    isDisabledFixButtonAndCancelButton() {
      if (!this.formValue || !this.formValue.manifestInfo || !this.formValue.manifestInfo.manifestStatusInfo) {
        return false;
      }
      const manifestStatus = this.formValue.manifestInfo.manifestStatusInfo.id;
      return manifestStatus != DOMAIN.MANIFEST_STATUS.ISSUED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.COLLECT_REPORTED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORTED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORTED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.COMPLETE;
    },
    isUnderDelete() {
      if (this.formValue.manifestInfo.manifestStatusInfo.id == null) {
        return false;
      }
      const statusId = this.formValue.manifestInfo.manifestStatusInfo.id;

      return statusId === DOMAIN.MANIFEST_STATUS.UNDER_DELETE;

    },
    isDeleted() {
      const statusId = this.formValue.manifestInfo.manifestStatusInfo.id;
      return statusId === DOMAIN.MANIFEST_STATUS.DELETE;
    },
    // 終了報告修正ボタンの表示条件
    // 確定状態、最終処分報告済みの場合：ボタンが表示、非活性
    // 終了報告修正可：ボタンが表示、活性
    isSealedStatusAndFinalDisposalReportedOrReportFixable() {
      const manifestStatus = this.formValue.manifestInfo.manifestStatusInfo.id;
      if (!this.formValue.isEdittable && manifestStatus == DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORTED) {
        return true;
      }
      return this.formValue.isCollectReportFixable;
    }
  },
  methods: {

    closeModalHandler() {
      this.isChecksheetDownloadModalShow = false;
    },

    // 受渡確認票印刷押下
    showChecksheetDownloadModal() {
      this.isChecksheetDownloadModalShow = true;
    },

    // 受渡確認票をダウンロード
    checksheetDownloadHandler(downloadTypeId) {
      this.errMsgs = [];

      this.isDisableChecksheetDownload = true;
      this.checksheetDownloadMessage = `${this.checksheetDownloadTypes.find(t => t.value === downloadTypeId).label}を行なっています。少々お待ちください。`;
      axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET
            , { downLoadTypeId: downloadTypeId, manifestIdList: [{manifestId: this.manifestId}] }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          if (downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET) {
            saveAs(blob, `受渡確認表(単票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET) {
            saveAs(blob, `受渡確認表(連票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF) {
            saveAs(blob, `PDFダウンロード(複数一括)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else {
            saveAs(blob, `受渡確認表_${moment().format("YYYYMMDDHHmmss")}.zip`);
          }
        })
        .then(() => {
          // ダウンロードボタンは活生にする
          this.isDisableChecksheetDownload = false;
          this.checksheetDownloadMessage = "";
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },

    setDeadlineCheckFlag() {
      const r = this.formValue.manifestInfo;
      const currentDate = new Date();
      if(r.expirationDate && moment().isAfter(r.expirationDate) && r.manifestStatusInfo.id === DOMAIN.MANIFEST_STATUS.RESERVED) {
        //予約済みmanifestの有効期限が切れている
        this.isExpiredManifest = true;
      }
      if (r.collectionDate != null && r.issueDate == null) {
        // 引き渡し日をdate型に変換
        const dates = r.collectionDate.split("-");
        const date = new Date(dates[0], dates[1], dates[2]);
        var collectionDatePuls2 = new Date(dates[0], dates[1], dates[2]);
        collectionDatePuls2.setDate(date.getDate() + 2);
        var collectionDatePuls3 = new Date(dates[0], dates[1], dates[2]);
        collectionDatePuls3.setDate(date.getDate() + 3);
        if (collectionDatePuls3 < currentDate) {
          // 交付期限切れ
          this.isDeliveryExpired = true;
        } else if (
          collectionDatePuls2 <= currentDate &&
          currentDate <= collectionDatePuls3
        ) {
          // 交付期限
          this.isDeliveryDeadline = true;
        }
      }

      // 報告アラート判定
      if (this.formValue.manifestInfo.issueDate != null) {
        if (this.formValue.routeInfo.jwnetItem.isSpecial) {
          // 特別管理産業廃棄物の場合
          let deadline = null
          if (!this.formValue.finalDisposalReportDate) {
            deadline = REPORT_DEADLINE.FINAL_DISPOSAL_REPORT_INTERVAL
          }

          if (!this.formValue.collectReportList || !this.formValue.collectReportList[this.formValue.collectReportList.length - 1].collectionReportDate
          || !this.formValue.disposalReportInfo || !this.formValue.disposalReportInfo.disposalReportDate) {
            deadline = REPORT_DEADLINE.SPECIAL_WASTE_REPORT_INTERVAL
          }

          if (deadline == null) return;

          this.setIsDeliveryReportFlag(this.formValue.manifestInfo.issueDate, deadline);
        } else {
          // 特別管理産業廃棄物でない場合
          let deadline = null;
          if (!this.formValue.finalDisposalReportDate) {
            deadline = REPORT_DEADLINE.FINAL_DISPOSAL_REPORT_INTERVAL
          }

          if (!this.formValue.collectReportList || !this.formValue.collectReportList[this.formValue.collectReportList.length - 1].collectionReportDate
              || !this.formValue.disposalReportInfo || !this.formValue.disposalReportInfo.disposalReportDate) {
            deadline = REPORT_DEADLINE.COLLECT_REPORT_INTERVAL
          }
          if (deadline == null) return;

          this.setIsDeliveryReportFlag(this.formValue.manifestInfo.issueDate, deadline);
        }
      }
    },

    // 中間運搬・処分報告と最終処分報告はアラートあるかどうかチェック
    setIsDeliveryReportFlag(issueDate, deadline) {
      if (!issueDate || !Number(deadline)) return ""; // 本番運用では発生しないが、交付日がない場合スルー
      const warning = deadline - REPORT_DEADLINE.WARNING_ALERT_INTERVAL; // 黄色の注意アラートは30日前から表示
      const today = moment();
      const targetDate = moment(issueDate);
      const diff = today.diff(targetDate, "days");
      if (warning < diff && diff <= deadline) {
        // 報告期限注意
        this.isDeliveryReportDeadline = true;
      } else if (diff > deadline) {
        // 報告期限切れ
        this.isDeliveryReportExpired = true;
      }
    },

    // 終了報告修正承認情報取得APIパス ＋ manifestId
    approveConfirmOpenHandler(isFixing) {
      this.getManifestReportfixApproveApi(this.manifestId, isFixing);
    },

    onClickApprove() {
      this.approveHandler(true);
    },

    onClickDenial() {
      this.approveHandler(false);
    },

    // アラート解除処理
    cancelFinalDisposalSiteAlert() {
      this.isCallApiProcess = true
      // 最終処分場アラート解除API
      axios
        .patch(`${API_URL.MANIFEST.CANCEL_ALERT_FINAL_DISPOSAL_SITE_NG}${this.manifestId}`)
        .then((res) => {
          this.isCancelAlertFinalDisposalSiteNGShow = false;
          const manifestId = res.data.manifestId;

          this.$router.push(`/manifest/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`, () => {
            this.afterUpdateFunc(manifestId).then(() => {
              this.isCallApiProcess = false
              this.init();
            });
          });
        })
        .catch((err) => {
          this.isCallApiProcess = false
          this.errMsgs.push(err);
        });
    },

    // 承認待ちモーダル内決定押下時
    approveHandler(isApprove) {
      this.isCallApiProcess = true
      var denialReason = this.denyReason;
      if (isApprove) {
        denialReason = null;
      }
      const url = `${API_URL.MANIFEST.PATCH_REPORT_FIX_APPROVE}${this.manifestId}`;
      axios
        .patch(url, { isApprove: isApprove, denialReason: denialReason })
        .then((res) => {
          this.isApproveConfirmModalShow = false;
          this.isDenyReasonModalShow = false;

          const manifestId = res.data.manifestId;

          this.$router.push(`/manifest/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`, () => {
            this.afterUpdateFunc(manifestId).then(() => {
              this.isCallApiProcess = false
              this.init();
            });
          });
        })
        .catch((err) => {
          this.isCallApiProcess = false
          this.errMsgs.push(err);
        });
    },
    // WDSファイルダウンロード
    downloadWDS(wasteInfoId, wdsFileName) {
      axios
        .get(API_URL.MANIFEST.WDS_DOWNLOAD + wasteInfoId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deliveryChecksheetHandler() {
      this.postDeliveryChecksheetApi(this.manifestId)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          const disposition = res.headers['content-disposition'];
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            const filename = matches[1].replace(/['"]/g, '').replace("UTF-8", '');
            saveAs(blob, filename);
          } else {
            saveAs(blob, 'ukewatasi.zip');
          }
        });
    },
    //マニフェスト完了画面の修正ボタンと受渡確認票ボタンの表示条件
    getIsShowButtonForComplete() {
      const manifestTypeId = this.formValue.manifestType ? this.formValue.manifestType.id : null;
      const wasteTypeId = this.formValue.wasteType ? this.formValue.wasteType.id : null;
      if (manifestTypeId === DOMAIN.MANIFEST_TYPE.ANALOG ||
          wasteTypeId === DOMAIN.WASTE_TYPE.VALUABLES ||
          wasteTypeId === DOMAIN.WASTE_TYPE.COMMON_WASTE) {
          this.isShowButtonForComplete = true;
      } else if (wasteTypeId === DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE && manifestTypeId === DOMAIN.MANIFEST_TYPE.DIGITAL && this.formValue.manifestInfo.issueDate) {
        const today = moment();
        const issueDate = moment(this.formValue.manifestInfo.issueDate);
        const diff = today.diff(issueDate, "days");
        if (0 <= diff && diff <= 180) {
          this.isShowButtonForComplete = true;
        }
      } else {
        this.isShowButtonForComplete = false;
      }

    },
    // 画面遷移用
    getIsCompletedBack() {
      const statusId = this.formValue.manifestInfo.manifestStatusInfo.id;
      this.isCompleted = statusId === DOMAIN.MANIFEST_STATUS.COMPLETE ||
          statusId === DOMAIN.MANIFEST_STATUS.DELETE ||
          statusId === DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORTED;
    },
    // ステータスを完了(80)に更新
    completeHandler() {
      this.isCallApiProcess = true;
      axios
        .patch(API_URL.MANIFEST.STATUS_UPDATE, {
          manifestIdList: [{ manifestId: this.manifestId }],
          manifestStatusId: DOMAIN.MANIFEST_STATUS.COMPLETE,
        })
        .then((res) => {
          this.isCompleteConfirmModalShow = false;
          this.isCallApiProcess = false;
          const manifestId = res.data.manifestIdInfoList.find(manifestIdInfo => manifestIdInfo.originalManifestId === this.manifestId).newManifestId;

          this.$router.push(`/manifest/${manifestId}`, () => {
            this.afterUpdateFunc(manifestId).then(() => {
              this.init();
            });
          });
        })
        .catch((err) => {
          this.isCallApiProcess = false;
          this.errMsgs.push(err.message);
        });
    },

    // 戻る押下
    backHandler() {
      if (this.backPath) {
        this.$router.push(this.backPath);
      } else {
        // 排出マニフェスト一覧に戻る
        if (this.isGenerator) {
          if (this.isCompleted) {
            this.$router.push('/manifests/complete');
          } else if (this.isInProgress) {
            this.$router.push('/manifests/progress');
          } else if (this.isOwnedManifest === false) {
            this.$router.push('/manifests/all');
          }
        } else {
          this.$router.push('/manifests/all');
        }
      }
    },

    // 取消押下
    cancelConfirmHandler() {
      this.errMsgs = [];
      const manifestStatusId = this.formValue.manifestInfo.manifestStatusInfo.id;

      // マニフェストステータスが、修正中(23)、修正承認待ち（manifest_status_id = 22,23,32,33,34,35,42,43,44)の場合、エラーとなります
      if (manifestStatusId === DOMAIN.MANIFEST_STATUS.MANIFEST_FIX_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.MANIFEST_FIXING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_COLLECT_REPORT_FIX
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_FIX_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.COLLECT_REPORT_DELETE_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_COLLECT_REPORT_DELETE
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_DISPOSAL_REPORT_FIX
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_FIX_PENDING
          || manifestStatusId === DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORT_DELETE_PENDING) {

        this.errMsgs.push("マニフェストが修正中のため、取消できません。");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else if (manifestStatusId === DOMAIN.MANIFEST_STATUS.UNDER_DELETE) {
        // 削除中（91)の場合もエラーとする
        this.errMsgs.push("マニフェストが削除中のため、取消できません。");
        window.scrollTo({ top: 0, behavior: "smooth" });
      } else {
        this.isCancelConfirmModalShow = true;
      }
    },
    // アラート解除押下
    cancelFinalDisposalSiteAlertConfirmHandler() {

      this.errMsgs = [];
      const isFinalDisposalSiteNG = this.formValue.isFinalDisposalSiteNG;
      if (!isFinalDisposalSiteNG) {
        this.errMsgs.push("アラート解除しました。");
      } else {
        this.isCancelAlertFinalDisposalSiteNGShow = true;
      }
    },

    // 取消、モーダル内のOK押下
    cancelHandler() {
      this.errMsgs = [];

      axios
          .patch(API_URL.MANIFEST.STATUS_UPDATE, {
            manifestIdList: [{ manifestId: this.manifestId }],
            manifestStatusId: DOMAIN.MANIFEST_STATUS.DELETE,
          })
          .then(() => {
            this.isCancelConfirmModalShow = false;
            if (this.isCollector) {
              this.$router.push('/manifests/all');
            } else {
              this.backHandler();
            }
          })
          .catch((err) => {
            this.isCancelConfirmModalShow = false;
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    displayManifestFixApproveModal() {
      this.isDisplayManifestFixApproveModal = true;
    },
    closeManifestFixApproveModal() {
      this.isDisplayManifestFixApproveModal = false;
    },
    afterEditApproveManifest(manifestId) {
      this.closeManifestFixApproveModal();
      this.$router.push(`/manifest/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`, () => {
        this.afterUpdateFunc(manifestId).then(() => {
          this.init();
        });
      });
    },
    onClickManifestEditRequestApprove() {
      this.putManifestEditApproveManifestIdApprove(this.manifestId)
        .then(this.afterEditApproveManifest)
        .catch((err) => {
          this.modalErMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },
    onClickManifestEditRequestDenial(denyReason) {
      this.putManifestEditApproveManifestIdDenial(this.manifestId, denyReason)
        .then(this.afterEditApproveManifest)
        .catch((err) => {
          this.modalErMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },
    init() {
      this.backPath = this.$route.query.transitionSource;
      this.setDeadlineCheckFlag();
      this.getIsCompletedBack();
      this.getIsShowButtonForComplete();
      this.getPrefecturesApi();
      if (this.isManifestFixPending) {
        this.getManifestEditRequestApi(this.manifestId);
      }
      if(this.formValue.manifestInfo.manifestNo) {
        this.getIsJwnetReffering(this.formValue.manifestInfo.manifestNo)
      }

      while (this.formValue.contactNoInfo.length < 3) {
        let index = this.formValue.contactNoInfo.length + 1 // IDは利用しないので、一旦インデックスで設定
        this.formValue.contactNoInfo.push({ id: index, contactNo: ""});
      }

      while (this.formValue.remarksInfo.length < 5) {
        let index = this.formValue.remarksInfo.length + 1 // IDは利用しないので、一旦インデックスで設定
        this.formValue.remarksInfo.push({ id: index, detail: ""});
      }

    },

    /** マニフェスト添付ファイルダウンロード押下 */
    downloadManifestAttachedFile(fileName) {
      this.downloadManifestAttachedFileApi(this.manifestId, fileName);
    },

    // 運搬終了報告取消ボタンを押下する
    deleteCollectReportHandler() {
      this.isDisplayCollectReportDeleteApproveModal = true
    },

    // 運搬終了報告の取消を確認した後、運搬終了報告を取り消す
    confirmCollectReportDeleteHandler() {
      this.errMsgs = [];
      axios
          .patch(`${API_URL.MANIFEST.COLLECT_REPORT_DELETE}/${this.manifestId}`)
          .then(() => {
            this.isDisplayCollectReportDeleteApproveModal = false;
            this.$router.push('/manifests/all');
          })
          .catch((err) => {
            this.isDisplayCollectReportDeleteApproveModal = false;
            this.processing = false; // 2重submit解除
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
