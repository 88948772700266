<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA加入編集（排出）" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- 企業情報 ======================================================================== -->
        <div
          class="l-contents"
          v-for="(company, companyIndex) in form.generateCompanyInfoList"
          :key="'company-' + companyIndex"
        >
          <div class="l-box">
            <div class="l-box u-space-btwn">
              <h3 class="c-lead icon">
                排出事業者情報
                <span v-if="form.generateCompanyInfoList.length > 1">{{
                  companyIndex + 1
                }}</span>
              </h3>
              <div
                v-if="companyIndex"
                class="c-btn secondary sm delete"
                @click="deleteFormHandler(company.formId)"
              >
                削除
              </div>
            </div>
            <div v-if="getFormValidate(company.formId)">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>事業者名</label>
                </div>
                <div class="c-inputWrap__items">
                  <div>
                    <InputText
                      :value.sync="company.name"
                      inputType="text"
                      placeholder="会社名/営業所名"
                      tooltip="100文字以内で入力してください"
                      :validation="validateMaxLen"
                      :validateArg="100"
                      :isValid.sync="
                        getFormValidate(company.formId).companyName.isValid
                      "
                      :errMsg.sync="
                        getFormValidate(company.formId).companyName.errMsg
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>住所</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>郵便番号</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div class="c-inputWrap__items__cols center sm-mgn">
                        <InputText
                          :value.sync="company.addressInfo.zipCode"
                          inputType="number"
                          placeholder="1234567(ハイフンなし)"
                          tooltip="7桁の半角数字で入力してください。"
                          :validation="validateNumberEqualLen"
                          :validateArg="7"
                          :isValid.sync="
                            getFormValidate(company.formId).zipCode.isValid
                          "
                          :errMsg.sync="
                            getFormValidate(company.formId).zipCode.errMsg
                          "
                        />
                        <div
                          class="c-btn secondary sm"
                          @click="searchAddressHandler(company.formId, 0, 0)"
                        >
                          住所自動入力
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>都道府県</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <Selectbox
                          class=""
                          name="prefecture"
                          :value.sync="company.addressInfo.prefecturesName"
                          :validation="validateJustBlank"
                          :isValid.sync="
                            getFormValidate(company.formId).prefecture.isValid
                          "
                          :errMsg.sync="
                            getFormValidate(company.formId).prefecture.errMsg
                          "
                        >
                          <option
                            v-for="prefecture in prefectures"
                            :key="prefecture.id"
                            :value="prefecture.name"
                          >
                            {{ prefecture.name }}
                          </option>
                        </Selectbox>
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>市区町村</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <InputText
                          :value.sync="company.addressInfo.address"
                          inputType="text"
                          placeholder="市町村"
                          tooltip="100文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="100"
                          :isValid.sync="
                            getFormValidate(company.formId).address.isValid
                          "
                          :errMsg.sync="
                            getFormValidate(company.formId).address.errMsg
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>番地建物名号室</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <InputText
                          :value.sync="company.addressInfo.buildingName"
                          inputType="text"
                          placeholder="番地建物名号室"
                          tooltip="100文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="100"
                          :isValid.sync="
                            getFormValidate(company.formId).building.isValid
                          "
                          :errMsg.sync="
                            getFormValidate(company.formId).building.errMsg
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- /.c-inputWrap -->
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>電話番号</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="company.tel"
                    inputType="number"
                    placeholder="電話番号"
                    tooltip="半角数値で入力してください"
                    :validation="validateNumberMaxLen"
                    :validateArg="15"
                    :isValid.sync="getFormValidate(company.formId).tel.isValid"
                    :errMsg.sync="getFormValidate(company.formId).tel.errMsg"
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>FAX番号</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="company.fax"
                    inputType="number"
                    placeholder="FAX番号"
                    tooltip="半角数値で入力してください"
                    :validation="validateNumberMaxLen"
                    :validateArg="15"
                    :isValid.sync="getFormValidate(company.formId).fax.isValid"
                    :errMsg.sync="getFormValidate(company.formId).fax.errMsg"
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>JWNETID(加入者番号)</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="company.jwnetContractNo"
                    inputType="text"
                    placeholder="JWNETID(加入者番号)"
                    tooltip="7桁の半角数値で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="7"
                    :isValid.sync="
                      getFormValidate(company.formId).jwnetContractNo.isValid
                    "
                    :errMsg.sync="
                      getFormValidate(company.formId).jwnetContractNo.errMsg
                    "
                  />
                </div>
              </div>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>EDI利用確認キー</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="company.ediKey"
                    inputType="text"
                    placeholder="EDI利用確認キー"
                    tooltip="8桁の半角数値で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="8"
                    :isValid.sync="
                      getFormValidate(company.formId).ediKey.isValid
                    "
                    :errMsg.sync="getFormValidate(company.formId).ediKey.errMsg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(factory, factoryIndex) in company.generateFactoryInfoList"
            :key="'factory-' + factoryIndex"
          >
            <div
              class="l-box"
              v-if="getFormValidateFactory(company.formId, factory.factoryId)"
            >
              <div class="l-box u-space-btwn">
                <h3 class="c-lead icon">
                  排出事業者情報
                  <span v-if="form.generateCompanyInfoList.length > 1">{{
                    companyIndex + 1
                  }}</span>
                  に紐づく事業所/事業場情報
                  <span v-if="form.generateCompanyInfoList[companyIndex].generateFactoryInfoList.length > 1">{{factoryIndex + 1}}</span>
                </h3>
                <div
                  class="c-btn secondary sm delete"
                  @click="deleteFactoryHandler(company.formId, factory.factoryId)"
                >
                  削除
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>事業所名</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="factory.name"
                    inputType="text"
                    placeholder="事業所名"
                    tooltip="100文字以内で入力してください。"
                    :validation="validateMaxLen"
                    :validateArg="100"
                    :isValid.sync="
                      getFormValidateFactory(company.formId, factory.factoryId)
                        .name.isValid
                    "
                    :errMsg.sync="
                      getFormValidateFactory(company.formId, factory.factoryId)
                        .name.errMsg
                    "
                  />
                </div>
              </div>
            </div>
            <div
              v-for="(store, storeIndex) in factory.generateStoreInfoList"
              :key="'store-' + storeIndex"
            >
              <div
                class="l-box"
                v-if="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId)"
              >
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label required">
                    <label>事業場名</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <InputText
                      :value.sync="store.name"
                      inputType="text"
                      placeholder="事業場名"
                      tooltip="100文字以内で入力してください。"
                      :validation="validateMaxLen"
                      :validateArg="100"
                      :isValid.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).name.isValid"
                      :errMsg.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).name.errMsg"
                    />
                  </div>
                  <div
                    class="c-btn secondary sm delete"
                    @click="deleteStoreHandler(company.formId, factory.factoryId, store.storeId)"
                  >
                    削除
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label required">
                    <label>事業場住所</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>郵便番号</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div class="c-inputWrap__items__cols center sm-mgn">
                          <InputText
                            :value.sync="store.addressInfo.zipCode"
                            inputType="number"
                            placeholder="1234567(ハイフンなし)"
                            tooltip="7桁の半角数字で入力してください。"
                            :validation="validateNumberEqualLen"
                            :validateArg="7"
                            :isValid.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).zipCode.isValid"
                            :errMsg.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).zipCode.errMsg"
                          />
                          <div
                            class="c-btn secondary sm"
                            @click="searchAddressHandler(company.formId, factory.factoryId, store.storeId)"
                          >
                            住所自動入力
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>都道府県</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <Selectbox
                            class=""
                            name="store-prefecture"
                            :value.sync="store.addressInfo.prefecturesName"
                            :validation="validateJustBlank"
                            :isValid.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).prefecture.isValid"
                            :errMsg.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).prefecture.errMsg"
                          >
                            <option
                              v-for="prefecture in prefectures"
                              :key="prefecture.id"
                              :value="prefecture.name"
                            >
                              {{ prefecture.name }}
                            </option>
                          </Selectbox>
                        </div>  
                      </div>
                    </div>
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>市区町村</label>
                      </div>
                      <div class="c-inputWrap__items">
                       <div>
                          <InputText
                            :value.sync="store.addressInfo.address"
                            inputType="text"
                            placeholder="市町村"
                            tooltip="100文字以内で入力してください"
                            :validation="validateMaxLen"
                            :validateArg="100"
                            :isValid.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).address.isValid"
                            :errMsg.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).address.errMsg"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>番地建物名号室</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div>
                          <InputText
                            :value.sync="store.addressInfo.buildingName"
                            inputType="text"
                            placeholder="番地建物名号室"
                            tooltip="100文字以内で入力してください"
                            :validation="validateMaxLen"
                            :validateArg="100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label required">
                    <label>事業場電話番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                   <InputText
                      :value.sync="store.tel"
                      inputType="number"
                      placeholder="電話番号"
                      tooltip="半角数値で入力してください"
                      :validation="validateNumberMaxLen"
                      :validateArg="15"
                      :isValid.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).tel.isValid"
                      :errMsg.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).tel.errMsg"
                    />
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>事業場FAX番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <InputText
                      :value.sync="store.fax"
                      inputType="number"
                      placeholder="FAX番号"
                      tooltip="半角数値で入力してください"
                      :validation="validateNumberMaxLen"
                      :validateArg="15"
                      :isValid.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).fax.isValid"
                      :errMsg.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).fax.errMsg"
                    />
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>空予約自動保有件数</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <InputText
                      :value.sync="store.autoEmptyReservePossessNumb"
                      inputType="number"
                      placeholder="空予約自動保有件数"
                      tooltip="半角で数値は0～100を入力してください"
                      :validation="validateHalfPositiveNumbMax"
                      :validateArg="100"
                      :isValid.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).autoEmptyReservePossessNumb.isValid"
                      :errMsg.sync="getFormValidateFactoryStore(company.formId, factory.factoryId, store.storeId).autoEmptyReservePossessNumb.errMsg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="l-each sm l-box">
              <div class="c-btn secondary func add" @click="addStoreHandler(company.formId, factory.factoryId)">
                排出事業場情報追加
              </div>
            </div>
          </div>
          <div class="l-each sm l-box">
            <div class="c-btn secondary func add" @click="addFactoryHandler(company.formId)">
              排出事業所情報追加
            </div>
          </div>
        </div>
        <!-- /.l-contents -->
        <!-- v-for ここまで -->

        <div class="l-each sm l-box">
          <div class="c-btn secondary func add" @click="addFormHandler">
            排出事業者情報追加
          </div>
        </div>

        <div class="l-btns">
          <div class="c-btn secondary small" @click="backHandler">戻る</div>
          <div class="c-btn primary mid" @click="checkRequiredHandler">
            登録
          </div>
        </div>
      </div>
      <!-- /.l-container -->
    </main>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      :targetForm.sync="targetForm"
    />

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          契約情報を登録します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";

import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import AddressSelectModal from "../components/AddressSelectModal.vue";

import validation from "@/mixin/validation";
import formValueEdit from "../mixins/formValueEdit";
import callApi from "../mixins/callApi";
import addressSearch from "../mixins/form/addressSearch"; // 住所検索処理
import formValidationEdit from "../mixins/form/formValidationEdit";
import { API_URL } from "@/const";
import common from "@/mixin/common";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "step2-edit",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    AddressSelectModal,
  },
  mixins: [
    validation,
    formValueEdit,
    callApi,
    addressSearch,
    formValidationEdit,
    common,
  ],
  data() {
    return {
      contractId: null,
      isConfirmModalShow: false,
      errMsgs: [],
      targetForm: {},
      postData: [], // POSTするデータ。formValue.jsで定義しているものを「次へ」押下時にフォーマットしたもの。
      processing: false,
    };
  },

  methods: {
    // 排出事業者情報追加を押下  post用のformのOBJとバリデーション用のPBJを追加
    addFormHandler() {
      var newFormId = 1;
      if (this.form.generateCompanyInfoList.length > 0) {
        newFormId = this.form.generateCompanyInfoList[
          this.form.generateCompanyInfoList.length - 1
        ].formId + 1;
      }
      const newList = {
        formId: newFormId,
        generateContractId: this.contractId,
        id: null,
        name: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          prefecturesName: "",
          address: "",
          buildingName: "",
        },
        tel: "",
        fax: "",
        jwnetContractNo: "",
        ediKey: "",
        isActive: false,
        generateFactoryInfoList: [],
      };

      const newValidate = {
        formId: newFormId,
        companyName: { isValid: true, errMsg: "" },
        zipCode: { isValid: true, errMsg: "" },
        prefecture: { isValid: true, errMsg: "" },
        address: { isValid: true, errMsg: "" },
        building: { isValid: true, errMsg: "" },
        tel: { isValid: true, errMsg: "" },
        fax: { isValid: true, errMsg: "" },
        jwnetContractNo: { isValid: true, errMsg: "" },
        ediKey: { isValid: true, errMsg: "" },
        generateFactoryInfoList: [],
      };

      this.form.generateCompanyInfoList = [
        ...this.form.generateCompanyInfoList,
        newList,
      ];
      this.validate = [...this.validate, newValidate];
    },
    addFactoryHandler(formId) {
      var newFactoryId = 1;
      if (this.form.generateCompanyInfoList[formId - 1]
            .generateFactoryInfoList.length != 0) {
        newFactoryId = this.form.generateCompanyInfoList[formId - 1]
          .generateFactoryInfoList[
            this.form.generateCompanyInfoList[formId - 1].generateFactoryInfoList.length - 1
          ].factoryId + 1;
      }

      const newFactoryList = {
        factoryId: newFactoryId,
        id: null,
        isActive: false,
        name: "",
        generateStoreInfoList: [],
      };

      const newFactoryValidate = {
        factoryId: newFactoryId,
          name: { isValid: true, errMsg: "", isRequired: true },
          generateStoreInfoList: [],
      };

      this.form.generateCompanyInfoList[formId - 1]
        .generateFactoryInfoList.push(newFactoryList);
      this.validate[formId - 1].generateFactoryInfoList.push(newFactoryValidate);

    },
    addStoreHandler(formId, factoryId) {
      var newStoreId = 1;
      if (this.form.generateCompanyInfoList[formId - 1]
          .generateFactoryInfoList[factoryId - 1].generateStoreInfoList.length != 0) {
        newStoreId = this.form.generateCompanyInfoList[formId - 1]
          .generateFactoryInfoList[factoryId - 1].generateStoreInfoList[
            this.form.generateCompanyInfoList[formId - 1]
            .generateFactoryInfoList[factoryId - 1]
            .generateStoreInfoList.length - 1
          ].storeId + 1;
      }

      const newStoreList = {
        storeId: newStoreId,
        id: null,
        name: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          prefecturesName: "",
          address: "",
          buildingName: "",
        },
        tel: "",
        fax: "",
        autoEmptyReservePossessNumb: 0,
      };

      const newStoreValidate = {
        storeId: newStoreId,
        name: { isValid: true, errMsg: "", isRequired: true },
        zipCode: { isValid: true, errMsg: "", isRequired: true },
        prefecture: { isValid: true, errMsg: "", isRequired: true },
        address: { isValid: true, errMsg: "", isRequired: true },
        tel: { isValid: true, errMsg: "", isRequired: true },
        fax: { isValid: true, errMsg: "", isRequired: false },
        autoEmptyReservePossessNumb: { isValid: true, errMsg: "", isRequired: false },
      };

      this.form.generateCompanyInfoList[formId - 1]
        .generateFactoryInfoList[factoryId - 1]
        .generateStoreInfoList.push(newStoreList);
      this.validate[formId - 1]
        .generateFactoryInfoList[factoryId - 1]
        .generateStoreInfoList.push(newStoreValidate);
    },

    // form削除 post用のformのOBJとバリデーション用のPBJを削除
    deleteFormHandler(formId) {
      this.form.generateCompanyInfoList =
        this.form.generateCompanyInfoList.filter(
          (list) => list.formId != formId
        );
      this.validate = this.validate.filter((list) => list.formId != formId);
    },
    deleteFactoryHandler(formId, factoryId) {
      this.form.generateCompanyInfoList[formId - 1].generateFactoryInfoList = 
        this.form.generateCompanyInfoList[formId - 1]
        .generateFactoryInfoList.filter(
          (list) => list.factoryId != factoryId
        );
      this.validate[formId - 1].generateFactoryInfoList = 
        this.validate[formId - 1]
        .generateFactoryInfoList.filter((list) => list.factoryId != factoryId);
    },
    deleteStoreHandler(formId, factoryId, storeId) {
      this.form.generateCompanyInfoList[formId - 1].generateFactoryInfoList[factoryId - 1].generateStoreInfoList =
        this.form.generateCompanyInfoList[formId - 1].generateFactoryInfoList[factoryId - 1]
        .generateStoreInfoList.filter(
          (list) => list.storeId != storeId
        );
      this.validate[formId - 1].generateFactoryInfoList[factoryId - 1].generateStoreInfoList = 
        this.validate[formId - 1].generateFactoryInfoList[factoryId - 1]
        .generateStoreInfoList.filter((list) => list.storeId != storeId);
    },

    /**
     * formデータをフォーマット
     * 不要なformIdを削除
     * 都道府県をIDに変換
     * フォーマットされたデータを返す(Promise)
     */
    formatPostData() {
      return new Promise((resolve) => {
        var formData = this.form.generateCompanyInfoList.map((company) => {
          var generateFactoryInfoList = [];
          company.generateFactoryInfoList.map((factory) => {
            var generateStoreInfoList = [];
            factory.generateStoreInfoList.map((store) => {
              this.prefectures.map((prefecture) => {
                if (prefecture.name === store.addressInfo.prefecturesName) {
                  store.addressInfo.prefecturesId = prefecture.id
                }
              });
              const generateStoreInfo = {
                storeName: store.name,
                addressInfo: {
                  zipCode: store.addressInfo.zipCode,
                  prefecturesId: store.addressInfo.prefecturesId,
                  address: this.toFullWidthStringForAddressInfo(store.addressInfo.address), // 住所の全角変換
                  buildingName: this.toFullWidthStringForAddressInfo(store.addressInfo.buildingName), // 住所の全角変換
                },
                tel: store.tel,
                storeId: store.id,
                fax: store.fax,
                autoEmptyReservePossessNumb: store.autoEmptyReservePossessNumb ? store.autoEmptyReservePossessNumb : 0,
              };
              generateStoreInfoList.push(generateStoreInfo);
            });
            const generateFactoryInfo = {
              factoryName: factory.name,
              editGenerateStoreList: generateStoreInfoList,
              factoryId: factory.id,
            };
            generateFactoryInfoList.push(generateFactoryInfo);
          });
          this.prefectures.map((prefecture) => {
            if (prefecture.name === company.addressInfo.prefecturesName) {
              company.addressInfo.prefecturesId = prefecture.id
            }
          });
          const generateCompanyInfo = {
            companyName: company.name,
            addressInfo: {
              zipCode: company.addressInfo.zipCode,
              prefecturesId: company.addressInfo.prefecturesId,
              address: this.toFullWidthStringForAddressInfo(company.addressInfo.address), // 住所の全角変換
              buildingName: this.toFullWidthStringForAddressInfo(company.addressInfo.buildingName), // 住所の全角変換
            },
            tel: company.tel,
            jwnetContractNo: company.jwnetContractNo,
            ediKey: company.ediKey,
            editGenerateFactoryList: generateFactoryInfoList,
            companyId: company.id,
            fax: company.fax,
          };
          return generateCompanyInfo;
        });
        let newData = JSON.parse(JSON.stringify(formData));

        resolve(newData);
      });
    },

    // post
    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      axios
        .put(API_URL.GENERATOR.EDIT_COMPANY + this.contractId, { editGenerateCompanyList: this.postData })
        .then(() => {
          // 排出事業者契約一覧画面
          this.$router.push("/generator/contract/");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    // 次へ押下
    async checkRequiredHandler() {
      this.errMsgs = [];

      const isValid = await this.runValidate(this.form.generateCompanyInfoList);
      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      // 都道府県をIDに変えてthis.formに追加してからpost
      this.formatPostData().then((res) => {
        this.isConfirmModalShow = true;
        this.postData = res;
      });
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.push(`/generator/contract/step1/edit/${this.contractId}`);
    },

    // モーダル内戻る押下
    closeModalHandler() {
      this.postData = [];
      this.isConfirmModalShow = false;
    },
    // モーダル内登録押下
    registerHandler() {
      this.postForm();
    },
  },

  mounted() {
    this.contractId = Number(this.$route.params.id);

    if (!this.contractId) {
      this.$router.push("/generator/contract/step1/input");
    }

    this.getPrefecturesApi()
      .then(() => {
        this.getGeneratorContractInfoApi(this.contractId);
      });
  },
};
</script>
