<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="運搬終了報告" />
    <AppSideMenu />
    <main class="l-main" v-if="formValue">
      <div class="l-container l-area">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- マニフェスト情報 -->
        <ManifestInfo :manifestId="manifestId" :formValue="formValue" />

        <!-- 廃棄物情報 -->
        <DisposalInfo
          :formValue="formValue"
          :routeCompanyName="routeCompanyName"
          :wasteUnitOpt="wasteUnitOpt"
          :narrowedRoutesList="narrowedRoutesList"
        />

        <!-- 運搬終了報告  -->
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">運搬終了報告</h3>
          </div>
          <div v-if="formValue.collectReportList.length">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>運搬終了日</label>
              </div>
              <div class="c-inputWrap__items">
                <InputDate
                  :value.sync="form.collectionReportDate"
                  :placeholder="'0000/00/00'"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.collectionReportDate.isValid"
                  :errMsg.sync="validate.collectionReportDate.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>運搬担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                        id="collect-member-select"
                        type="radio"
                        :value="true"
                        v-model="isCollectMemberInputForm"
                        v-on:change="changeCollectMemberInputForm"
                    />
                    <label class="c-radio__label" for="collect-member-select">
                      <span class="c-radio__box"></span>リストから選択
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                        id="collect-member-input"
                        type="radio"
                        :value="false"
                        v-model="isCollectMemberInputForm"
                        v-on:change="changeCollectMemberInputForm"
                    />
                    <label class="c-radio__label" for="collect-member-input">
                      <span class="c-radio__box"></span>直接入力
                    </label>
                  </div>
                </div>
                <div class="c-inputWrap__items__cols">
                  <Selectbox
                      v-if="isCollectMemberInputForm"
                      class=""
                      name="register-in-charge"
                      :value.sync="form.collectMemberInfo.id"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.collectMemberInfo.isValid"
                      :errMsg.sync="validate.collectMemberInfo.errMsg"
                  >
                    <option
                        v-for="(opt, index) in memberOpt"
                        :key="'mp' + index"
                        :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <InputText
                      v-if="isCollectMemberInputForm == false"
                      style="width: 22rem"
                      placeholder="入力してください"
                      tooltip="12文字以内で入力してください"
                      :validation="validateMaxLenWithoutOnlyWhitespace"
                      :validateArg="12"
                      inputType="text"
                      input_id="input_collectMember"
                      :value.sync="form.collectMemberInfo.name"
                      :isValid.sync="validate.collectMemberInfo.isValid"
                      :errMsg.sync="validate.collectMemberInfo.errMsg"
                  />
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>報告担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <div class="c-radio">
                    <input
                      id="report-member-select"
                      type="radio"
                      :value="true"
                      v-model="isReportMemberInputForm"
                      v-on:change="changeReportMemberInputForm"
                    />
                    <label class="c-radio__label" for="report-member-select">
                      <span class="c-radio__box"></span>リストから選択
                    </label>
                  </div>
                  <div class="c-radio">
                    <input
                      id="report-member-input"
                      type="radio"
                      :value="false"
                      v-model="isReportMemberInputForm"
                      v-on:change="changeReportMemberInputForm"
                    />
                    <label class="c-radio__label" for="report-member-input">
                      <span class="c-radio__box"></span>直接入力
                    </label>
                  </div>
                </div>
                <div class="c-inputWrap__items__cols">
                  <Selectbox
                    v-if="isReportMemberInputForm"
                    class=""
                    name="register-in-charge"
                    :value.sync="form.reportMemberInfo.id"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.reportMemberInfo.isValid"
                    :errMsg.sync="validate.reportMemberInfo.errMsg"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in memberOpt"
                      :key="'mp' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                  <InputText
                    v-if="isReportMemberInputForm == false"
                    style="width: 22rem"
                    placeholder="入力してください"
                    tooltip="12文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="12"
                    inputType="text"
                    input_id="input_reportMember"
                    :value.sync="form.reportMemberInfo.name"
                    :isValid.sync="validate.reportMemberInfo.isValid"
                    :errMsg.sync="validate.reportMemberInfo.errMsg"
                  />
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬方法</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  class=""
                  name="register-in-charge"
                  :value.sync="form.collectWay.id"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.collectWay.isValid"
                  :errMsg.sync="validate.collectWay.errMsg"
                >
                  <option :value="null"></option>
                  <option
                    v-for="(opt, index) in collectWayTypeList"
                    :key="'mp' + index"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label for="">車両番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="form.carNo"
                  :validation="validateJustBlank"
                  tooltip="車両番号を入力してください。"
                  :isValid.sync="validate.carNo.isValid"
                  :errMsg.sync="validate.carNo.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label" v-bind:class="[isCollectQuantityRequired ? 'required' : '']">
                <label>運搬量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputText
                    inputType="number"
                    :value.sync="form.collectQuantity"
                    :validation="validateNumberOrFloat"
                    tooltip="運搬量は数字で入力してください。"
                    :isValid.sync="validate.collectQuantity.isValid"
                    :errMsg.sync="validate.collectQuantity.errMsg"
                  />
                  <Selectbox
                    class="sm"
                    name="unit"
                    :value.sync="form.collectWasteUnitType.id"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.collectWasteUnitType.isValid"
                    :errMsg.sync="validate.collectWasteUnitType.errMsg"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in wasteUnitOpt"
                      :key="'wu' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>有価収拾量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputText
                    inputType="number"
                    :value.sync="form.valuablesQuantity"
                    :validation="validateNumberOrFloat"
                    tooltip="有価収拾量は数字で入力してください。"
                    :isValid.sync="validate.valuablesQuantity.isValid"
                    :errMsg.sync="validate.valuablesQuantity.errMsg"
                  />
                  <Selectbox
                    class="sm"
                    name="unit"
                    :value.sync="form.valuablesUnitType.id"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.valuablesUnitType.isValid"
                    :errMsg.sync="validate.valuablesUnitType.errMsg"
                  >
                    <option :value="null"></option>
                    <option
                      v-for="(opt, index) in wasteUnitOpt"
                      :key="'wu' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label for="">備考</label>
              </div>
              <div class="c-inputWrap__items">
                <MyTextarea
                  placeholder="備考を入力してください"
                  :value.sync="form.remark"
                  rows="6"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.remark.isValid"
                  :errMsg.sync="validate.remark.errMsg"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->
      </div>
      <div class="l-btns">
        <router-link
          :to="`/manifest/${manifestId}`"
          class="c-btn secondary small"
          >戻る</router-link
        >
        <div class="c-btn primary mid" :class="{ disabled: !form.isCollectReportFixable || !formValue.isEdittable || isJwnetReferring }" @click="checkRequiredHandler">登録</div>
      </div>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          修正内容を登録します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="() => (isConfirmModalShow = false)"
          >
            戻る
          </div>
          <div class="c-btn primary small" :class="{ disabled: isCallApiProcess }" @click="submit">OK</div>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import ManifestInfo from "../components/ManifestInfo";
import DisposalInfo from "../components/DisposalInfo";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import MyTextarea from "@/components/parts/input/MyTextarea";

import validation from "@/mixin/validation";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import route from "../mixins/route";
import validate from "../mixins/validate";
import common from "@/mixin/common";
import memberInput from "@/pages/manifest/collect/report/common/memberInput";
import commonApi from "@/mixin/commonApi";

import { API_URL, QUANTITY_CONFIRM_BUSSINESS_TYPE } from "@/const/index";

export default {
  name: "manifest-collect-report",
  components: {
    ManifestInfo,
    DisposalInfo,
    InputText,
    Selectbox,
    InputDate,
    MyTextarea,
  },
  mixins: [commonApi, validation, formValue, callApi, route, validate, common, memberInput],
  data() {
    return {
      manifestId: null,
      isConfirmModalShow: false,
      errMsgs: [],
      isCallApiProcess: false,
      isCollectMemberInputForm: true,
      isReportMemberInputForm: true,
    };
  },
  methods: {
    // 画面内下部の登録押下
    checkRequiredHandler() {
      this.errMsgs = [];
      if (!this.runValidate()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      };
      this.isConfirmModalShow = true;
    },

    submit() {
      this.isCallApiProcess = true
      const beforeModifyReportMember = this.form.reportMemberInfo
      this.handleCollectMember()
      this.handleReportMember()
      const postForm = {
        manifestId : this.manifestId,
        collectMember : this.form.collectMemberInfo,
        reportMember : this.form.reportMemberInfo,
        collectWasteUnitTypeId : this.form.collectWasteUnitType.id,
        valuablesUnitTypeId : this.form.valuablesUnitType.id,
        collectionReportDate : this.form.collectionReportDate,
        collectWayTypeId: this.form.collectWay.id,
        carNo : this.form.carNo ? this.toFullWidthString(this.form.carNo) : this.form.carNo,
        collectQuantity : this.form.collectQuantity,
        valuablesQuantity : this.form.valuablesQuantity,
        remarks : this.form.remark ? this.toFullWidthString(this.form.remark) : this.form.remark,
        needsIssueBeforeCollectReport: false,
      }
      axios
        .put(`${API_URL.MANIFEST.COLLECT_REPORT_EDIT}${this.manifestId}`, postForm)
        .then(() => {
          if (this.backPath) {
            this.$router.push(this.backPath);
          } else {
            this.$router.push("/manifests/all");
          }
        })
        .catch((err) => {
          this.form.reportMemberInfo = beforeModifyReportMember;
          this.isConfirmModalShow = false;
          this.isCallApiProcess = false
          this.errMsgs.push(err.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
  },

  computed: {
    isCollectQuantityRequired() {
      return this.formValue.wasteInfo.quantityConfirmBusinessType && this.formValue.wasteInfo.quantityConfirmBusinessType.name !== QUANTITY_CONFIRM_BUSSINESS_TYPE.GENERATOR && this.formValue.wasteInfo.quantityConfirmBusinessType.name != QUANTITY_CONFIRM_BUSSINESS_TYPE.DISPOSER
    },
  },

  mounted() {
    this.backPath = this.$route.query.transitionSource;
    // URLからID取得
    this.manifestId = +this.$route.params.id;
    // 初期表示に必要なAPIを呼ぶ
    this.getManifestInfoApi(this.manifestId).then(() => {
      this.getRouteListApi(this.formValue.routeInfo.generateStore.id)
      this.isCollectMemberInputForm = this.form.collectMemberInfo.id != null
      this.isReportMemberInputForm = this.form.reportMemberInfo.id != null
      if(this.formValue.manifestInfo.manifestNo) {
        this.getIsJwnetReffering(this.formValue.manifestInfo.manifestNo)
      }
    });
    this.getCollectMemberOptApi();
    this.getWasteUnitApi();
    this.getCollectCollectWayTypeApi();
  },
};
</script>
<style>
textarea {
  font-family : inherit;
}
</style>
