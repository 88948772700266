<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="ルート一覧" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />

      <!-- 検索フォーム ============================================================ -->
      <div>
        <div class="c-searchMenu__wrap">
          <div v-if="isDisplaySearch" class="c-searchMenu">
            <div class="l-flex">
              <div v-if="shorten().length === 0">
                <div class="title icon">条件なし</div>
              </div>
              <template v-else v-for="(s, sindex) in shorten()">
                <div
                  class="title"
                  :class="[{ icon: sindex === 0 }]"
                  :key="'shorten-' + sindex"
                >
                  {{ s.key }}
                  <div class="data">: {{ s.value }}</div>
                </div>
              </template>
            </div>
            <div class="l-flex">
              <div class="c-btn secondary sm" @click="scrollTop">
                検索条件を変更
              </div>
            </div>
          </div>
        </div>
        <div class="l-full-white l-area">
          <div class="l-box l-flex">
            <h3 class="c-lead-s-black icon">検索</h3>
            <div class="c-tabs l-area-l">
              <div
                class="c-tabs__each"
                :class="{ active: !isSearchDetail }"
                @click="
                  isSearchDetail = false;
                  initSearch(true);
                "
              >
                簡易
              </div>
              <div
                class="c-tabs__each"
                :class="{ active: isSearchDetail }"
                @click="
                  isSearchDetail = true;
                  initSearch(false);
                "
              >
                詳細
              </div>
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols">
                <InputText
                  name="routeNameList"
                  class="xlg"
                  :value.sync="searchParam.routeNameSearchText"
                  inputType="text"
                  placeholder="ルート名称を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                  <span class="text-label">ルート名称</span>
                </InputText>
                <InputText
                  name="routeIdList"
                  class="xlg"
                  :value.sync="searchParam.routeNoSearchText"
                  inputType="text"
                  placeholder="ルート番号を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                  <span class="text-label">ルート番号</span>
                </InputText>
              </div>
            </div>
            <div class="c-inputWrap">
              <InputText
                name="wasteNameList"
                class="xlg"
                :value.sync="searchParam.wasteNameSearchText"
                inputType="text"
                placeholder="廃棄物名称を入力"
                :validation="validateMaxLen"
                :validateArg="100"
              >
                <span class="text-label">廃棄物名称</span>
              </InputText>
            </div>
            <!-- 詳細のときだけ表示 -->
            <template v-if="isSearchDetail">
              <div class="c-inputWrap">
                <InputText
                  class="xlg"
                  :value.sync="searchParam.entrustId"
                  inputType="text"
                  placeholder="委託契約番号を入力"
                  :validation="validateMaxLen"
                  :validateArg="100"
                >
                  <span class="text-label">委託契約番号</span>
                </InputText>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <div
                      v-for="(list, index) in searchParam.generateCompanyNameList"
                      :key="`t-slect-${index}`"
                      class="c-input"
                  >
                <span class="text-label">
                  <p v-if="index === 0">排出事業者</p>
                </span>
                    <div class="c-inputWrap__items__cols center search-wrap">
                      <v-autocomplete ref="generate_company_autocomplete" @change="s => {onChange(s, 'generate_company_autocomplete')}" placeholder="" class="c-input" :items="selectGenerateCompanyList" :min-len="0" :wait="300" @update-items='(text) => updateGenerateCompanyList(text)' v-model="selectedGenerateCompany[index]" :component-item='selectTemplate' :auto-select-one-item="autoSelect" :get-label='getLabel'>
                      </v-autocomplete>
                      <span
                          v-if="searchParam.generateCompanyNameList.length > 1 && index > 0"
                          class="c-btn-icon delete secondary small l-item-l"
                          @click="deleteGenerateCompanySelection(index)"/>
                    </div>
                  </div>
                  <div
                      v-if="searchParam.generateCompanyNameList.length < 5"
                      class="c-btn secondary sm add l-item-l"
                      @click="addGenerateCompanySelection"
                  >
                    排出事業者を追加
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <div
                      v-for="(list, index) in searchParam.generateStoreNameList"
                      :key="`t-slect-${index}`"
                      class="c-input"
                  >
                <span class="text-label">
                  <p v-if="index === 0">排出事業場</p>
                </span>
                    <div class="c-inputWrap__items__cols center search-wrap">
                      <v-autocomplete ref="generate_store_autocomplete" @change="s => {onChange(s, 'generate_store_autocomplete')}" placeholder="" class="c-input" :items="selectGenerateStoreList" :min-len="0" :wait="300" @update-items='(text) => updateGenerateStoreList(text)' v-model="selectedGenerateStore[index]" :component-item='selectTemplate' :auto-select-one-item="autoSelect" :get-label='getLabel'>
                      </v-autocomplete>
                      <span
                          v-if="searchParam.generateStoreNameList.length > 1 && index > 0"
                          class="c-btn-icon delete secondary small l-item-l"
                          @click="deleteGenerateStoreSelection(index)"/>
                    </div>
                  </div>
                  <div
                      v-if="searchParam.generateStoreNameList.length < 5"
                      class="c-btn secondary sm add l-item-l"
                      @click="addGenerateStoreSelection"
                  >
                    排出事業場を追加
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols">
                  <div v-if="!isCollector">
                    <span class="text-label">運搬事業者</span>
                    <v-autocomplete ref="collect_autocomplete" @change="s => {onChange(s, 'collect_autocomplete')}" placeholder="運搬事業者名称を入力" class="c-input" :items="this.selectGeneratorCollectList" :min-len="0" :wait="300" @update-items='updateGeneratorCollectList' v-model="selectedGeneratorCollect" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                    </v-autocomplete>
                  </div>
                  <div>
                    <div>
                      <span class="text-label">運搬許可証期限日</span>
                      <div class="c-inputWrap__items__cols">
                        <InputDate
                          :value.sync="searchParam.collectLicenceFrom"
                          placeholder="0000/00/00"
                        />
                        <span>〜</span>
                        <InputDate
                          :value.sync="searchParam.collectLicenceTo"
                          placeholder="0000/00/00"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols">
                  <div v-if="!isDisposer">
                    <span class="text-label">処分事業者</span>
                    <v-autocomplete ref="disposal_autocomplete" @change="s => {onChange(s, 'disposal_autocomplete')}" placeholder="処分事業者名称を入力" class="c-input" :items="this.selectGeneratorDisposalList" :min-len="0" :wait="300" @update-items='updateGeneratorDisposalList' v-model="selectedGeneratorDisposal" :component-item='selectTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabel'>
                    </v-autocomplete>
                  </div>
                  <div>
                    <span class="text-label">処分場</span>
                    <v-autocomplete ref="disposal_site_autocomplete" @change="s => {onChange(s, 'disposal_site_autocomplete')}" placeholder="処分場名称を入力" class="c-input" :items="this.selectDisposalSiteList" :min-len="0" :wait="300" @update-items='updateDisposalSiteList' v-model="selectedDisposalSite" :component-item='selectItemShowNameAndJwnetIdTemplate' :auto-select-one-item="this.autoSelect" :get-label='getLabelDisposalSite'>
                    </v-autocomplete>
                  </div>
                  <div>
                    <div>
                      <span class="text-label">処分許可証期限日</span>
                      <div class="c-inputWrap__items__cols">
                        <InputDate
                          :value.sync="searchParam.disposalLicenceFrom"
                          placeholder="0000/00/00"
                        />
                        <span>〜</span>
                        <InputDate
                          :value.sync="searchParam.disposalLicenceTo"
                          placeholder="0000/00/00"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <!--事業場任意設定区分条件-->
                  <div>
                    <span class="text-label">事業場任意設定</span>
                    <div class="l-flex cols">
                      <div
                        class="c-input"
                        v-for="(
                          changeStoreType, changeStoreTypeIndex
                        ) in changeStoreTypeOpt"
                        :key="'changeStoreType-' + changeStoreTypeIndex"
                      >
                        <div class="c-checkbox">
                          <input
                            :id="'changeStoreType-' + changeStoreTypeIndex"
                            type="checkbox"
                            v-model="searchParam.changeStoreTypeIdList"
                            :value="changeStoreType.id"
                          />
                          <label
                            class="c-checkbox__label"
                            :for="'changeStoreType-' + changeStoreTypeIndex"
                          >
                            <span class="c-checkbox__box"></span>
                            {{ changeStoreType.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--廃棄物区分区分条件-->
                  <div>
                    <span class="text-label">廃棄物区分</span>
                    <div class="l-flex cols">
                      <div
                        class="c-input"
                        v-for="(wasteTypeId, wtIndex) in wasteTypeIdOpt"
                        :key="'wasteTypeId-' + wtIndex"
                      >
                        <div class="c-checkbox">
                          <input
                            :id="'wasteTypeId-' + wtIndex"
                            type="checkbox"
                            v-model="searchParam.wasteTypeIdList"
                            :value="wasteTypeId.id"
                          />
                          <label
                            class="c-checkbox__label"
                            :for="'wasteTypeId-' + wtIndex"
                          >
                            <span class="c-checkbox__box"></span>
                            {{ wasteTypeId.name }}
                          </label>

                          <!--産業廃棄物区分の場合-->
                          <template
                            v-if="
                              wasteTypeId.id === wasteTypeIdOpt[0].id &&
                              searchParam.wasteTypeIdList.includes(
                                wasteTypeId.id
                              )
                            "
                          >
                            <div class="l-flex wrap full">
                              <span>(</span>
                              <div
                                class="c-input"
                                v-for="(
                                  industrialWasteType, industrialWasteTypeIndex
                                ) in industrialWasteJwnetTypeOpt"
                                :key="
                                  'industrialWasteType-' +
                                  industrialWasteTypeIndex
                                "
                              >
                                <input
                                  :id="
                                    'industrialWasteType-' +
                                    industrialWasteTypeIndex
                                  "
                                  type="checkbox"
                                  v-model="
                                    searchParam.industrialWasteJwnetTypeIdList
                                  "
                                  :value="industrialWasteType.id"
                                />
                                <label
                                  class="c-checkbox__label"
                                  :for="
                                    'industrialWasteType-' +
                                    industrialWasteTypeIndex
                                  "
                                >
                                  <span class="c-checkbox__box"></span>
                                  {{ industrialWasteType.name }}
                                </label>
                              </div>
                              <span>)</span>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--マニフェスト区分条件-->
                  <div>
                    <span class="text-label">マニフェスト区分</span>
                    <div class="l-flex cols">
                      <div
                        class="c-input"
                        v-for="(
                          manifestType, manifestTypeIndex
                        ) in manifestTypeOpt"
                        :key="'manifestType-' + manifestTypeIndex"
                      >
                        <div class="c-checkbox">
                          <input
                            :id="'manifestType-' + manifestTypeIndex"
                            type="checkbox"
                            v-model="searchParam.manifestTypeIdList"
                            :value="manifestType.id"
                          />
                          <label
                            class="c-checkbox__label"
                            :for="'manifestType-' + manifestTypeIndex"
                          >
                            <span class="c-checkbox__box"></span>
                            {{ manifestType.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <!--お気に入り-->
                  <div>
                    <span class="text-label">お気に入り</span>
                    <div class="l-flex wrap full">
                      <div class="c-input">
                        <div class="c-checkbox">
                          <input
                            id="is-favorite"
                            type="checkbox"
                            v-model="searchParam.isOnlyFavorite"
                          />
                          <label class="c-checkbox__label" for="is-favorite">
                            <span class="c-checkbox__box"></span>
                            有
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--表示オプション-->
                  <div>
                    <span class="text-label">表示オプション</span>
                    <div class="l-flex wrap full">
                      <div class="c-input">
                        <div class="c-checkbox">
                          <input
                            id="is-including-invalid"
                            type="checkbox"
                            v-model="searchParam.isIncludingInvalid"
                            :value="true"
                          />
                          <label
                            class="c-checkbox__label"
                            for="is-including-invalid"
                          >
                            <span class="c-checkbox__box"></span>
                            無効ルートも表示
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="l-container">
          <div class="l-btns l-block-lg">
            <div class="c-btn secondary small" @click="resetSearchHandler">
              条件をリセット
            </div>
            <div class="c-btn primary small" @click="searchHandler">検索</div>
          </div>
        </div>
      </div>

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="routeDownloadErrMsg" />
        <div v-if="searchRouteList.length" class="l-flex cols l-item">
          <div class="u-txt-bold">
            選択中
            {{ selectedActiveRoutes.length + selectedInactiveRoutes.length }} 件
          </div>
          <div class="l-flex cols">
            <div
              class="c-btn secondary small"
              @click="isDisplayActivateModal = true"
              :class="{
                disabled: !selectedInactiveRoutes.length || activateRouteButton,
              }"
            >
              有効化
            </div>
            <div
              class="c-btn secondary small"
              @click="isDisplayDeactivateModal = true"
              :class="{
                disabled: !selectedActiveRoutes.length || deactivateRouteButton,
              }"
            >
              無効化
            </div>
          </div>
          <template v-if="!isDisposer">
            <div 
              class="c-btn secondary small upload"
              style="margin-left: auto;!important;width: 16rem;"
              @click="onClickCsvUploadModalDisplayButton"
            >
              CSVアップロード
            </div>
            <div
                class="c-btn secondary small download"
                :class="{ disabled: isDownloading }"
                style="width: 16rem;"
                @click="clickCSVSearchHandler"
            >
              CSVダウンロード
            </div>
          </template>
        </div>

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell xsm">
                <div class="c-input">
                  <div class="c-checkbox">
                    <input
                      id="checkAllRoute"
                      type="checkbox"
                      v-model="checkAll"
                      @change="checkAllRoute()"
                    />
                    <label class="c-checkbox__label" for="checkAllRoute">
                      <span class="c-checkbox__box"></span>
                    </label>
                  </div>
                </div>
              </div>
              <div
                class="c-infoList__cell xsm"
                v-if="isGenerator || isCba || isCollectProxy"
              >
                お気に入り
              </div>
              <div class="c-infoList__cell xs">委託契約番号</div>
              <div class="c-infoList__cell md">ルート名称</div>
              <div class="c-infoList__cell md-s">排出事業場</div>
              <div class="c-infoList__cell md-s">構成種類</div>
              <div class="c-infoList__cell md-s">廃棄物区分</div>
              <div class="c-infoList__cell md-s">廃棄物名称</div>
              <div class="c-infoList__cell md">運搬事業者</div>
              <div class="c-infoList__cell sm">処分場</div>
              <div class="c-infoList__cell xsm">ステータス</div>
              <div class="c-infoList__cell sm">有効期間</div>
              <div class="c-infoList__cell xxsm"></div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="searchRouteList.length">
              <div
                v-for="(route, routeIndex) of searchRouteList"
                :key="'route-' + routeIndex"
                class="c-infoList__row list"
                :class="{ notActive: !route.isActive }"
              >
                <div class="c-infoList__cell xsm">
                  <div class="c-input">
                    <div v-if="route.isActive" class="c-checkbox">
                      <input
                        :id="route.routeId"
                        type="checkbox"
                        v-model="selectedActiveRoutes"
                        :value="route.routeId"
                        @input="checkAll = false"
                        @change="checkRoute()"
                      />
                      <label class="c-checkbox__label" :for="route.routeId">
                        <span class="c-checkbox__box"></span>
                      </label>
                    </div>
                    <div v-else class="c-checkbox">
                      <input
                        :id="route.routeId"
                        type="checkbox"
                        v-model="selectedInactiveRoutes"
                        :value="route.routeId"
                        @input="checkAll = false"
                        @change="checkRoute()"
                      />
                      <label class="c-checkbox__label" :for="route.routeId">
                        <span class="c-checkbox__box"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  class="c-infoList__cell xsm"
                  v-if="isGenerator || isCba || isCollectProxy"
                >
                  <span v-if="route.isFavorite">★</span>
                  <span v-else>☆</span>
                </div>
                <div class="c-infoList__cell xs">
                  {{ route.entrustId }}
                </div>
                <div class="c-infoList__cell md c-tooltip">
                  <router-link @click.native="setRoutePage()" :to="`/route/${route.routeId}`">
                    {{ route.routeName | shortenText }}
                  </router-link>
                  <div v-if="!route.isActive" class="c-badge-black">無効</div>
                  <!-- 運搬許可証、処分許可証の期限が30日後以内 -->
                  <div
                    v-else-if="
                      (route.collectLicenceExpiredDate != null &&
                        today > route.collectLicenceExpiredDate &&
                        !route.collectLicenceIsUpdate) ||
                      (route.disposalLicenceExpiredDate != null &&
                        today > route.disposalLicenceExpiredDate &&
                        !route.disposalLicenceIsUpdate)
                    "
                    class="c-status info-red"
                  >
                    <div class="c-infoList__popup">
                      <span
                        v-if="
                          route.collectLicenceExpiredDate != null &&
                          today > route.collectLicenceExpiredDate
                        "
                      >
                        運搬業者の許可証期限が超過しています。<br />
                      </span>
                      <span
                        v-if="
                          route.disposalLicenceExpiredDate != null &&
                          today > route.disposalLicenceExpiredDate
                        "
                      >
                        処分業者の許可証期限が超過しています。
                      </span>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      (route.collectLicenceExpiredDate != null &&
                        today <= route.collectLicenceExpiredDate &&
                        route.collectLicenceExpiredDate <= nearDeadline &&
                        !route.collectLicenceIsUpdate) ||
                      (route.disposalLicenceExpiredDate != null &&
                        today <= route.disposalLicenceExpiredDate &&
                        route.disposalLicenceExpiredDate <= nearDeadline &&
                        !route.disposalLicenceIsUpdate)
                    "
                    class="c-status info-yellow"
                  >
                    <div class="c-infoList__popup">
                      <span
                        v-if="
                          route.collectLicenceExpiredDate != null &&
                          today <= route.collectLicenceExpiredDate &&
                          route.collectLicenceExpiredDate <= nearDeadline
                        "
                      >
                        運搬業者の許可証期限が迫っています。<br />
                      </span>
                      <span
                        v-if="
                          route.disposalLicenceExpiredDate != null &&
                          today <= route.disposalLicenceExpiredDate &&
                          route.disposalLicenceExpiredDate <= nearDeadline
                        "
                      >
                        処分業者の許可証期限が迫っています。
                      </span>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      (route.commonWasteCollectLicenceExpiredDate != null &&
                        today > route.commonWasteCollectLicenceExpiredDate &&
                        !route.commonWasteCollectLicenceIsUpdate) ||
                      (route.commonWasteDisposalLicenceExpiredDate != null &&
                        today > route.commonWasteDisposalLicenceExpiredDate &&
                        !route.commonWasteDisposalLicenceIsUpdate)
                    "
                    class="c-status info-red"
                  >
                    <div class="c-infoList__popup">
                      <span
                        v-if="
                          route.commonWasteCollectLicenceExpiredDate != null &&
                          today > route.commonWasteCollectLicenceExpiredDate
                        "
                      >
                        運搬業者の許可証期限が超過しています。<br />
                      </span>
                      <span
                        v-if="
                          route.commonWasteDisposalLicenceExpiredDate != null &&
                          today > route.commonWasteDisposalLicenceExpiredDate
                        "
                      >
                        処分業者の許可証期限が超過しています。
                      </span>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      (route.commonWasteCollectLicenceExpiredDate != null &&
                        today <= route.commonWasteCollectLicenceExpiredDate &&
                        route.commonWasteCollectLicenceExpiredDate <=
                          nearDeadline &&
                        !route.commonWasteCollectLicenceIsUpdate) ||
                      (route.commonWasteDisposalLicenceExpiredDate != null &&
                        today <= route.commonWasteDisposalLicenceExpiredDate &&
                        route.commonWasteDisposalLicenceExpiredDate <=
                          nearDeadline &&
                        !route.commonWasteDisposalLicenceIsUpdate)
                    "
                    class="c-status info-yellow"
                  >
                    <div class="c-infoList__popup">
                      <span
                        v-if="
                          route.commonWasteCollectLicenceExpiredDate != null &&
                          today <= route.commonWasteCollectLicenceExpiredDate &&
                          route.commonWasteCollectLicenceExpiredDate <=
                            nearDeadline
                        "
                      >
                        運搬業者の許可証期限が迫っています。<br />
                      </span>
                      <span
                        v-if="
                          route.commonWasteDisposalLicenceExpiredDate != null &&
                          today <=
                            route.commonWasteDisposalLicenceExpiredDate &&
                          route.commonWasteDisposalLicenceExpiredDate <=
                            nearDeadline
                        "
                      >
                        処分業者の許可証期限が迫っています。
                      </span>
                    </div>
                  </div>
                </div>
                <div class="c-infoList__cell md-s">
                  {{ route.generateStoreInfo.name }}
                </div>
                <div class="c-infoList__cell md-s c-tooltip">
                  <CommaText
                    :items="route.constitutionInfo"
                    arg="name"
                    :maxLength="11"
                  />
                </div>
                <div class="c-infoList__cell md-s">
                  {{ route.wasteType.name }}
                </div>
                <div class="c-infoList__cell md-s">
                  {{ route.wasteNameInfo.name }}
                </div>
                <div class="c-infoList__cell md c-tooltip">
                  <CommaText
                    :items="route.collectCompanyList.map( cc => cc.isActive ? cc : { 'id': cc.is, 'name': '', 'isActive': cc.isActive} )"
                    arg="name"
                    :showblankText="true"
                  />
                </div>
                <div class="c-infoList__cell sm">
                  {{ route.disposalSite != null && route.disposalSite.isActive ? route.disposalSite.name : "" }}
                </div>
                <div class="c-infoList__cell xsm">
                  <span v-if="route.isActive">有効</span>
                  <span v-else>無効</span>
                </div>
                <div class="c-infoList__cell sm">
                  <span>{{ route.activeStartDate | dateFormat }}</span>
                  <span>~</span>
                  <span v-if="route.activeEndDate">{{
                    route.activeEndDate | dateFormat
                  }}</span>
                </div>
                <div class="c-infoList__cell xxsm"></div>
              </div>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致するルートがありません。
                </div>
              </div>
            </template>
          </div>

          <div v-if="searchRouteList.length" class="c-infoList__floated">
            <div
              v-for="(route, routeIndex) of searchRouteList"
              :key="'route-edit-' + routeIndex"
              class="c-infoList__cell l-flex"
            >
              <template v-if="!isCollector && !isDisposer">
                <a @click="editRoute(route.routeId)">
                  <span class="c-infoList__cell__icon pen"></span>
                </a>
              </template>
              <template v-if="isLowClass || isCollectProxy">
                <div
                  v-if="route.isFavorite"
                  @click="toggleFavorite(route)"
                  class="c-btn fav on"
                />
                <div
                  v-else
                  @click="toggleFavorite(route)"
                  class="c-btn fav off"
                />
              </template>
            </div>
          </div>
        </div>
        <!-- /__wrap -->

        <div v-if="searchRouteList.length" class="l-flex cols l-item">
          <div class="u-txt-bold">
            選択中
            {{ selectedActiveRoutes.length + selectedInactiveRoutes.length }} 件
          </div>
          <div class="l-flex cols">
            <div
              class="c-btn secondary small"
              @click="isDisplayActivateModal = true"
              :class="{
                disabled: !selectedInactiveRoutes.length || activateRouteButton,
              }"
            >
              有効化
            </div>
            <div
              class="c-btn secondary small"
              @click="isDisplayDeactivateModal = true"
              :class="{
                disabled: !selectedActiveRoutes.length || deactivateRouteButton,
              }"
            >
              無効化
            </div>
          </div>
        </div>

        <Pagination
          v-if="searchRouteList.length"
          :params="paginationParams"
          :page.sync="paginationParams.page"
          @callback="getRouteSearchApi(searchParam, true)"
        />
      </div>
    </main>
    <!-- 有効化確認モーダル -->
    <div class="c-modal" v-show="isDisplayActivateModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          <ErrorMessage :errMsgs="errMsgs" />
          ルートを有効化します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayActivateModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="activateRoute">はい</div>
        </div>
      </div>
    </div>
    <!-- 無効化確認モーダル -->
    <div class="c-modal" v-show="isDisplayDeactivateModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          <ErrorMessage :errMsgs="errMsgs" />
          ルートを無効化します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div
            class="c-btn secondary small"
            @click="isDisplayDeactivateModal = false"
          >
            戻る
          </div>
          <div class="c-btn primary small" @click="deactivateRoute">はい</div>
        </div>
      </div>
    </div>
    <RouteCsvUploadModal v-if="isDisplayCsvUploadModal"
                            v-bind:onClickCloseButton="hideCsvUploadModal"
                            v-bind:onClickUploadButton="onClickUploadCsv"
    />
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Pagination from "@/components/parts/Pagination.vue";
import CommaText from "@/components/parts/CommaText";
import callApi from "./mixins/callApi";
import common from "@/mixin/common";
import validation from "@/mixin/validation";
import { API_URL } from "@/const/index";
import { DOMAIN } from "@/const";
import ToastMessage from "@/components/parts/ToastMessage";
import SelectItemTemplate from "@/components/parts/input/SelectItemTemplate";
import selectionLocalStorage from "@/mixin/selectionLocalStorage";
import csvFile from "@/pages/route/mixins/csvFile";
import RouteCsvUploadModal from "./components/RouteCsvUploadModal";
import SelectItemShowNameAndJwnetIdTemplate from "@/components/parts/input/SelectItemShowNameAndJwnetIdTemplate";

export default {
  name: "route-list",
  components: {
    ToastMessage,
    InputText,
    InputDate,
    Pagination,
    CommaText,
    RouteCsvUploadModal,
  },
  mixins: [validation, common, callApi, selectionLocalStorage, csvFile],
  data() {
    return {
      selectedGeneratorCollect : '', //選択した運搬事業者
      selectedGeneratorDisposal: '', //選択した処分事業者
      selectedDisposalSite: '', //選択した処分事業場
      selectedGenerateCompany: [], // 選択した排出事業者
      selectedGenerateStore: [], // 選択した排出事業場
      isLoadingSearchGenerateStore: true, // 排出事業場を取得APIが完了するかどうか判断
      isLoadingSearchGenerateCompany: true, // 排出事業者を取得APIが完了するかどうか判断
      autoSelect: false,
      selectTemplate: SelectItemTemplate,
      selectItemShowNameAndJwnetIdTemplate: SelectItemShowNameAndJwnetIdTemplate,
      isSearchDetail: false,
      isDisplaySearch: false,
      sindex: 0,
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 10,
        total: null,
      },
      sortParams: {
        sortColumn: "route_id",
        sortType: "asc",
      },
      wasteTypeIdOpt: [
        { id: DOMAIN.WASTE_TYPE.INDUSTRIAL_WASTE, name: "産業廃棄物" },
        { id: DOMAIN.WASTE_TYPE.VALUABLES, name: "有価物" },
        { id: DOMAIN.WASTE_TYPE.COMMON_WASTE, name: "一般廃棄物" },
      ],
      industrialWasteJwnetTypeOpt: [
        {
          id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE
            .IS_NOT_SPECIAL_INDUSTRIAL_WASTE,
          name: "普通産業廃棄物",
        },
        {
          id: DOMAIN.INDUSTRIAL_WASTE_JWNET_TYPE.IS_SPECIAL_INDUSTRIAL_WASTE,
          name: "特別管理産業廃棄物",
        },
      ],
      manifestTypeOpt: [
        { id: DOMAIN.MANIFEST_TYPE.DIGITAL, name: "電子" },
        { id: DOMAIN.MANIFEST_TYPE.ANALOG, name: "紙マニフェスト" },
      ],
      changeStoreTypeOpt: [
        { id: 0, name: "無" },
        { id: 1, name: "有" },
      ],
      labelSettingTypeOpt: [
        { id: 0, name: "無" },
        { id: 1, name: "有" },
      ],
      // 検索v-model
      searchParam: {
        routeNameList: [], // ルート名称
        routeIdList: [], // ルート番号
        wasteNameList: [], // 廃棄物名称
        entrustId: "", //委託契約書番号
        generateCompanyNameList: [null], // 排出事業者
        generateCompanyIndex: 1,
        generateStoreNameList: [null], // 排出事業場
        generateStoreIndex: 1,
        collectCompanyId: "", // 運搬事業者Id
        collectLicenceFrom: "", // 運搬許可証期限日From
        collectLicenceTo: "", //運搬許可証期限日To
        disposalCompanyId: "", //処分事業者Id
        disposalSite: "", //処分事業場名称
        disposalLicenceFrom: "", // 処分許可証期限日From
        disposalLicenceTo: "", // 処分許可証期限日To
        wasteTypeIdList: [], //廃棄物区分
        manifestTypeIdList: [], // マニフェスト区分
        industrialWasteJwnetTypeIdList: [],
        changeStoreTypeIdList: [], // 事業場任意設定区分
        labelSettingTypeIdList: [], // ラベル設定有区分
        isOnlyFavorite: false, //お気に入り
        isIncludingInvalid: true, // 無効ルートも表示
      },
      checkAll: false, // 全てチェック
      today: new Date().toJSON().slice(0, 10), // statusの表示時にしようする現在時刻
      nearDeadline: new Date(), // statusの表示時にしようする現在時刻から7日後
      selectedActiveRoutes: [], // チェックボックスで選択したルートIDのうち、有効のもの
      selectedInactiveRoutes: [], // チェックボックスで選択したルートIDのうち、無効のもの
      isDisplayActivateModal: false,
      isDisplayDeactivateModal: false,
      errMsgs: [],
      routeDownloadErrMsg: [],
      activateRouteButton: false,
      deactivateRouteButton: false,
      toastMsg: "",
      isDisplayCsvUploadModal: false,
      isDownloading: false,
    };
  },
  computed: {
    isHighClass() {
      return this.$store.getters["userInfo/isHighClass"];
    },
    isLowClass() {
      return this.$store.getters["userInfo/isLowClass"];
    },
    isCollector() {
      return this.$store.getters["userInfo/isCollector"];
    },
    isCollectProxy() {
      return this.$store.getters["userInfo/isCollectProxy"];
    },
    isCba() {
      return this.$store.getters["userInfo/isCba"];
    },
    isGenerator() {
      return this.$store.getters["userInfo/isGenerator"];
    },
    isDisposer() {
      return this.$store.getters["userInfo/isDisposer"];
    },
  },
  mounted() {
    if (this.$route.query.page != null) {
      this.paginationParams.page = Number(this.$route.query.page)
    }
    this.searchParam = this.$store.getters["searchParamRoute/get"];
    this.isSearchDetail =
      this.$store.getters["searchParamRoute/isSearchDetail"];
    this.generateCompanyIndex = this.searchParam.generateCompanyIndex;
    this.generateStoreIndex = this.searchParam.generateStoreIndex;
    window.addEventListener("scroll", this.handleScroll);
    this.nearDeadline.setDate(new Date().getDate() + 30);
    this.nearDeadline = this.nearDeadline.toJSON().slice(0, 10);
    this.getRouteSearchApi(this.$store.getters["searchParamRoute/get"], true);
    this.getGeneratorCollectApi();
    if (this.isCba || this.isGenerator) {
      this.getGeneratorDisposalApi();
    } else if (this.isCollector) {
      this.getGeneratorDisposalApiForCollect()
    }
    this.getDisposalSiteApi();
    this.getGeneratorCompanyApi();
    this.getStoreListApi();
  },

  methods: {
    onChange(queryString, ref) {
      this.$nextTick(() => {
          this.$refs[ref].searchText = queryString;
      });
    },
    initSearch(isAll = true) {
      this.selectedGeneratorCollect = isAll ? null : this.selectedGeneratorCollect
      this.selectedGeneratorDisposal = isAll ? null : this.selectedGeneratorDisposal
      this.selectedDisposalSite = isAll ? null : this.selectedDisposalSite
      this.selectedGenerateCompany = isAll ? [null] : this.selectedGenerateCompany
      this.selectedGenerateStore = isAll ? [null] : this.selectedGenerateStore
      this.searchParam = {
        routeNameList: this.searchParam.routeNameList,
        routeNameSearchText: this.searchParam.routeNameSearchText,
        routeIdList: this.searchParam.routeIdList,
        routeNoSearchText: this.searchParam.routeNoSearchText,
        wasteNameList: this.searchParam.wasteNameList,
        wasteNameSearchText: this.searchParam.wasteNameSearchText,
        entrustId: isAll ? "" : this.searchParam.entrustId,
        generateCompanyNameList: [null],
        generateStoreNameList: [null],
        collectCompanyId: isAll ? null : this.searchParam.collectCompanyId,
        collectLicenceFrom: isAll ? "" : this.searchParam.collectLicenceFrom,
        collectLicenceTo: isAll ? "" : this.searchParam.collectLicenceTo,
        disposalCompanyId: isAll ? null : this.searchParam.disposalCompanyId,
        disposalSite: isAll ? null : this.searchParam.disposalSite,
        disposalLicenceFrom: isAll ? "" : this.searchParam.disposalLicenceFrom,
        disposalLicenceTo: isAll ? "" : this.searchParam.disposalLicenceTo,
        wasteTypeIdList: isAll ? [] : this.searchParam.wasteTypeIdList,
        manifestTypeIdList: isAll ? [] : this.searchParam.manifestTypeIdList,
        industrialWasteJwnetTypeIdList: isAll
          ? []
          : this.searchParam.industrialWasteJwnetTypeIdList,
        changeStoreTypeIdList: isAll
          ? []
          : this.searchParam.changeStoreTypeIdList,
        labelSettingTypeIdList: isAll
          ? []
          : this.searchParam.labelSettingTypeIdList,
        isOnlyFavorite: isAll ? false : this.searchParam.isOnlyFavorite,
        isIncludingInvalid: isAll ? true : this.searchParam.isIncludingInvalid,
        generateCompanyIndex: isAll ? 1 : this.generateCompanyIndex,
        generateStoreIndex: isAll ? 1 : this.generateStoreIndex,
      };
    },
    resetSearchHandler() {
      this.selectedGeneratorCollect = ""
      this.selectedGeneratorDisposal = ""
      this.selectedDisposalSite = ""
      this.selectedGenerateCompany = [null],
      this.selectedGenerateStore= [null],
      // 検索条件を全て初期化
      this.searchParam = {
        routeNameList: [],
        routeNameSearchText: "",
        routeIdList: [],
        routeNoSearchText: "",
        wasteNameList: [],
        wasteNameSearchText: "",
        entrustId: "",
        generateCompanyNameList: [null],
        generateStoreNameList: [null],
        collectCompanyId: "",
        collectLicenceFrom: "",
        collectLicenceTo: "",
        disposalCompanyId: "",
        disposalSite: "",
        disposalLicenceFrom: "",
        disposalLicenceTo: "",
        wasteTypeIdList: [],
        industrialWasteJwnetTypeIdList: [],
        manifestTypeIdList: [],
        changeStoreTypeIdList: [],
        labelSettingTypeIdList: [],
        isOnlyFavorite: false,
        isIncludingInvalid: true,
        generateCompanyIndex: 1,
        generateStoreIndex: 1,
      };
    },
    searchHandler() {
      this.getRouteSearchApi(this.searchParam, false);
    },
    editRoute(routeId) {
      this.setRoutePage()
      this.$router.push(`/route/edit/${routeId}`);
    },

    // 排出事業場の項目追加
    addGenerateCompanySelection() {
      //追加した項目の選択肢の初期化
      this.selectGenerateCompanyList = this.generateCompanyList
      this.selectedGenerateCompany.push(null);
      this.searchParam.generateCompanyNameList.push(null);
    },
    // 排出事業場の項目削除
    deleteGenerateCompanySelection(index) {
      this.selectedGenerateCompany.splice(index,1);
      this.searchParam.generateCompanyNameList.splice(index, 1);
    },

    // 排出事業場の項目追加
    addGenerateStoreSelection() {
      //追加した項目の選択肢の初期化
      this.selectGenerateStoreList = this.generateStoreList
      this.selectedGenerateStore.push(null);
      this.searchParam.generateStoreNameList.push(null);
    },
    // 排出事業場の項目削除
    deleteGenerateStoreSelection(index) {
      this.selectedGenerateStore.splice(index,1);
      this.searchParam.generateStoreNameList.splice(index, 1);
    },

    // チェックボックスALL押下時
    checkAllRoute() {
      this.selectedActiveRoutes = [];
      this.selectedInactiveRoutes = [];
      if (this.checkAll) {
        for (const route of this.searchRouteList) {
          if (route.isActive) {
            this.selectedActiveRoutes.push(route.routeId);
          } else {
            this.selectedInactiveRoutes.push(route.routeId);
          }
        }
        if (
          this.selectedActiveRoutes.length !== 0 &&
          this.selectedInactiveRoutes.length !== 0
        ) {
          this.activateRouteButton = true;
          this.deactivateRouteButton = true;
        } else {
          this.activateRouteButton = false;
          this.deactivateRouteButton = false;
        }
      } else {
        this.activateRouteButton = false;
        this.deactivateRouteButton = false;
      }
    },
    checkRoute() {
      if (
        this.selectedActiveRoutes.length !== 0 &&
        this.selectedInactiveRoutes.length !== 0
      ) {
        this.activateRouteButton = true;
        this.deactivateRouteButton = true;
      } else {
        this.activateRouteButton = false;
        this.deactivateRouteButton = false;
      }
    },
    toggleFavorite(route) {
      route.isFavorite = !route.isFavorite;
      axios.put(API_URL.ROUTE.FAVORITE + route.routeId, {
        isFavorite: route.isFavorite,
      });
    },
    activateRoute() {
      this.errMsgs = [];
      const data = {
        isActive: true,
        targetIdList: this.selectedInactiveRoutes.map((routeId) => {
          return { id: routeId };
        }),
      };
      axios
        .patch(API_URL.ROUTE.ACTIVATE, data)
        .then(() => {
          this.getRouteSearchApi(
            this.$store.getters["searchParamRoute/get"],
            true
          );
          this.selectedInactiveRoutes = [];
          this.checkAll = false;
          this.isDisplayActivateModal = false;
        })
        .catch((err) => {
          this.errMsgs.push("ルートを有効化できませんでした");
          console.log(err);
        });
    },
    deactivateRoute() {
      this.errMsgs = [];
      const data = {
        isActive: false,
        targetIdList: this.selectedActiveRoutes.map((routeId) => {
          return { id: routeId };
        }),
      };
      axios
        .patch(API_URL.ROUTE.ACTIVATE, data)
        .then(() => {
          this.getRouteSearchApi(
            this.$store.getters["searchParamRoute/get"],
            true
          );
          this.selectedActiveRoutes = [];
          this.checkAll = false;
          this.isDisplayDeactivateModal = false;
        })
        .catch((err) => {
          this.errMsgs.push("ルートを無効化できませんでした");
          console.log(err);
        });
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    shorten() {
      const result = [];
      const searchParam = this.searchParam;
      if (this.searchParam.routeNameSearchText)
        result.push({
          key: "ルート名称",
          value: this.searchParam.routeNameSearchText,
        });
      if (this.searchParam.routeNoSearchText)
        result.push({
          key: "ルート番号",
          value: this.searchParam.routeNoSearchText,
        });
      if (this.searchParam.wasteNameSearchText)
        result.push({
          key: "廃棄物名称",
          value: this.searchParam.wasteNameSearchText,
        });
      if (this.isSearchDetail && searchParam.generateCompanyNameList.length)
        result.push({
          key: "排出事業者",
          value: searchParam.generateCompanyNameList.join("、"),
        });
      if (this.isSearchDetail && searchParam.generateStoreNameList.length)
        result.push({
          key: "排出事業場",
          value: searchParam.generateStoreNameList.join("、"),
        });
      if (this.isSearchDetail && searchParam.entrustId)
        result.push({ key: "委託契約書番号", value: searchParam.entrustId });
      if (this.isSearchDetail && searchParam.collectCompanyId)
        result.push({ key: "運搬事業者", value: this.generatorCollectList.filter(e => e.id === searchParam.collectCompanyId)[0].name });
      if (
        (this.isSearchDetail && searchParam.collectLicenceFrom) ||
        searchParam.collectLicenceTo
      )
        result.push({
          key: "運搬許可証期限日",
          value:
            searchParam.collectLicenceFrom +
            "〜" +
            searchParam.collectLicenceTo,
        });
      if (this.isSearchDetail && searchParam.disposalCompanyId)
        result.push({ key: "処分事業者", value: this.generatorDisposalList.filter(e => e.id === searchParam.disposalCompanyId)[0].name });
      if (this.isSearchDetail && searchParam.disposalSite)
        result.push({ key: "処分場", value: this.disposalSiteList.filter(c => c.id === searchParam.disposalSite)[0].name });
      if (
        (this.isSearchDetail && searchParam.disposalLicenceFrom) ||
        searchParam.disposalLicenceTo
      )
        result.push({
          key: "処分許可証期限日",
          value:
            searchParam.disposalLicenceFrom +
            "〜" +
            searchParam.disposalLicenceTo,
        });
      if (this.isSearchDetail && searchParam.wasteTypeIdList.length > 0)
        result.push({
          key: "廃棄物区分",
          value: searchParam.wasteTypeIdList
            .map((b) => this.wasteTypeIdOpt.find((opt) => opt.id === b).name)
            .join("、"),
        });
      if (
        this.isSearchDetail &&
        searchParam.wasteTypeIdList.includes(this.wasteTypeIdOpt[0].id) &&
        searchParam.industrialWasteJwnetTypeIdList.length > 0
      )
        result.push({
          key: "産業廃棄物区分",
          value: searchParam.industrialWasteJwnetTypeIdList
            .map(
              (iwjt) =>
                this.industrialWasteJwnetTypeOpt.find((opt) => opt.id == iwjt)
                  .name
            )
            .join("、"),
        });
      if (this.isSearchDetail && searchParam.manifestTypeIdList.length > 0)
        result.push({
          key: "マニフェスト区分",
          value: searchParam.manifestTypeIdList
            .map(
              (manifestTypeId) =>
                this.manifestTypeOpt.find((opt) => opt.id == manifestTypeId)
                  .name
            )
            .join("、"),
        });
      if (this.isSearchDetail && searchParam.changeStoreTypeIdList.length > 0)
        result.push({
          key: "事業場任意設定",
          value: searchParam.changeStoreTypeIdList
            .map(
              (changeStoreTypeId) =>
                this.changeStoreTypeOpt.find(
                  (opt) => opt.id == changeStoreTypeId
                ).name
            )
            .join("、"),
        });
      // 2022.04.04 labelテーブルJOINによる負荷対策として、ルート詳細検索条件のノート設定有無を削除
      // if (this.isSearchDetail && searchParam.labelSettingTypeIdList.length > 0) result.push({ key: "ノート設定", value: searchParam.labelSettingTypeIdList.map(labelSettingTypeId => this.labelSettingTypeOpt.find(opt => opt.id == labelSettingTypeId).name).join("、") });
      if (this.isSearchDetail && searchParam.isOnlyFavorite)
        result.push({ key: "お気に入り", value: "有" });
      if (this.isSearchDetail)
        result.push({
          key: "表示オプション",
          value: searchParam.isIncludingInvalid
            ? "無効ルートも表示"
            : "無効ルート非表示",
        });
      return result;
    },
    updateGenerateCompanyList (text) {
      if (!text || text.length === 0) {
        //全ての排出事業場を表示する
        this.selectGenerateCompanyList = this.generateCompanyList
        return
      }
      this.selectGenerateCompanyList = this.generateCompanyList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGenerateStoreList (text) {
      if (!text || text.length === 0) {
        //全ての排出事業場を表示する
        this.selectGenerateStoreList = this.generateStoreList
        return
      }
      this.selectGenerateStoreList = this.generateStoreList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorCollectList (text) {
      if (!text || text.length === 0) {
        //全ての運搬事業者の選択肢を表示する
        this.selectGeneratorCollectList = this.generatorCollectList
        return
      }
      this.selectGeneratorCollectList = this.generatorCollectList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateGeneratorDisposalList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業者の選択肢を表示する
        this.selectGeneratorDisposalList = this.generatorDisposalList
        return
      }
      this.selectGeneratorDisposalList = this.generatorDisposalList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    updateDisposalSiteList (text) {
      if (!text || text.length === 0) {
        //全ての処分事業場の選択肢を表示する
        this.selectDisposalSiteList = this.disposalSiteList
        return
      }
      this.selectDisposalSiteList = this.disposalSiteList.filter((item) => {
        return (new RegExp(text.toLowerCase())).test(item.name.toLowerCase())
      })
    },
    getLabel (item) {
      if (item) {
        return item.name
      }
      return ''
    },
    getLabelDisposalSite (item) {
      if (item) {
        return item.name + "  JWNETID:"+ item.jwnetId;
      }
      return ''
    },
    setRoutePage() {
      this.$store.commit("searchParamRoute/setRoutePage", this.paginationParams.page);
    },
    addRequestToLocation(requestObj) {
        const param = new URLSearchParams(requestObj)
        history.replaceState(
        {},
        null,
        this.$route.path + '?' + param
      )
    },

    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },
    onClickUploadCsv(csvFile) {
      return this.postRouteCsvupload(csvFile)
          .then(this.onUpload)
          .catch(err => {
            throw(err)
          })
    },
    onUpload() {
      this.hideCsvUploadModal();
      this.toastMsg = "正常にアップロードしました。";
      // 再検索を行う
      this.getRouteSearchApi(this.$store.getters["searchParamRoute/get"], true);
    }
  },
  watch: {
    selectedGeneratorCollect: function(newVal) {
      this.searchParam.collectCompanyId = newVal ? newVal.id : ''
    },
    selectedGeneratorDisposal: function(newVal) {
      this.searchParam.disposalCompanyId = newVal ? newVal.id : ''
    },
    selectedDisposalSite: function(newVal) {
      this.searchParam.disposalSite = newVal ? newVal.id : ''
    },
    selectedGenerateCompany: function(newVal) {
      if (!this.isLoadingSearchGenerateCompany) {
        newVal.forEach((e, i) => {
          this.searchParam.generateCompanyNameList[i] = e ? e.name : null
        })
      }
    },
    selectedGenerateStore: function(newVal) {
      if (!this.isLoadingSearchGenerateStore) {
        newVal.forEach((e, i) => {
          this.searchParam.generateStoreNameList[i] = e ? e.name : null
        })
      }
    },
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.entrustNo) {
      this.$store.commit("searchParamRoute/set", {
        routeNameList: [],
        routeIdList: [],
        wasteNameList: [],
        entrustId: this.$route.query.entrustNo,
        generateCompanyList: [],
        generateCompanyIndex: 1,
        generateStoreList: [],
        generateStoreIndex: 1,
        collectCompanyId: "",
        collectLicenceFrom: "",
        collectLicenceTo: "",
        disposalCompanyId: "",
        disposalSite: "",
        disposalLicenceFrom: "",
        disposalLicenceTo: "",
        wasteTypeIdList: [],
        manifestTypeIdList: [],
        industrialWasteJwnetTypeIdList: [],
        changeStoreTypeIdList: [],
        labelSettingTypeIdList: [],
        isOnlyFavorite: false,
        isIncludingInvalid: true,
        generateCompanyNameList: [null],
        generateStoreNameList: [null],
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      if(from.path.includes("/route")) {
        let page = vm.$store.getters["searchParamRoute/getRoutePage"]
        vm.paginationParams.page = page
      } else {
        // リセットする
        vm.$store.commit("searchParamRoute/setRoutePage", 1);
      }
    })
  },
};
</script>

<style scoped>
.btns {
  display: flex;
  align-items: center;
  justify-content: left;
}
</style>
