<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="運搬事業者編集" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div
          v-for="(collectFactory, factoryIndex) in formEdit.collectFactoryEditList"
          :key="'factory-' + factoryIndex"
          class="l-contents l-box"
        >
          <div class="l-box u-space-btwn">
            <h3 class="c-lead icon">運搬事業所情報{{ factoryIndex + 1 }}</h3>
            <div
              v-if="factoryIndex !== 0"
              class="c-btn secondary sm delete"
              @click="clickFactoryDeleteButton(factoryIndex)"
            >
              削除
            </div>
          </div>
          <div class="l-box">
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業所名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.name"
                  inputType="text"
                  placeholder="事業所名"
                  tooltip="100文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="100"
                  :isValid.sync="validate.collectFactory[factoryIndex].name.isValid"
                  :errMsg.sync="validate.collectFactory[factoryIndex].name.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="collectFactory.addressInfo.zipCode"
                        inputType="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="
                          validate.collectFactory[factoryIndex].addressInfo.zipCode
                            .isValid
                        "
                        :errMsg.sync="
                          validate.collectFactory[factoryIndex].addressInfo.zipCode
                            .errMsg
                        "
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandler(collectFactory.addressInfo)"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="collectFactory.addressInfo.selectedPrefectureTemp"
                        :validation="validateJustBlank"
                        :isValid.sync="
                          validate.collectFactory[factoryIndex].addressInfo.prefectures
                            .isValid
                        "
                        :errMsg.sync="
                          validate.collectFactory[factoryIndex].addressInfo.prefectures
                            .errMsg
                        "
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.name"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="collectFactory.addressInfo.address"
                        inputType="text"
                        placeholder="市町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          validate.collectFactory[factoryIndex].addressInfo.address
                            .isValid
                        "
                        :errMsg.sync="
                          validate.collectFactory[factoryIndex].addressInfo.address
                            .errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="collectFactory.addressInfo.buildingName"
                        inputType="text"
                        placeholder="番地建物名号室"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          validate.collectFactory[factoryIndex].addressInfo
                            .buildingName.isValid
                        "
                        :errMsg.sync="
                          validate.collectFactory[factoryIndex].addressInfo
                            .buildingName.errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.collectFactory[factoryIndex].tel.isValid"
                  :errMsg.sync="validate.collectFactory[factoryIndex].tel.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.fax"
                  inputType="number"
                  placeholder="FAX番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.collectFactory[factoryIndex].fax.isValid"
                  :errMsg.sync="validate.collectFactory[factoryIndex].fax.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>JWNETID(加入者番号)</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.jwnetContractNo"
                  inputType="text"
                  placeholder="JWNETID(加入者番号)"
                  tooltip="最大7文字の半角英数字で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="7"
                  :isValid.sync="validate.collectFactory[factoryIndex].jwnetContractNo.isValid"
                  :errMsg.sync="validate.collectFactory[factoryIndex].jwnetContractNo.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>EDI利用確認キー</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="collectFactory.ediKey"
                    inputType="text"
                    placeholder="EDI利用確認キー"
                    tooltip="最大8文字の半角英数字で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="8"
                    :isValid.sync="validate.collectFactory[factoryIndex].ediKey.isValid"
                    :errMsg.sync="validate.collectFactory[factoryIndex].ediKey.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>公開確認番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.publicConfirmationNo"
                  inputType="number"
                  placeholder="公開確認番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumber"
                  :isValid.sync="
                    validate.collectFactory[factoryIndex].publicConfirmationNo.isValid
                  "
                  :errMsg.sync="
                    validate.collectFactory[factoryIndex].publicConfirmationNo.errMsg
                  "
                />
              </div>
            </div>
          </div>
          <div
            v-for="(office, officeIndex) in collectFactory.collectOfficeList"
            :key="'office' + officeIndex"
            class="l-box"
          >
            <div class="l-box u-space-btwn">
              <h3 class="c-lead icon">営業所情報{{ officeIndex + 1 }}</h3>
              <div class="c-btn secondary sm delete"
                @click="clickOfficeDeleteButton(factoryIndex, officeIndex)"
              >
                削除
              </div>
            </div>
            <div class="l-box">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>営業所名</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                    :value.sync="office.name"
                    inputType="text"
                    placeholder="営業所名"
                    tooltip="100文字以内で入力してください"
                    :validation="validateNumberMaxLen"
                    :validateArg="100"
                    :isValid.sync="
                      validate.collectFactory[factoryIndex].collectOfficeList[
                        officeIndex
                      ].name.isValid
                    "
                    :errMsg.sync="
                      validate.collectFactory[factoryIndex].collectOfficeList[
                        officeIndex
                      ].name.errMsg
                    "
                  />
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>住所</label>
                </div>
                <div class="c-inputWrap__items">
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>郵便番号</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div class="c-inputWrap__items__cols center sm-mgn">
                        <InputText
                          :value.sync="office.addressInfo.zipCode"
                          inputType="number"
                          placeholder="1234567(ハイフンなし)"
                          tooltip="7桁の半角数字で入力してください。"
                          :validation="validateNumberEqualLen"
                          :validateArg="7"
                          :isValid.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].zipCode.isValid
                          "
                          :errMsg.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].zipCode.errMsg
                          "
                        />
                        <div
                          class="c-btn secondary sm"
                          @click="searchAddressHandler(office.addressInfo)"
                        >
                          住所自動入力
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>都道府県</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <Selectbox
                          :value.sync="office.addressInfo.selectedPrefectureTemp"
                          :validation="validateJustBlank"
                          :isValid.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].prefecturesName.isValid
                          "
                          :errMsg.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].prefecturesName.errMsg
                          "
                        >
                          <option
                            v-for="prefecture in prefectures"
                            :key="prefecture.id"
                            :value="prefecture.name"
                          >
                            {{ prefecture.name }}
                          </option>
                        </Selectbox>
                      </div>
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>市町村</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <InputText
                          :value.sync="office.addressInfo.address"
                          inputType="text"
                          placeholder="市町村"
                          tooltip="100文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="100"
                          :isValid.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].address.isValid
                          "
                          :errMsg.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].address.errMsg
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <div class="c-inputWrap">
                    <div class="c-inputWrap__label">
                      <label>番地建物名号室</label>
                    </div>
                    <div class="c-inputWrap__items">
                      <div>
                        <InputText
                          :value.sync="office.addressInfo.buildingName"
                          inputType="text"
                          placeholder="番地建物名号室"
                          tooltip="100文字以内で入力してください"
                          :validation="validateMaxLen"
                          :validateArg="100"
                          :isValid.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].buildingName.isValid
                          "
                          :errMsg.sync="
                            validate.collectFactory[factoryIndex].collectOfficeList[
                              officeIndex
                            ].buildingName.errMsg
                          "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label required">
                  <label>電話番号</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                      :value.sync="office.tel"
                      inputType="number"
                      placeholder="電話番号"
                      tooltip="半角数値で入力してください"
                      :validation="validateNumberMaxLen"
                      :validateArg="15"
                      :isValid.sync="
                      validate.collectFactory[factoryIndex].collectOfficeList[
                        officeIndex
                      ].tel.isValid
                    "
                      :errMsg.sync="
                      validate.collectFactory[factoryIndex].collectOfficeList[
                        officeIndex
                      ].tel.errMsg
                    "
                  />
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>FAX番号</label>
                </div>
                <div class="c-inputWrap__items">
                  <InputText
                      :value.sync="office.fax"
                      inputType="number"
                      placeholder="電話番号"
                      tooltip="半角数値で入力してください"
                      :validation="validateNumberMaxLen"
                      :validateArg="15"
                      :isValid.sync="
                      validate.collectFactory[factoryIndex].collectOfficeList[
                        officeIndex
                      ].fax.isValid
                    "
                      :errMsg.sync="
                      validate.collectFactory[factoryIndex].collectOfficeList[
                        officeIndex
                      ].fax.errMsg
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="l-each sm l-box">
              <button
                class="c-btn secondary func add"
                @click="addOffice(factoryIndex)"
              >
                営業所情報を追加
              </button>
            </div>
          </div>
        </div>
        <div class="l-each sm l-box">
          <div class="c-btn secondary func add" @click="addCollectFactory">
            運搬事業所を追加
          </div>
        </div>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandler">登録</div>
        </div>
      </div>
    </main>

    <AddressSelectModal
        :isShow.sync="isAddressSelectModalShow"
        :addresses="addressResults"
        @selectAddress="selectAddressHandler"
    />

    <!-- 事業所情報削除確認モーダル -->
    <!-- 営業所削除確認モーダル -->
    <div class="c-modal" v-show="isDisplayDeleteModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          {{ deleteOfficeIndex !== null ? "営業所情報" : "運搬事業所情報" }}を削除します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeDeleteModal">戻る</div>
          <div v-if="deleteOfficeIndex !== null" class="c-btn primary small" @click="deleteOffice">はい</div>
          <div v-else class="c-btn primary small" @click="deleteCollectFactory">はい</div>
        </div>
      </div>
    </div>

    <!-- 登録確認モーダル -->
    <div class="c-modal" v-show="isDisplayRegisterModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body" v-if="!isDeleteFactoryFlag">
          運搬事業所情報を編集します。<br />
          よろしいですか？
        </div>
        <div class="c-modal__body" v-else-if="isDeleteFactoryFlag">
          運搬事業所情報を編集します。<br />
          ルートに反映するために、<br />
          引き続き委託契約書情報の修正を行ってください。
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isDisplayRegisterModal = false">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import validation from "@/mixin/validation";
import formValidationEdit from "../mixins/form/formValidationEdit";
import formValueEdit from "../mixins/formValueEdit";
import callApi from "../mixins/callApi";
import axios from "axios";
import common from "@/mixin/common";
import {API_URL} from "@/const";
import addressSearch from "../mixins/form/addressSearch";
import AddressSelectModal from "../components/AddressSelectModal";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "collector-step2-edit",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    AddressSelectModal,
  },
  mixins: [validation, formValidationEdit, formValueEdit, callApi, addressSearch, common],
  data() {
    return {
      errMsgs: [],
      collectorId: null,
      deleteFactoryIndex: null,
      deleteOfficeIndex: null,
      isDisplayDeleteModal: false,
      isDisplayRegisterModal: false,
      isDeleteFactoryFlag: false,
      processing: false,
    };
  },

  methods: {
    // 運搬事業所 > 追加ボタン押下
    addCollectFactory() {
      this.formEdit.collectFactoryEditList.push(JSON.parse(JSON.stringify(this.dummyFormEdit.collectFactory)));
      this.validate.collectFactory.push(JSON.parse(JSON.stringify(this.dummyValidateEdit.collectFactory)));
    },
    // 営業所 > 追加ボタン押下
    addOffice(index) {
      this.formEdit.collectFactoryEditList[index].collectOfficeList.push(JSON.parse(JSON.stringify(this.dummyFormEdit.collectOffice)));
      this.validate.collectFactory[index].collectOfficeList.push(JSON.parse(JSON.stringify(this.dummyValidateEdit.collectOffice)));
    },
    // 運搬事業所 > 削除ボタン押下
    clickFactoryDeleteButton(index) {
      this.deleteFactoryIndex = index;
      this.isDisplayDeleteModal = true;
    },
    // 営業所 > 削除ボタン押下
    clickOfficeDeleteButton(index, officeIndex) {
      this.deleteFactoryIndex = index;
      this.deleteOfficeIndex = officeIndex;
      this.isDisplayDeleteModal = true;
    },
    // 事業所情報削除モーダルはいボタン押下
    deleteCollectFactory() {
      this.isDeleteFactoryFlag = true;
      this.formEdit.collectFactoryEditList.splice(this.deleteFactoryIndex, 1);
      this.validate.collectFactory.splice(this.deleteFactoryIndex, 1);
      this.closeDeleteModal();
    },
    // 営業所情報削除モーダルはいボタン押下
    deleteOffice() {
      this.formEdit.collectFactoryEditList[this.deleteFactoryIndex].collectOfficeList.splice(this.deleteOfficeIndex, 1);
      this.validate.collectFactory[this.deleteFactoryIndex].collectOfficeList.splice(this.deleteOfficeIndex, 1);
      this.closeDeleteModal();
    },
    // 削除モーダルを閉じる
    closeDeleteModal() {
      this.deleteFactoryIndex = null;
      this.deleteOfficeIndex = null;
      this.isDisplayDeleteModal = false;
    },
    // 戻るボタン押下
    backHandler() {
      this.$router.go(-1);
    },
    async checkRequiredHandler() {
      this.errMsgs = [];

      const isValid = await this.runValidateEdit();
      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.isDisplayRegisterModal = true;
    },
    // 登録ボタン押下
    registerHandler() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止

      const deleteFlag = this.isDeleteFactoryFlag;
      this.formEdit.collectFactoryEditList.map((d) => {
        if (!d.fax) delete d.fax;
        // 住所の全角変換
        d.addressInfo.address = this.toFullWidthStringForAddressInfo(d.addressInfo.address);
        d.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(d.addressInfo.buildingName);
        if (d.collectOfficeList.length > 0) {
          d.collectOfficeList.forEach(
            (co) => {
              co.addressInfo.address = this.toFullWidthStringForAddressInfo(co.addressInfo.address);
              co.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(co.addressInfo.buildingName);
            }
          )
        }
        this.prefectures.map((prefecture) => {
          if (prefecture.name == d.addressInfo.selectedPrefectureTemp) {
            d.addressInfo.prefecturesId = prefecture.id;
          }
        });
        delete d.addressInfo.selectedPrefectureTemp;
        d.collectOfficeList.map((c) => {
          this.prefectures.map((prefecture) => {
            if (prefecture.name == c.addressInfo.selectedPrefectureTemp) {
              c.addressInfo.prefecturesId = prefecture.id;
            }
          });
          delete c.addressInfo.selectedPrefectureTemp;
        });
      });
      axios.put(API_URL.COLLECTOR.EDIT_COLLECT_FACTORY + this.collectorId, this.formEdit)
          .then(() => {
            if (deleteFlag) {
              const params = new URLSearchParams();
              params.append("collectCompanyId", this.collectorId);
              this.$router.push(`/entrust?${params.toString()}`);
            } else {
              this.$router.push(`/collector/${this.collectorId}`);
            }
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.processing = false; // 2重submit解除
            this.isDisplayRegisterModal = false;
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },
  },

  mounted() {
    this.collectorId = Number(this.$route.params.id);
    this.getCollectFactoryListApi(this.collectorId);
    this.getPrefecturesApi();
  },
};
</script>
