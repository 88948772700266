<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="マニフェスト修正" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container l-area">
        <ErrorMessageIncludeHtml :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- マニフェスト情報 -->
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">マニフェスト情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>マニフェスト番号</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  {{ formValue.manifestInfo.manifestNo }}
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>引き渡し日</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputDate
                    :value.sync="formValue.manifestInfo.collectionDate"
                    :placeholder="'0000/00/00'"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.collectionDate.isValid"
                    :errMsg.sync="validate.collectionDate.errMsg"
                  />
                  <InputTime
                    class="sm"
                    :value.sync="formValue.manifestInfo.collectionTime"
                    :placeholder="'00:00'"
                    :validation="validateJustBlank"
                  />
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>交付日</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  {{ formValue.manifestInfo.issueDate | dateJpYMDFormat }}
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>担当者</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>登録担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols">
                      <div class="c-radio">
                        <input
                          id="regist-member-select"
                          type="radio"
                          :value="true"
                          v-model="isRegistMemberSelect"
                          @change="resetRegistMemberForm()"
                        />
                        <label class="c-radio__label" for="regist-member-select">
                          <span class="c-radio__box"></span>リストから選択
                        </label>
                      </div>
                      <div class="c-radio">
                        <input
                          id="regist-member-input"
                          type="radio"
                          :value="false"
                          v-model="isRegistMemberSelect"
                          @change="resetRegistMemberForm()"
                        />
                        <label class="c-radio__label" for="regist-member-input">
                          <span class="c-radio__box"></span>直接入力
                        </label>
                      </div>
                    </div>
                    <Selectbox
                      v-if="isRegistMemberSelect"
                      class="c-inputWrap__items c-select-input"
                      name="register-in-charge"
                      :value.sync="formValue.manifestInfo.registMember.id"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.registMember.isValid"
                      :errMsg.sync="validate.registMember.errMsg"
                    >
                      <option
                        v-for="(opt, index) in memberOpt"
                        :key="'mp' + index"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                    <InputText
                      v-if="isRegistMemberSelect == false"
                      class="c-inputWrap__items c-select-input"
                      inputType="text"
                      input_id="input_registerMember"
                      :value.sync="formValue.manifestInfo.registMember.name"
                      placeholder="入力してください"
                      tooltip="12文字以内で入力してください"
                      :validation="validateMaxLenWithoutOnlyWhitespace"
                      :validateArg="12"
                      :isValid.sync="validate.registMember.isValid"
                      :errMsg.sync="validate.registMember.errMsg"
                    />
                  </div>
                </div>
                <!-- /.c-inputWra -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>引き渡し担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols">
                      <div class="c-radio">
                        <input
                          id="collection-select"
                          type="radio"
                          :value="true"
                          v-model="isGenerateMemberSelect"
                          @change="resetGenerateMemberForm()"
                        />
                        <label class="c-radio__label" for="collection-select">
                          <span class="c-radio__box"></span>リストから選択
                        </label>
                      </div>
                      <div class="c-radio">
                        <input
                          id="collection-input"
                          type="radio"
                          :value="false"
                          v-model="isGenerateMemberSelect"
                          @change="resetGenerateMemberForm()"
                        />
                        <label class="c-radio__label" for="collection-input">
                          <span class="c-radio__box"></span>直接入力
                        </label>
                      </div>
                    </div>
                    <Selectbox
                      v-if="isGenerateMemberSelect"
                      class="c-inputWrap__items c-select-input"
                      name="emit-in-charge"
                      :value.sync="formValue.manifestInfo.generateMember.id"
                      :validation="validateJustBlank"
                      :isValid.sync="validate.generateMember.isValid"
                      :errMsg.sync="validate.generateMember.errMsg"
                    >
                      <option
                        v-for="(opt, index) in memberDisOpt"
                        :key="'mp' + index"
                        :value="opt.id"
                      >
                        {{ opt.name }}
                      </option>
                    </Selectbox>
                    <InputText
                      v-if="isGenerateMemberSelect == false"
                      class="c-inputWrap__items c-select-input"
                      inputType="text"
                      input_id="input_generateMember"
                      :value.sync="formValue.manifestInfo.generateMember.name"
                      placeholder="入力してください"
                      tooltip="12文字以内で入力してください"
                      :validation="validateMaxLenWithoutOnlyWhitespace"
                      :validateArg="12"
                      :isValid.sync="validate.generateMember.isValid"
                      :errMsg.sync="validate.generateMember.errMsg"
                    />
                  </div>
                </div>
                <div v-if="formValue.manifestInfo.proxyCollectMember != null" class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>運搬代理登録担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ formValue.manifestInfo.proxyCollectMember.name }}
                  </div>
                </div>
                <div v-else-if="formValue.manifestInfo.proxyDisposalMember != null" class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>処分代理登録担当者</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ formValue.manifestInfo.proxyDisposalMember.name }}
                  </div>
                </div>
                <!-- /.c-inputWra -->
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>運搬費用（総額）</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="formValue.collectCost"
                    tooltip="半角数値で入力してください"
                    inputType="number"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.collectCost.isValid"
                    :errMsg.sync="validate.collectCost.errMsg"
                  />
                  <span>円</span>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分費用</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="formValue.disposalCost"
                    tooltip="半角数値で入力してください"
                    inputType="number"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.disposalCost.isValid"
                    :errMsg.sync="validate.disposalCost.errMsg"
                  />
                  <span>円</span>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>有価売却額</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="formValue.valuablesCost"
                    tooltip="半角数値で入力してください"
                    inputType="number"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.valuablesCost.isValid"
                    :errMsg.sync="validate.valuablesCost.errMsg"
                  />
                  <span>円</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->

        <!-- 廃棄物情報 -->
        <div class="l-contents">
          <div class="l-box">
            <h3 class="c-lead icon">廃棄物情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物種類</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  {{ jwnetItem }}
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>ルート</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols onTop l-area">
                  <div>
                    <div v-if="routeDetailInfo">
                      <dl class="c-dl">
                        <dt>ルート名称：</dt>
                        <dd>{{ routeName }}</dd>
                      </dl>
                      <dl class="c-dl">
                        <dt>排出事業場：</dt>
                        <dd>{{ routeGenerateStore }}</dd>
                      </dl>
                      <dl class="c-dl">
                        <dt>運搬業者：</dt>
                        <dd>{{ routeCollectCompany }}</dd>
                      </dl>
                      <dl class="c-dl">
                        <dt>処分場：</dt>
                        <dd>{{ routeDisposalStore }}</dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物名称</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  {{ wasteName }}
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>詳細名称</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  {{ formValue.wasteInfo.detailWasteName | blankText }}
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>廃棄物数量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols l-area">
                  <InputText
                    inputType="number"
                    :value.sync="formValue.wasteInfo.wasteQuantity"
                    tooltip="廃棄物数量は数字で入力してください。"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.quantityValue.isValid"
                    :errMsg.sync="validate.quantityValue.errMsg"
                  />
                  <Selectbox
                    class="sm"
                    name="unit"
                    :value.sync="formValue.wasteInfo.wasteUnitType.id"
                    :validation="validateJustBlank"
                  >
                    <option
                      v-for="(opt, index) in wasteUnitOpt"
                      :key="'wu' + index"
                      :value="opt.id"
                    >
                      {{ opt.name }}
                    </option>
                  </Selectbox>
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>数量確定者</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  name="number-in-charge"
                  :value.sync="
                    formValue.wasteInfo.quantityConfirmBusinessType.id
                  "
                  :validation="validateJustBlank"
                  :isValid.sync="validate.quantityConfirmBusinessTypeId.isValid"
                  :errMsg.sync="validate.quantityConfirmBusinessTypeId.errMsg"
                >
                  <option
                    v-for="(opt, index) in confirmMemberOpt"
                    :key="'cm' + index"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>荷姿</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  name="package-name"
                  :value.sync="formValue.wasteInfo.shapeType.id"
                  :validation="validateJustBlank"
                  :isValid.sync="validate.shapeTypeId.isValid"
                  :errMsg.sync="validate.shapeTypeId.errMsg"
                >
                  <option
                    v-for="(opt, index) in shapeOpt"
                    :key="'shp' + index"
                    :value="opt.id"
                  >
                    {{ opt.name }}
                  </option>
                </Selectbox>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>荷姿数量</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="formValue.wasteInfo.shapeQuantity"
                    inputType="number"
                    :validation="validateNumber"
                    :isValid.sync="validate.shapeQuantity.isValid"
                    :errMsg.sync="validate.shapeQuantity.errMsg"
                  />
                </div>
              </div>
            </div>

            <div v-if="isDisplayHarmfulSelection" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>有害物質名</label>
              </div>
              <div class="c-inputWrap__items">
                <div
                  v-for="(harmfulItem, itemIndex) in formValue.harmfulList"
                  :key="`hmfl-item-${itemIndex}`"
                  class="l-flex start"
                >
                  <Selectbox
                    class="l-area"
                    name="package-name"
                    :value.sync="harmfulItem.id"
                    :validation="validateJustBlank"
                  >
                    <option
                      v-for="(harmfulType, index) in harmfulTypes"
                      :key="'hmfl-' + index"
                      :value="harmfulType.id"
                    >
                      {{ harmfulType.name }}
                    </option>
                  </Selectbox>
                  <div
                    class="c-input__addBtn c-btn secondary delete sm"
                    @click="removeHarmfulTypeHandler(harmfulItem)"
                  >
                    削除
                  </div>
                </div>
                <div
                  class="c-btn secondary add sm"
                  v-if="formValue.harmfulList && formValue.harmfulList.length < 6"
                  @click="addHarmfulTypeHandler"
                >
                  有害物質名を追加
                </div>
              </div>
            </div>


            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分方法</label>
              </div>
              <div class="c-inputWrap__items">
                <Selectbox
                  name="package-name"
                  :value.sync="formValue.wasteInfo.disposalWayTypeId"
                >
                  <option value=""></option>
                  <option
                    v-for="(disposalWayType, index) in disposalWayTypes"
                    :key="'disposalWayType' + index"
                    :value="disposalWayType.id"
                  >
                    {{ disposalWayType.name }}
                  </option>
                </Selectbox>
              </div>
            </div>

            <div class="c-inputWrap" v-for="(contact, contactIndex) in formValue.contactNoInfo" :key="`ctct-item-${contactIndex}`">
              <div class="c-inputWrap__label">
                <label>連絡番号{{ contactIndex+1 }}</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="l-flex start">
                  <InputText
                    :value.sync="contact.contactNo"
                    inputTyle="text"
                    :validation="validateJustBlank"
                    :isValid.sync="validate.contactNo[contactIndex].isValid"
                    :errMsg.sync="validate.contactNo[contactIndex].errMsg"
                  />
                </div>
              </div>
            </div>

            <!-- 備考 -->
            <div class="c-inputWrap" v-for="(taInfo, index) in formValue.remarksInfo" :key="'ta' + taInfo.id">
              <div class="c-inputWrap__label">
                <label>備考{{ index+1 }}</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <InputText
                    tooltip="備考は50バイト以内で入力してください。"
                    :value.sync="taInfo.detail"
                    inputType="text"
                    :validateArg="50"
                    :validation="validateByteLen"
                    :isValid.sync="validate.remarks[index].isValid"
                    :errMsg.sync="validate.remarks[index].errMsg"
                  />
                </div>
              </div>
            </div>

            <!-- 添付ファイル -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>添付ファイル</label>
              </div>
              <div class="c-inputWrap__items">
                <!-- 容量制限エラーメッセージ表示領域 -->
                <span v-if="manifestAttachedFileMsg" class="c-input__errMsg">{{
                  manifestAttachedFileMsg
                }}</span>
                <template v-if="!formValue.manifestInfo || !formValue.manifestInfo.attachedFileName">
                  <input
                    id="file-upload"
                    type="file"
                    @change="(e) => uploadManifestAttachedFile(e)"
                    ref="attachedFile"
                    accept=".pdf"
                  />
                  <label
                    class="c-btn secondary upload fileupload-sm"
                    for="file-upload"
                  >
                    ファイルアップロード
                  </label>
                </template>
                <template v-else>
                  <div class="l-flex start">
                    <div class="upload-filename">
                      <span class="upload-filename__text" v-if="formValue.manifestInfo.attachedFilePath">
                        {{ formValue.manifestInfo.attachedFileName }}
                      </span>
                      <!-- アップロード済みのファイルはダウロードリンク表示 -->
                      <a v-else
                        class="c-text text-link c-entrustText__text"
                        @click="downloadManifestAttachedFile(formValue.manifestInfo.attachedFileName)"
                      >
                        <span class="upload-filename__text">
                          {{ formValue.manifestInfo.attachedFileName }}
                        </span>
                      </a>
                    </div>
                    <label
                      class="c-btn secondary delete sm"
                      @click="deleteManifestAttachedFile()"
                    >
                      削除
                    </label>
                  </div>
                </template>
              </div>
            </div>

            <!-- WDS -->
            <div v-if="routeDetailInfo.wdsFileName" class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>WDS</label>
              </div>
              <div class="c-inputWrap__items">
                <a class="c-text text-link c-entrustText__text" @click="downloadWDS()">
                  {{ routeDetailInfo.wdsFileName }}
                </a>
              </div>
            </div>

            <!-- トン換算係数 -->
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>トン換算係数</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="l-flex start">
                  <InputText
                    :value.sync="formValue.manifestInfo.conversionFactorKg"
                    class="l-area sm"
                    tooltip="半角数値で入力してください"
                    inputType="number"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.conversionFactorKg.isValid"
                    :errMsg.sync="validate.conversionFactorKg.errMsg"
                  /><span>/ kg</span>
                </div>
                <div class="l-flex start">
                  <InputText
                    :value.sync="formValue.manifestInfo.conversionFactorM3"
                    class="l-area sm"
                    tooltip="半角数値で入力してください"
                    inputType="number"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.conversionFactorM3.isValid"
                    :errMsg.sync="validate.conversionFactorM3.errMsg"
                  /><span>/ m3</span>
                </div>
                <div class="l-flex start">
                  <InputText
                    :value.sync="formValue.manifestInfo.conversionFactorLiter"
                    class="l-area sm"
                    tooltip="半角数値で入力してください"
                    inputType="number"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.conversionFactorLiter.isValid"
                    :errMsg.sync="validate.conversionFactorLiter.errMsg"
                  /><span>/ リットル</span>
                </div>
                <div class="l-flex start">
                  <InputText
                    :value.sync="formValue.manifestInfo.conversionFactorUnitNumber"
                    class="l-area sm"
                    tooltip="半角数値で入力してください"
                    inputType="number"
                    :validation="validateNumberOrFloat"
                    :isValid.sync="validate.conversionFactorUnitNumber.isValid"
                    :errMsg.sync="validate.conversionFactorUnitNumber.errMsg"
                  /><span>/ 個・台</span>
                </div>

              </div>
            </div>

            <!-- ルートラベル -->
            <div
              v-for="manifestLabel in formValue.labelInfo.manifestLabels"
              :key="manifestLabel.labelId"
              class="c-inputWrap"
            >
              <div class="c-inputWrap__label">
                <label>{{ manifestLabel.labelHeader }}</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap__items__cols">
                  <InputText
                    :value.sync="manifestLabel.labelValue"
                    inputTyle="number"
                    :validation="validateJustBlank"
                  />
                  <span v-if="manifestLabel.labelUnit">
                    {{ manifestLabel.labelUnit }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->
      </div>
      <div class="l-btns l-container">
        <div class="left">
          <router-link class="c-btn secondary small back" :to="`/manifest/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`">戻る</router-link>
        </div>
        <div class="center">
          <div class="c-btn primary mid" :class="{ disabled: isDisabledRegisterButton || isJwnetReferring }" @click="checkRequiredHandler">登録</div>
        </div>
        <div class="right">
          <div class="c-btn secondary small" @click="deliveryChecksheetHandler">受渡確認表印刷</div>
        </div>
      </div>
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを修正します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="submit">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputDate from "@/components/parts/input/InputDate";
import InputTime from "@/components/parts/input/InputTime";

import validation from "@/mixin/validation";
import formValue from "./mixins/formValue";
import callApi from "./mixins/callApi";
import contactNo from "./mixins/contactNo";
import route from "./mixins/route";
import textarea from "./mixins/textarea";
import harmfulType from "./mixins/harmfulType";
import validate from "./mixins/validate";

import { API_URL, DOMAIN } from "@/const/index";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";
import common from "@/mixin/common";
import uploadFile from "@/mixin/uploadFile";
import downloadManifestAttachedFile from "@/pages/manifest/common/downloadManifestAttachedFile";
import commonApi from "@/mixin/commonApi";
import ErrorMessageIncludeHtml from "@/components/parts/ErrorMessageIncludeHtml";

export default {
  name: "manifest-edit",
  components: {
    ErrorMessageIncludeHtml,
    ModalFooterButtons,
    InputText,
    Selectbox,
    InputDate,
    InputTime,
  },
  mixins: [
    commonApi,
    validation,
    formValue,
    callApi,
    contactNo,
    route,
    textarea,
    harmfulType,
    validate,
    common,
    uploadFile,
    downloadManifestAttachedFile,
  ],
  data() {
    return {
      backPath: null,
      manifestId: null,
      isRecurryingModalShow: false,
      isConfirmModalShow: false,
      selectedRecurrying: "",
      errMsgs: [],
      processing: false,
      manifestAttachedFileMsg: "",
      isGenerateMemberSelect: true,
      isRegistMemberSelect: true,
    };
  },
  methods: {
    // 画面内下部の登録押下
    checkRequiredHandler() {
      this.errMsgs = [];

      if (!this.runValidate()) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.isConfirmModalShow = true;
    },

    // 編集API
    editPutApi(data) {
      this.processing = true; // 2重submit防止
      axios
        .put(`${API_URL.MANIFEST.INFO}${this.manifestId}`, data)
        .then((res) => {
          const manifestId = res.data.manifestId;
          this.$router.push(`/manifest/${manifestId}?transitionSource=${this.backPath ? this.backPath : ''}`);
        })
        .catch((err) => {
          this.isConfirmModalShow = false;
          this.processing = false; // 2重submit解除
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    deliveryChecksheetHandler() {
      this.postDeliveryChecksheetApi(this.manifestId);
    },
    downloadWDS() {
      this.getWDSDownloadApi(this.routeDetailInfo.routeId, this.routeDetailInfo.wdsFileName);
    },

    // モーダル内の登録押下
    submit() {
      // データを編集用にフォーマットしたものを返す
      this.formatEditData().then((formattedData) => {
        this.editPutApi(formattedData);
      });
    },

    /** PDFのアップロード */
    uploadManifestAttachedFile(e) {
      this.manifestAttachedFileMsg = "";

      const sizeLimit = 1024 * 1024 * 5;
      const file = e.target.files[0] || e.dataTransfer.files[0];

      // PDFファイル種類チェック
      if (file.type !== "application/pdf") {
        this.manifestAttachedFileMsg = "PDFファイルを選択してください。";
        return;
      }

      if (file.size > sizeLimit) {
        this.manifestAttachedFileMsg = `ファイルサイズは5MB以下にしてください。`;
        return;
      }

      // PDFファイル名は50文字列以内で設定するチェック
      if (file.name.length > 50) {
        this.manifestAttachedFileMsg = "ファイル名は50文字列以内にしてください。";
        return;
      }

      this.uploadFileApi(file)
        .then((path) => {
          this.formValue.manifestInfo.attachedFilePath = path;
          this.formValue.manifestInfo.attachedFileName = file.name;
          this.manifestAttachedFileMsg = "";

          // NOTE: 反映されないので強制レンダリング
          this.$forceUpdate();
        })
        .catch((error) => {
          console.log(error);
          this.manifestAttachedFileMsg = error;

          // NOTE: 反映されないので強制レンダリング
          this.$forceUpdate();
        });
    },

    /** マニフェスト添付ファイルを削除する */
    deleteManifestAttachedFile() {
      this.formValue.manifestInfo.attachedFilePath = null;
      this.formValue.manifestInfo.attachedFileName = null;
      if (this.$refs.attachedFile) {
        this.$refs.attachedFile.value = null;
      }
      this.manifestAttachedFileMsg = "";

      // NOTE: 反映されないので強制レンダリング
      this.$forceUpdate();
    },

    /** マニフェスト添付ファイルダウンロード押下 */
    downloadManifestAttachedFile(attachedFileName) {
      this.downloadManifestAttachedFileApi(this.manifestId, attachedFileName);
    },
    resetGenerateMemberForm() {
      this.formValue.manifestInfo.generateMember = {
        id: "",
        name: "",
      };
    },

    resetRegistMemberForm() {
      this.formValue.manifestInfo.registMember = {
        id: "",
        name: "",
      };
    }
  },

  computed: {
    isDisplayHarmfulSelection() {
      return this.formValue.routeInfo.jwnetItem.isHarmful;
    },
    jwnetItem() {
      const filteredJwnetItems = this.jwnetItemsOpt.filter((jwnetItem) => jwnetItem.id === this.formValue.routeInfo.jwnetItem.id);

      if (filteredJwnetItems.length > 0) {
        return filteredJwnetItems[0].name;
      }
      return "";
    },
    routeName() {
      return this.routeDetailInfo.routeName;
    },
    routeGenerateStore() {
      return this.routeDetailInfo.generateStoreInfo.name;
    },
    routeCollectCompany() {
      return this.routeDetailInfo.routeCollectCompanyInfo
        .map((cc) => cc.collectCompanyInfo.name)
        .join(",");
    },
    routeDisposalStore() {
      return this.formValue.routeInfo.disposalSite.name;
    },
    wasteName() {
      return this.formValue.wasteInfo.wasteName.name;
    },
    isDisabledRegisterButton() {
      if (!this.formValue || !this.formValue.manifestInfo || !this.formValue.manifestInfo.manifestStatusInfo) {
        return false;
      }

      const manifestStatus = this.formValue.manifestInfo.manifestStatusInfo.id;

      return manifestStatus != DOMAIN.MANIFEST_STATUS.ISSUED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.COLLECT_REPORTED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.DISPOSAL_REPORTED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.FINAL_DISPOSAL_REPORTED &&
        manifestStatus != DOMAIN.MANIFEST_STATUS.COMPLETE;
    }
  },

  mounted() {
    this.backPath = this.$route.query.transitionSource;
    // URLからID取得
    this.manifestId = +this.$route.params.id;
    // 初期表示に必要なAPIを呼ぶ
    this.getManifestInfoApi(this.manifestId).then((res) => {
      this.getRouteListApi(res.data.manifestInfo.generateStoreInfo.id);
      this.getRouteInfoApi(res.data.routeInfo.routeId);
      this.getMemberOptApi(res.data.routeInfo.generateStore.id);
      this.getDisposalWayTypesApi(res.data.routeInfo.routeId);
      this.getRouteShapeTypeApi(res.data.routeInfo.routeId);
      this.getConfirmMemberApi(res.data.routeInfo.routeId);
      if (this.formValue.manifestInfo.generateMember && this.formValue.manifestInfo.generateMember.id === null) {
        this.isGenerateMemberSelect = false
      }
      if (this.formValue.manifestInfo.registMember && this.formValue.manifestInfo.registMember.id === null) {
        this.isRegistMemberSelect = false
      }
      if(this.formValue.manifestInfo.manifestNo) {
        this.getIsJwnetReffering(this.formValue.manifestInfo.manifestNo)
      }
    });
    this.getJwnetItemsApi();
    this.getRecurryingApi();
    this.getWasteUnitApi();
    this.getHarmfulTypesApi();
  },
};
</script>

<style scoped>
.c-select-input {
  padding-left: 0; 
  width: 22rem; 
  margin-top: 2.7rem;
}
</style>