import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";

export default {
  data() {
    return {
      validate: {
        countInfo: { isValid: true, errMsg: "" },
        collectionDate: { isValid: true, errMsg: "" },
        collectionTime: { isValid: true, errMsg: "" },
        route: { errMsg: "" },
        jwnetItemType: { isValid: true, errMsg: "" },
        proxyGenerateId: { isValid: true, errMsg: "" },
        generateStoreId: { isValid: true, errMsg: "" },
        shapeQuantity: { isValid: true, errMsg: "" },
        anyGenerateStoreName: { isValid: true, errMsg: "" },
        zipCode: { isValid: true, errMsg: "" },
        prefecture: { isValid: true, errMsg: "" },
        address: { isValid: true, errMsg: "" },
        buildingName: { isValid: true, errMsg: "" },
        detailWasteName: { isValid: true, errMsg: "" },
        wasteQuantity: { isValid: true, errMsg: "" },
        wasteUnitTypeId: { isValid: true, errMsg: "" },
        contactNo: [{ isValid: true, errMsg: "" }],
        remarks: [{ isValid: true, errMsg: "" }],
        selectedRecurrying: { isValid: true, errMsg: "" },
        recurringEndDate: { isValid: true, errMsg: "" },
        registMemberName: { isValid: true, errMsg: "" },
        generateMemberName: { isValid: true, errMsg: "" },
      },
    };
  },
  methods: {
    validatesCheck() {
      const v = this.validate;
      const w = this.formValue.wasteInfo;
      const r = this.formValue.recurringInfo;
      const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      let promise = null;

      if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length && !v.zipCode.isValid) {

        promise = new Promise((resolve) => {
          // 何かの処理
          v.zipCode.errMsg = "正しい値を入力してください。";
          resolve(false);
        });
        
      } else {
        if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode}`;
          promise = axios
            .get(url, {adapter: axiosJsonpAdapter})  // Promise型
            .then((res) => {
              let isValidForm = true;
              const results = res.data.results;
              if (results != null) {
                if (v.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (this.selectedPrefectureTemp === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                      if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address === address.address2) {
                        addressFlg = true;
                      }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }

              return isValidForm;
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          promise = new Promise((resolve) => {
            resolve(true);
          });
        }
      }

      promise = promise.then((prevIsValidForm) => {
        let isValidForm = prevIsValidForm;
              
        if (this.selectedRecurrying === "custom" && (r.intervalValue === "" || r.recurringTypeId === "")) {
          v.selectedRecurrying.errMsg = "カスタム設定で、間隔をご選択ください。";
          isValidForm = false;
        }
        // 繰り返し設定の場合
        if(this.selectedRecurrying !== "") {
          if (this.formValue.recurringInfo.recurringEndDate !== "" &&
            this.formValue.recurringInfo.recurringEndDate <= this.formValue.scheduleSettingInfo.firstCollectionDate) {
            v.recurringEndDate.errMsg = "繰り返し終了日は引き渡し日以降で選択してください。";
            isValidForm = false;
          }
        }

        if (this.formValue.wasteInfo.detailWasteName && !v.detailWasteName.isValid) {
          v.detailWasteName.errMsg = "詳細名称は30文字以内で入力してください。";
          isValidForm = false;
        }

        if (this.formValue.manifestInfo.registMember && this.formValue.manifestInfo.registMember.name) {
          if (!v.registMemberName.isValid) {
            v.registMemberName.errMsg = "12文字以内で入力してください。";
            isValidForm = false;
          }
        }

        if (this.formValue.manifestInfo.generateMember && this.formValue.manifestInfo.generateMember.name) {
          if (!v.generateMemberName.isValid) {
            v.generateMemberName.errMsg = "12文字以内で入力してください。";
            isValidForm = false;
          }
        }

        if (!v.countInfo.isValid) {
          v.countInfo.errMsg = "必須項目です、ご選択ください。";
          isValidForm = false;
        }

        if (w.wasteQuantity) {
          if (!wasteQuantityRegexp.test(w.wasteQuantity)) {
            v.wasteQuantity.errMsg =
              "整数5桁、小数点1桁、小数部3桁で入力してください。";
            isValidForm = false;
          }
          if (w.wasteQuantity == 0.0) {
            v.wasteQuantity.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
        }
        if ((w.wasteQuantity && !w.wasteUnitTypeId) || (!w.wasteQuantity && w.wasteUnitTypeId)) {
          v.wasteUnitTypeId.errMsg = "廃棄物数量の数量、単位いずれかのみの設定はできません。未設定もしくは、両方の設定をしてください。";
          isValidForm = false;
        }

        if (!v.proxyGenerateId.isValid) {
          v.proxyGenerateId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.generateStoreId.isValid) {
          v.generateStoreId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.collectionDate.isValid) {
          v.collectionDate.errMsg = "必須項目です、ご選択ください。";
          isValidForm = false;
        }

        if (w.shapeQuantity) {
          if (String(w.shapeQuantity).length > 5) {
            v.shapeQuantity.errMsg =
              "荷姿数量は5桁以内の数値で入力してください。";
            isValidForm = false;
          }
          if (!Number.isInteger(Number(w.shapeQuantity))) {
            v.shapeQuantity.errMsg = "荷姿数量は整数で入力してください。";
            isValidForm = false;
          }
          if(Number(w.shapeQuantity) <= 0) {
            v.shapeQuantity.errMsg = "荷姿数量は0より大きい値を設定してください。";
            isValidForm = false;
          }
        }

        if (!v.jwnetItemType.isValid) {
          v.jwnetItemType.errMsg = "必須項目です、ご選択ください。";
          isValidForm = false;
        }
        if (
          this.formValue.manifestInfo.routeId != null &&
          this.formValue.manifestInfo.routeId !== ""
        ) {
          v.contactNo.map((cv, index) => {
            cv.errMsg = ""
            const f = this.formValue.contactNoInfo.contactNoList[index];
            if (f.contactNo !== "") {
              if ((f.contactNo.length && !cv.isValid)) {
                cv.errMsg = "半角英数字で入力してください。";
                isValidForm = false;
              }
              if (!halfAlphaRegexp.test(f.contactNo)) {
                cv.errMsg = "半角英数字で入力してください。";
                isValidForm = false;
              }
              if (
                  encodeURIComponent(f.contactNo).replace(/%../g, "x").length > 20
              ) {
                cv.errMsg = "20バイト以内で入力してください。";
                isValidForm = false;
              }
            }
          });
          v.remarks.map((re, index) => {
            re.errMsg = ""
            const r = this.formValue.remarksInfo.remarksList[index];
            if (r.remarks) {
              if (!this.isValidByteLength(r.remarks, 50)) {
                re.errMsg = "備考は50バイト以内で入力してください。";
                isValidForm = false;
              } else if (
                PROHIBITED_CHARACTERS_REGEX.test(r.remarks) ||
                !this.isContainValidCharacter(r.remarks)
              ) {
                re.errMsg = "登録できない文字が含まれています。";
                isValidForm = false;
              }
            }
          });

          // 画面側で、任意事業場の項目に１つでも入力値があると、「事業場名」、「郵便番号」、「都道府県」、「市区町村」は、入力しないとエラーとする
          if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreName.length
            || this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length
            || this.selectedPrefectureTemp
            || this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length
            || this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length
          ) {
            if (!v.anyGenerateStoreName.isValid) {
              v.anyGenerateStoreName.errMsg = "60文字以内で入力してください。";
              isValidForm = false;
            }
            if (!this.formValue.anyGenerateStoreInfo.anyGenerateStoreName.length) {
              v.anyGenerateStoreName.errMsg = "任意事業場を設定する場合は必須入力になります。";
              isValidForm = false;
            }
            if (!this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length) {
              v.zipCode.errMsg = "任意事業場を設定する場合は必須入力になります。";
              isValidForm = false;
            }
            if (!this.selectedPrefectureTemp || !this.selectedPrefectureTemp.length) {
              v.prefecture.errMsg = "任意事業場を設定する場合は必須入力になります。";
              isValidForm = false;
            }
            if (!this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
              v.address.errMsg = "任意事業場を設定する場合は必須入力になります。";
              isValidForm = false;
            }
          }
          if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length > 0 && 
            this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length > 100) {
            v.address.errMsg = "市区町村は100文字以内で入力してください。";
            isValidForm = false;
          }
          if (!v.buildingName.isValid && this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length) {
            v.buildingName.errMsg = "40文字以内で入力してください。";
            isValidForm = false;
          }

        } else if (
          this.formValue.manifestInfo.routeId == null ||
          this.formValue.manifestInfo.routeId === ""
        ) {
          v.route.errMsg = "必須項目です、ご選択ください。";
          isValidForm = false;
        }

        const collectionDate = this.formValue.scheduleSettingInfo.firstCollectionDate;
        if (this.routeDetailInfo
            && collectionDate
            && !this.routeDetailInfo.isEntrustUpdate
            && (collectionDate < this.routeDetailInfo.activeStartDate
                || (this.routeDetailInfo.activeEndDate && collectionDate > this.routeDetailInfo.activeEndDate))
        ){
          v.collectionDate.errMsg = "ルート利用期間外です。契約、ルートの有効期間を確認して下さい。";
          isValidForm = false;
        }

        return isValidForm;
      });
      
      return promise;
    },

    runValidate() {
      return this.validatesCheck();
    },

    // 半角は1Byte・全角2Byte文字を分けてカウントして、Byteに変換する(Byte数はShift-jis)
    countByteByString(string) {
      let byteSize = 0;
      // 半角・全角判定
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9/]*$/);

      // 文字列１つ１つを取得し、半角か全角か判定する
      for (let i = 0; i < string.length; i++) {
        let character = string.charAt(i);

        if (halfAlphaRegexp.test(character)){
          byteSize += 1;
        } else {
          byteSize += 2;
        }
      }
      return byteSize;
    },
  },
};
