import { QUANITTY_REGEX, PROHIBITED_CHARACTERS_REGEX } from "@/const";

export default {
  data() {
    return {
      validate: {
        routeName: { isValid: true, errMsg: "" },
        wasteNameId: { isValid: true, errMsg: "" },
        detailWasteName: { isValid: true, errMsg: "" },
        plansQuantity: { isValid: true, errMsg: "" },
        limitQuantity: { isValid: true, errMsg: "" },
        shapeQuantity: { isValid: true, errMsg: "" },
        collectUnitPriceList: [
          { isValid: true, errMsg: "" }
        ],
        disposalUnitPrice: { isValid: true, errMsg: "" },
        valuablesUnitPrice: { isValid: true, errMsg: "" },
        conversionFactorKg: { isValid: true, errMsg: "" },
        conversionFactorM3: { isValid: true, errMsg: "" },
        conversionFactorLiter: { isValid: true, errMsg: "" },
        conversionFactorUnitNumber: { isValid: true, errMsg: "" },
        routeContactNoList: [],
        routeRemarksList: [
          { isValid: true, errMsg: "" },
        ],
        labelList: [],
        newLabelList: [],
        registMemberName: { isValid: true, errMsg: "" },
        generateMemberName: { isValid: true, errMsg: "" },
        notiMailList: [{ isValid: true, errMsg: "" }],
      },
    };
  },
  methods: {
    check(formDuplicate, newLabelList) {
      let isValidForm = true;
      const v = this.validate;
      const numberRegexp = new RegExp(/^[0-9]*$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      // 予定数量
      if (formDuplicate.plansQuantity && !QUANITTY_REGEX.test(formDuplicate.plansQuantity)) {
        v.plansQuantity.errMsg = "整数5桁、小数点1桁、小数部3桁で入力してください。";
        isValidForm = false;
      }

      if (formDuplicate.detailWasteName && !v.detailWasteName.isValid) {
        v.detailWasteName.errMsg = "詳細名称は30文字以内で入力してください。";
        isValidForm = false;
      }

      // 廃棄物名称
      if (!v.wasteNameId.isValid) {
        v.wasteNameId.errMsg = "正しい値を入力してください。";
        isValidForm = false;
      }

      const unitPriceRegexp = new RegExp(/^([-]?\d{1,8}|0)(\.\d{1,3})?$/);
      v.collectUnitPriceList.forEach((collectUnitPrice, index) => {
        if (formDuplicate.collectUnitPriceList[index].collectUnitPrice ||
            (formDuplicate.collectUnitPriceList[index].collectUnitPrice != null &&
                formDuplicate.collectUnitPriceList[index].collectUnitPrice.toString())) {
          if (!collectUnitPrice.isValid) {
            collectUnitPrice.errMsg = "運搬単価は数字で入力してください。";
            isValidForm = false;
          } else if (!unitPriceRegexp.test(formDuplicate.collectUnitPriceList[index].collectUnitPrice)) {
            collectUnitPrice.errMsg = "単価は整数8桁、小数点1桁、小数部3桁で入力してください。";
            isValidForm = false;
          }
        } else if (formDuplicate.collectUnitPriceList[index].collectPriceWasteUnitTypeId) {
          collectUnitPrice.errMsg = "単価も入力してください。";
          isValidForm = false;
        }
      });

      if (formDuplicate.disposalUnitPrice ||
          (formDuplicate.disposalUnitPrice != null && formDuplicate.disposalUnitPrice.toString())) {
        if (!v.disposalUnitPrice.isValid) {
          v.disposalUnitPrice.errMsg = "処分単価は数字で入力してください。";
          isValidForm = false;
        } else if (!unitPriceRegexp.test(formDuplicate.disposalUnitPrice)) {
          v.disposalUnitPrice.errMsg = "単価は整数8桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
      } else if (formDuplicate.disposalPriceWasteUnitTypeId) {
        v.disposalUnitPrice.errMsg = "単価も入力してください。";
        isValidForm = false;
      }

      if (formDuplicate.valuablesUnitPrice) {
        if (!v.valuablesUnitPrice.isValid) {
          v.valuablesUnitPrice.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else if (!unitPriceRegexp.test(formDuplicate.valuablesUnitPrice)) {
          v.valuablesUnitPrice.errMsg = "単価は整数8桁、小数点1桁、小数部3桁で入力してください。";
          isValidForm = false;
        }
      }

      if (formDuplicate.shapeQuantity != null) {
        if (!numberRegexp.test(formDuplicate.shapeQuantity)) {
          v.shapeQuantity.errMsg = "荷姿数量は数字で入力してください。";
          isValidForm = false;
        }
        if (String(formDuplicate.shapeQuantity).length > 5) {
          v.shapeQuantity.errMsg = "荷姿数量は5桁以内の数値で入力してください。";
          isValidForm = false;
        }
        if (!Number.isInteger(Number(formDuplicate.shapeQuantity))) {
          v.shapeQuantity.errMsg = "荷姿数量は整数で入力してください。";
          isValidForm = false;
        }
      }
      v.routeContactNoList.forEach((contactNo, index) => {
        contactNo.errMsg = ""
        var routeContactNo = formDuplicate.routeContactNoList[index];
        if (encodeURIComponent(routeContactNo.contactNo).replace(/%../g, "x").length > 20) {
          contactNo.errMsg = "連絡番号は20桁以内で入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(routeContactNo.contactNo)) {
          contactNo.errMsg = "連絡番号は半角英数記号で入力してください。";
          isValidForm = false;
        }
      });
      v.routeRemarksList.forEach((routeRemark, index) => {
        routeRemark.errMsg = ""
        var remark = formDuplicate.routeRemarksList[index];
        if (remark.remarks) {
          if (!this.isValidByteLength(remark.remarks, 50)) {
            routeRemark.errMsg = "備考は50バイト以内で入力してください。";
            isValidForm = false;
          } else if (
            PROHIBITED_CHARACTERS_REGEX.test(remark.remarks) ||
            !this.isContainValidCharacter(remark.remarks)
          ) {
            routeRemark.errMsg = "登録できない文字が含まれています。";
            isValidForm = false;
          }
        }
      });
      v.labelList.forEach((label, index) => {
        if (!label.labelHeader.isValid) {
          label.labelHeader.errMsg = "ノートヘッダーは必須入力です。";
          isValidForm = false;
        }
        var labelDuplicate = formDuplicate.labelList[index];
        if (labelDuplicate.labelTypeId == 2) {
          if (!numberRegexp.test(labelDuplicate.labelDefaultValue)) {
            label.labelDefaultValue.errMsg = "数値を選択している場合、ノート初期値は数値で入力してください。";
            isValidForm = false;
          }
        }
        if (labelDuplicate.labelHeader != null) {
          if (labelDuplicate.labelHeader.length > 50) {
            label.labelHeader.errMsg = "ノートヘッダーは50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (labelDuplicate.labelDefaultValue != null) {
          if (labelDuplicate.labelDefaultValue.length > 50) {
            label.labelDefaultValue.errMsg = "ノート初期値は50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (labelDuplicate.labelUnit != null) {
          if (labelDuplicate.labelUnit.length > 20) {
            label.labelUnit.errMsg = "ノート単位は20桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (labelDuplicate.labelTypeId != 1 && labelDuplicate.labelTypeId != 2) {
          label.labelDefaultValue.errMsg = "テキストか数値を選択してください。";
          isValidForm = false;
        }

      });
      v.newLabelList.forEach((newLabel, index) => {
        if (!newLabel.labelHeader.isValid) {
          newLabel.labelHeader.errMsg = "ノートヘッダーは必須入力です。";
          isValidForm = false;
        }
        var newLabelDuplicate = newLabelList[index];
        if (newLabelDuplicate.labelTypeId == 2) {
          if (!numberRegexp.test(newLabelDuplicate.labelDefaultValue)) {
            newLabel.labelDefaultValue.errMsg = "数値を選択している場合、ノート初期値は数値で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelDuplicate.labelHeader != null) {
          if (newLabelDuplicate.labelHeader.length > 50) {
            newLabel.labelHeader.errMsg = "ノートヘッダーは50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelDuplicate.labelDefaultValue != null) {
          if (newLabelDuplicate.labelDefaultValue.length > 50) {
            newLabel.labelDefaultValue.errMsg = "ノート初期値は50桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelDuplicate.labelUnit != null) {
          if (newLabelDuplicate.labelUnit.length > 20) {
            newLabel.labelUnit.errMsg = "ノート単位は20桁以内で入力してください。";
            isValidForm = false;
          }
        }
        if (newLabelDuplicate.labelTypeId != 1 && newLabelDuplicate.labelTypeId != 2) {
          newLabel.labelDefaultValue.errMsg = "テキストか数値を選択してください。";
          isValidForm = false;
        }
      });

      if (formDuplicate.registMemberName) {
        if (formDuplicate.registMemberName.length > 12) {
          v.registMemberName.errMsg = "登録担当者は最大12文字で入力してください。";
          isValidForm = false;
        }
      };

      if (formDuplicate.generateMemberName) {
        if (formDuplicate.generateMemberName.length > 12) {
          v.generateMemberName.errMsg = "引き渡し担当者は最大12文字で入力してください。";
          isValidForm = false;
        }
      };

      // 予約/報告通知先email
      v.notiMailList.forEach((mail, index) => {
        mail.errMsg = ""
        if (formDuplicate.notiMailList[index] && !mail.isValid) {
          mail.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else if (formDuplicate.notiMailList[index] && [...formDuplicate.notiMailList.slice(0, index), ...formDuplicate.notiMailList.slice(index + 1)].some(mail => mail === formDuplicate.notiMailList[index])) {
          mail.errMsg = "予約/報告通知先emailは重複しないように設定してください。";
          isValidForm = false;
        }
      })

      return isValidForm;
    },

    runValidate(formDuplicate, newLabelList) {
      this.validate.collectUnitPriceList.forEach((collectUnitPrice) => {
        collectUnitPrice.errMsg = ""
      });
      this.validate.disposalUnitPrice = { isValid: true, errMsg: "" };
      let isValidForm = false;
      isValidForm = this.check(formDuplicate, newLabelList);

      return isValidForm;
    },
  },
};
