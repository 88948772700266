<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="ルート詳細" v-if="isDisplaySideMenu" />
    <div v-else class="l-header-another-tab">
      <div class="l-header__inner-another-tab">
        <div>
          <h1 class="l-header__title">ルート詳細</h1>
        </div>
      </div>
    </div>

    <AppSideMenu v-if="isDisplaySideMenu" />
    <main
      id="main"
      :class="{
        'l-main': isDisplaySideMenu,
        'l-main-another-tab': !isDisplaySideMenu,
      }"
    >
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <ToastMessage :toastMsg.sync="toastMsg" />
        <template v-if="isGenerator || isCba || isCollectProxy">
          <div class="l-flex l-item">
            <div class="c-collectorText">
              <span>お気に入り</span>
              <div
                v-if="routeInfo.isFavorite"
                @click="toggleFavorite()"
                class="c-btn fav on"
              />
              <div v-else @click="toggleFavorite()" class="c-btn fav off" />
            </div>
          </div>
        </template>
        <!--基本情報-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">基本情報</h3>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有効期間</label>
            </div>
            <div class="c-inputWrap__items">
              <span>{{ routeInfo.activeStartDate | dateFormat }}</span>
              <span>~</span>
              <span v-if="routeInfo.activeEndDate">{{
                routeInfo.activeEndDate | dateFormat
              }}</span>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>ルート番号</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.routeId | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>ルート名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.routeName | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>排出事業場名</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.generateStoreInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>事業場任意設定</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.isChangeStore ? "有" : "無" | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物区分</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.wasteTypeInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>マニフェスト区分</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.manifestTypeInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.jwnetItemTypeInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>CBA品目</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.cbaItemInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>構成種類</label>
            </div>
            <div class="c-inputWrap__items">
              {{ listItem(routeInfo.constitutionItemInfo, "name") | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>廃棄物名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.wasteNameInfo.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>詳細名称</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.detailWasteName | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>予定数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.plansQuantity | formatFloat }}
              {{
                routeInfo.plansWasteUnitType
                  ? routeInfo.plansWasteUnitType.name
                  : ""
              }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>上限数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.limitQuantity | formatFloat }}
              {{
                routeInfo.limitWasteUnitType
                  ? routeInfo.limitWasteUnitType.name
                  : ""
              }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>数量確定者</label>
            </div>
            <div
              v-if="routeInfo.quantityConfirmBusinessType"
              class="c-inputWrap__items"
            >
              {{ routeInfo.quantityConfirmBusinessType.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>荷姿</label>
            </div>
            <div v-if="routeInfo.shapeType" class="c-inputWrap__items">
              {{ routeInfo.shapeType.name | blankText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>荷姿数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.shapeQuantity | blankText }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分方法</label>
            </div>
            <div v-if="routeInfo.disposalWayType" class="c-inputWrap__items">
              {{ routeInfo.disposalWayType.name | blankText }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有害物質情報</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                v-for="harmfulType of routeInfo.harmfulInfo"
                :key="'harmfulType-' + harmfulType.id"
              >
                {{ harmfulType.name }}
              </div>
              <div v-if="routeInfo.harmfulInfo.length === 0">-</div>
            </div>
          </div>

          <!--運搬単価-->
          <div
            v-for="(collectUnitPrice, index) in routeInfo.collectUnitPriceList"
            :key="'collectUnitPrice-' + index"
            class="c-inputWrap"
          >
            <div class="c-inputWrap__label">
              <label> 運搬単価（区間{{ collectUnitPrice.sectionNo }}） </label>
            </div>
            <div class="c-inputWrap__items">
              {{ collectUnitPrice.collectUnitPrice | moneyText }}
              {{
                collectUnitPrice.collectPriceWasteUnitType
                  ? "/ " + collectUnitPrice.collectPriceWasteUnitType.name
                  : ""
              }}
            </div>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分単価</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.disposalUnitPrice | moneyText }}
              {{
                routeInfo.disposalPriceWasteUnitType
                  ? "/ " + routeInfo.disposalPriceWasteUnitType.name
                  : ""
              }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>有価単価</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.valuablesUnitPrice | moneyText }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>トン換算係数</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                {{
                  routeInfo.conversionFactorKg
                    ? routeInfo.conversionFactorKg
                    : "-" | formatFloat
                }}
                / kg
              </div>
              <div>
                {{
                  routeInfo.conversionFactorM3
                    ? routeInfo.conversionFactorM3
                    : "-" | formatFloat
                }}
                / m3
              </div>
              <div>
                {{
                  routeInfo.conversionFactorLiter
                    ? routeInfo.conversionFactorLiter
                    : "-" | formatFloat
                }}
                / リットル
              </div>
              <div>
                {{
                  routeInfo.conversionFactorUnitNumber
                    ? routeInfo.conversionFactorUnitNumber
                    : "-" | formatFloat
                }}
                / 個・台
              </div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>運搬費用計算時の対象数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                routeInfo.collectTargetQuantityTypeInfo
                  ? routeInfo.collectTargetQuantityTypeInfo.name
                  : "" | blankText
              }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分費用計算時の対象数量</label>
            </div>
            <div class="c-inputWrap__items">
              {{
                routeInfo.disposalTargetQuantityTypeInfo
                  ? routeInfo.disposalTargetQuantityTypeInfo.name
                  : "" | blankText
              }}
            </div>
          </div>
          <div class="c-inputWrap" v-for="(contactNo, index) in routeInfo.contactNoInfo" :key="'contactNo-' + index">
            <div class="c-inputWrap__label">
              <label>連絡番号{{ index+1 }}</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                {{ contactNo.contactNo ? contactNo.contactNo : "-" }}
              </div>
              <div v-if="routeInfo.contactNoInfo.length == 0">-</div>
            </div>
          </div>
          <div class="c-inputWrap" v-for="(remark, index) in routeInfo.remarksInfo" :key="'remark-' + index">
            <div class="c-inputWrap__label">
              <label>備考{{ index+1 }}</label>
            </div>
            <div class="c-inputWrap__items">
              <div>
                {{ remark.remarks ? remark.remarks : "-"}}
              </div>
              <div v-if="routeInfo.remarksInfo.length == 0">-</div>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>WDS</label>
            </div>
            <div v-if="routeInfo.wdsFileName" class="c-inputWrap__items">
              <a
                @click="downloadWDS(routeInfo.routeId, routeInfo.wdsFileName)"
                class="c-text text-link c-entrustText__text"
              >
                {{ routeInfo.wdsFileName }}
              </a>
            </div>
            <div v-else class="c-inputWrap__items">-</div>
          </div>
        </div>
        <!--基本情報ここまで-->
        <!--運搬事業者情報-->
        <div
          v-for="(collector, index) in routeInfo.routeCollectCompanyInfo"
          :key="'collector-' + index"
        >
          <div class="l-contents l-box">
            <div class="l-box">
              <h3 class="c-lead icon">運搬事業者情報{{ index + 1 }}</h3>
            </div>
            <!--運搬事業者が有効の場合-->
            <template v-if="collector.collectCompanyInfo.isActive">
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>事業者名</label>
                </div>
                <div class="c-inputWrap__items">
                  {{ collector.collectCompanyInfo.name | blankText }}
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬事業所名</label>
                </div>
                <div
                  class="c-inputWrap__items"
                  v-if="collector.collectFactoryInfo.isActive"
                >
                  {{ collector.collectFactoryInfo.name | blankText }}<br />
                  JWNETID(加入者番号)：{{
                    collector.collectFactoryInfo.jwnetId | blankText
                  }}
                </div>
                <div class="c-inputWrap__items" v-else>-</div>
              </div>
              <!-- 産業・有価廃棄物の許可書情報 -->
              <template
                v-if="routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE"
              >
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可自治体</label>
                  </div>
                  <div class="c-inputWrap__items">
                    積み込み場所 :
                    {{
                      listItem(collector.loadingPlaceList, "municipalityName")
                        | blankText
                    }}<br />
                    荷下ろし場所 :
                    {{
                      listItem(collector.unloadingPlaceList, "municipalityName")
                        | blankText
                    }}
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    積み込み場所 :
                    <span
                      v-for="loadingLicence of collector.loadingPlaceList"
                      :key="'loading-licence-' + loadingLicence.licenceNo"
                    >
                      {{ loadingLicence.licenceNo }}
                      {{
                        loadingLicence.licenceExpiredDate | dateJpYMDFormat
                      }}まで<br />
                    </span>
                    <span v-if="collector.loadingPlaceList.length == 0">-</span
                    ><br v-if="collector.loadingPlaceList.length == 0" />
                    荷下ろし場所 :
                    <span
                      v-for="unloadingLicence of collector.unloadingPlaceList"
                      :key="'unloading-licence-' + unloadingLicence.licenceNo"
                    >
                      {{ unloadingLicence.licenceNo }}
                      {{
                        unloadingLicence.licenceExpiredDate | dateJpYMDFormat
                      }}まで<br />
                    </span>
                    <span v-if="collector.unloadingPlaceList.length == 0"
                      >-</span
                    >
                  </div>
                </div>
              </template>
              <!-- 一般廃棄物の許可書情報 -->
              <template v-else>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可市区町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      listItem(collector.commonLicenceList, "cityName")
                        | blankText
                    }}
                  </div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <span
                      v-for="commonLicence of collector.commonLicenceList"
                      :key="'common-licence-' + commonLicence.licenceNo"
                    >
                      {{ commonLicence.licenceNo }}
                      {{ commonLicence.licenceExpiredDate | dateJpYMDFormat
                      }}<br />
                    </span>
                    <span v-if="collector.commonLicenceList.length == 0">-</span
                    ><br v-if="collector.commonLicenceList.length == 0" />
                  </div>
                </div>
              </template>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先名</label>
                </div>
                <div
                  class="c-inputWrap__items"
                  v-if="collector.destinationInfo.isActive"
                >
                  {{ collector.destinationInfo.name | blankText }}<br />
                  JWNETID(加入者番号)：{{
                    collector.destinationInfo.jwnetId | blankText
                  }}<br />
                  事業場コード：{{
                    collector.destinationInfo.storeCode | blankText
                  }}
                </div>
                <div class="c-inputWrap__items" v-else>-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先住所</label>
                </div>
                <div
                  class="c-inputWrap__items"
                  v-if="collector.destinationInfo.isActive"
                >
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.zipCode
                      : "" | zipCodeText
                  }}
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.prefecturesName
                      : ""
                  }}
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.address
                      : ""
                  }}
                  {{
                    collector.destinationInfo.addressInfo
                      ? collector.destinationInfo.addressInfo.buildingName
                      : ""
                  }}
                </div>
                <!-- FIXME 1区間の場合、処分事業者の有効フラグを見る必要：確認必要 => 確認済み：自体事業場の有効フラグのみで良い-->
                <div class="c-inputWrap__items" v-else>-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>{{
                    routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE
                      ? "積替保管可能品目"
                      : "対象品目"
                  }}</label>
                </div>
                <div class="c-inputWrap__items">
                  {{
                    listItem(collector.transshipmentJwnetItem, "name")
                      | blankText
                  }}
                </div>
              </div>
            </template>
            <!--運搬事業者が無効化されたら、全項目は「-」で表示-->
            <template v-else>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>事業者名</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬事業所名</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
              <!-- 産業・有価廃棄物の許可書情報 -->
              <template
                v-if="routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE"
              >
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可自治体</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>
              </template>
              <!-- 一般廃棄物の許可書情報 -->
              <template v-else>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可市区町村</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>

                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>許可番号</label>
                  </div>
                  <div class="c-inputWrap__items">-</div>
                </div>
              </template>

              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先名</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>運搬先住所</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__label">
                  <label>{{
                    routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE
                      ? "積替保管可能品目"
                      : "対象品目"
                  }}</label>
                </div>
                <div class="c-inputWrap__items">-</div>
              </div>
            </template>
          </div>
        </div>
        <!--運搬事業者情報ここまで-->
        <!--処分事業者情報-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">処分事業者情報</h3>
          </div>
          <!--処分事業者が有効の場合-->
          <template
            v-if="
              routeInfo.routeDisposalCompanyInfo.disposalCompanyInfo.isActive
            "
          >
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  routeInfo.routeDisposalCompanyInfo.disposalCompanyInfo.name
                    | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>{{
                  routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE
                    ? "許可自治体"
                    : "許可市区町村"
                }}</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  listItem(
                    routeInfo.routeDisposalCompanyInfo.disposalLicenceInfo,
                    "municipalityName"
                  ) | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可番号</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  listItem(
                    routeInfo.routeDisposalCompanyInfo.disposalLicenceInfo,
                    "licenceNo"
                  ) | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証期限</label>
              </div>
              <div class="c-inputWrap__items">
                <span
                  v-for="licence of licenceExpiredDate"
                  v-bind:key="licence"
                >
                  {{ licence }} <br />
                </span>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場</label>
              </div>
              <div
                class="c-inputWrap__items"
                v-if="
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.isActive
                "
              >
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.name
                    | blankText
                }}
              </div>
              <div class="c-inputWrap__items" v-else>-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場住所</label>
              </div>
              <div
                class="c-inputWrap__items"
                v-if="
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.isActive
                "
              >
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.zipCode
                    : "" | zipCodeText
                }}
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.prefecturesName
                    : ""
                }}
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.address
                    : ""
                }}
                {{
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.addressInfo
                    ? routeInfo.routeDisposalCompanyInfo.destinationInfo
                        .addressInfo.buildingName
                    : ""
                }}
              </div>
              <div class="c-inputWrap__items" v-else>-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>対象品目</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  listItem(
                    routeInfo.routeDisposalCompanyInfo.disposalJwnetItem,
                    "name"
                  ) | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>最終処分場</label>
              </div>
              <div
                class="c-inputWrap__items"
                v-if="
                  routeInfo.routeDisposalCompanyInfo.destinationInfo.isActive
                "
              >
                <div
                  class="c-accordion"
                  v-for="finalDisposalSiteList in routeInfo.finalDisposalSiteList"
                  :key="
                    'finalDisposalSiteList-' +
                    finalDisposalSiteList.finalDisposalSiteId
                  "
                >
                  <input
                    :id="
                      'finalDisposalSiteList-acc-' +
                      finalDisposalSiteList.finalDisposalSiteId
                    "
                    class="c-accordion__cb"
                    type="checkbox"
                  />
                  <div class="c-accordion__head">
                    <label
                      class="c-accordion__head__lbl"
                      :for="
                        'finalDisposalSiteList-acc-' +
                        finalDisposalSiteList.finalDisposalSiteId
                      "
                    ></label>
                    <div class="c-accordion__head__text">
                      {{ finalDisposalSiteList.finalDisposalSiteName }}
                    </div>
                  </div>
                  <div class="c-accordion__body">
                    <div
                      class="c-infoText"
                      :key="'item-' + finalDisposalSiteList.finalDisposalSiteId"
                    >
                      <div class="c-dl">
                        <dt class="md">住所</dt>
                        <dd>
                          {{
                            finalDisposalSiteList.addressInfo.prefecturesName
                          }}
                          {{ finalDisposalSiteList.addressInfo.address }}
                          {{ finalDisposalSiteList.addressInfo.buildingName }}
                        </dd>
                      </div>
                      <div class="c-dl">
                        <dt class="md">電話番号</dt>
                        <dd>{{ finalDisposalSiteList.tel | blankText }}</dd>
                      </div>
                      <div class="c-dl">
                        <dt class="md">事業場コード</dt>
                        <dd>
                          {{
                            finalDisposalSiteList.finalDisposalStoreCode
                              | blankText
                          }}
                        </dd>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap__items" v-else>-</div>
            </div>
          </template>

          <!--処分事業者情報が無効化されたら、全項目は「-」で表示-->
          <template v-else>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>{{
                  routeInfo.wasteTypeInfo.id !== wasteType.COMMON_WASTE
                    ? "許可自治体"
                    : "許可市区町村"
                }}</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可番号</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>許可証期限</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>処分場住所</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>対象品目</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>最終処分場</label>
              </div>
              <div class="c-inputWrap__items">-</div>
            </div>
          </template>
        </div>
        <!--処分事業者情報ここまで-->

        <!-- 登録者情報 -->
        <div class="l-contents l-box">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">担当者情報</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>登録担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  routeInfo.registMemberName
                      ? routeInfo.registMemberName
                      : "" | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>引き渡し担当者</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  routeInfo.generateMemberName
                      ? routeInfo.generateMemberName
                      : "" | blankText
                }}
              </div>
            </div>
          </div>
        </div>

        <div class="l-contents l-box" v-if="!isDisposer">
          <div class="l-box title-wrap">
            <h3 class="c-lead icon">予約/報告通知先</h3>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>予約/報告通知先mail1</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  routeInfo.notiMail1
                      ? routeInfo.notiMail1
                      : "" | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>予約/報告通知先mail2</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  routeInfo.notiMail2
                      ? routeInfo.notiMail2
                      : "" | blankText
                }}
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>予約/報告通知先mail3</label>
              </div>
              <div class="c-inputWrap__items">
                {{
                  routeInfo.notiMail3
                      ? routeInfo.notiMail3
                      : "" | blankText
                }}
              </div>
            </div>
          </div>
        </div>

        <!-- 受渡確認票 / 一括登録通知配布先 -->
        <div class="l-contents l-box">
          <div class="c-inputWrap">
            <div class="c-checksheetinfo__detail u-w100">
              <div class="c-accordion">
                <input
                  id="routeDeliveryConfirmation"
                  class="c-accordion__cb"
                  type="checkbox"
                />
                <div class="c-accordion__head-noborder">
                  <div class="l-box title-wrap u-flex u-mgb0">
                    <h3 class="c-lead icon">受渡確認票 / 一括登録通知配布先</h3>
                    <label
                      class="c-accordion__head__lbl u-mgr0"
                      for="routeDeliveryConfirmation"
                    ></label>
                  </div>
                </div>
                <div class="c-accordion__body u-mgt30">
                  <div class="u-w100 u-mgl0">
                    <div class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>運搬事業者がJWNETで登録/wellfestで登録</label>
                      </div>
                      <div class="c-inputWrap__items">
                        <div v-if="routeInfo.routeDeliveryConfirmation.collectorRegistType == collectorRegistType.WELLFEST.value"><p>{{collectorRegistType.WELLFEST.name}}</p></div>
                        <div v-else><p>{{collectorRegistType.JWNET.name}}</p></div>
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.generateDeliveryConfirmMail" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email（排出）</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.generateDeliveryConfirmMail }}
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.collectDeliveryConfirmMail" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email（運搬）</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.collectDeliveryConfirmMail }}
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.disposalDeliveryConfirmMail" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email（処分）</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.disposalDeliveryConfirmMail }}
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.anyMail1" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email1</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.anyMail1 }}
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.anyMail2" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email2</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.anyMail2 }}
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.anyMail3" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email3</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.anyMail3 }}
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.anyMail4" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email4</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.anyMail4 }}
                      </div>
                    </div>
                    <div v-if="routeInfo.routeDeliveryConfirmation.anyMail5" class="c-inputWrap">
                      <div class="c-inputWrap__label">
                        <label>送付先email5</label>
                      </div>
                      <div class="c-inputWrap__items">
                        {{ routeInfo.routeDeliveryConfirmation.anyMail5 }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--CE情報-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">CE情報</h3>
          </div>
          <template v-if="routeInfo.circularEconomyInfo">
            <div class="c-accordion">
              <input id="ce-info-acc" class="c-accordion__cb" type="checkbox" />
              <div class="c-accordion__head">
                <label class="c-accordion__head__lbl" for="ce-info-acc"></label>
                <div class="c-accordion__head__text">詳細</div>
              </div>
              <div class="c-accordion__body for-form">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>マテリアルリサイクル率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.materialRecyclingRate !=
                      null
                        ? routeInfo.circularEconomyInfo.materialRecyclingRate +
                          "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>ケミカルリサイクル率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.chemicalRecyclingRate !=
                      null
                        ? routeInfo.circularEconomyInfo.chemicalRecyclingRate +
                          "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>サーマルリサイクル（熱回収）率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.heatRecoveryRate != null
                        ? routeInfo.circularEconomyInfo.heatRecoveryRate + "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>熱回収効率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.heatRecoveryEfficiency !=
                      null
                        ? routeInfo.circularEconomyInfo.heatRecoveryEfficiency +
                          "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>その他リサイクル率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.otherRecyclingRate != null ? routeInfo.circularEconomyInfo.otherRecyclingRate + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>最終処分率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.finalDisposalRate != null
                        ? routeInfo.circularEconomyInfo.finalDisposalRate + "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>埋立率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.landfillRate != null ? routeInfo.circularEconomyInfo.landfillRate + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>焼却率</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.incinerateRate != null ? routeInfo.circularEconomyInfo.incinerateRate + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>CO2排出係数（運搬）</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.co2EmmisionFactorCollect != null ? routeInfo.circularEconomyInfo.co2EmmisionFactorCollect + 'kg-CO2/kg' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>CO2排出係数（処分）</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.co2EmmisionFactorDisposal != null ? routeInfo.circularEconomyInfo.co2EmmisionFactorDisposal + 'kg-CO2/kg' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>１）排出量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.massWasteQuantity != null ? routeInfo.circularEconomyInfo.massWasteQuantity + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>２）自ら直接再生利用（CE）</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.selfRecycling != null
                        ? routeInfo.circularEconomyInfo.selfRecycling + "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>３）自ら直接埋立処分（CE）</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.selfLanfillDisposal != null
                        ? routeInfo.circularEconomyInfo.selfLanfillDisposal +
                          "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>４）自ら中間処理（CE）</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.selfDisposal != null
                        ? routeInfo.circularEconomyInfo.selfDisposal + "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>５）４）のうち熱回収行った量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.selfHeatRecovery != null ? routeInfo.circularEconomyInfo.selfHeatRecovery + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>６）自ら中間処理した後の残さ量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.selfDisposalResidue != null ? routeInfo.circularEconomyInfo.selfDisposalResidue + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>７）自ら中間処理により減量した量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.selfDisposalLoss != null ? routeInfo.circularEconomyInfo.selfDisposalLoss + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>８）自ら中間処理後再生利用（CE）</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.selfDisposalRecycling !=
                      null
                        ? routeInfo.circularEconomyInfo.selfDisposalRecycling +
                          "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>９）自ら中間処理後自ら埋立処分又は海洋投入（CE）</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo
                        .selfDisposalLanfillOceandump != null
                        ? routeInfo.circularEconomyInfo
                            .selfDisposalLanfillOceandump + "%"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>１０）直接及び自ら中間処理した後の処理委託量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.selfDisposalOutsource != null ? routeInfo.circularEconomyInfo.selfDisposalOutsource + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>１１）１０）のうち優良認定処理業者への処理委託量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.disposalExOutsource != null ? routeInfo.circularEconomyInfo.disposalExOutsource + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>１２）１０）のうち再生利用業者への処理委託量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.recycleOutsource != null ? routeInfo.circularEconomyInfo.recycleOutsource + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>１３）１０）のうち熱回収認定業者への処理委託量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.thermalOutsourceCertified != null ? routeInfo.circularEconomyInfo.thermalOutsourceCertified + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>１４）１０）のうち熱回収認定業者以外の熱回収を行う業者への処理委託量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.thermalOutsource != null ? routeInfo.circularEconomyInfo.thermalOutsource + '%' : '-' }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>車両コード</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.vehicleCode | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>燃料</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{ routeInfo.circularEconomyInfo.fuelType | blankText }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>最大積載量</label>
                  </div>
                  <div class="c-inputWrap__items">
                    {{
                      routeInfo.circularEconomyInfo.maxLoadingCapacity != null
                        ? routeInfo.circularEconomyInfo.maxLoadingCapacity +
                          "kg"
                        : "-"
                    }}
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>軽自動車フラグ</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <span v-if="routeInfo.circularEconomyInfo.smallVehicle"
                      >軽</span
                    >
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!--CE情報ここまで-->

        <!--ラベル-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">ノート</h3>
          </div>
          <div
            v-for="label of routeInfo.labelInfo"
            :key="'label-' + label.labelId"
            class="c-inputWrap"
          >
            <div class="c-inputWrap__label">
              <label>{{ label.labelHeader }}</label>
            </div>
            <div class="c-inputWrap__items">
              {{ label.labelDefaultValue }}
              {{ label.labelUnit }}
            </div>
          </div>
        </div>
        <!--ラベルここまで-->
        <!--自動設定-->
        <div class="l-contents l-box">
          <div class="l-box">
            <h3 class="c-lead icon">自動設定</h3>
          </div>

          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>代理登録の自動承認</label>
            </div>
            <div class="c-inputWrap__items">
              <span v-if="routeInfo.isAutoApproval">有</span>
              <span v-else>無</span>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>引き渡し日以降の自動交付</label>
            </div>
            <div class="c-inputWrap__items">
              <div
                v-if="routeInfo.isAutoIssueAfterCollectionDate"
                class="c-inputWrap__items__cols"
              >
                <span> 有</span>
                <div>
                  {{ routeInfo.autoIssueExecDateType.name }}
                  {{ routeInfo.autoIssueExecTime }}
                </div>
              </div>
              <span v-else>無</span>
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>一廃/有価の運搬終了報告要否</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.isNecessityCommonCollectReport ? "要" : "否" }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>運搬終了報告通知</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.isCollectReportAlert ? "有" : "無" }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>終了報告後自動交付</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.isAutoIssue ? "有" : "無" }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>処分終了報告通知</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.isDisposalReportAlert ? "有" : "無" }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>予約マニフェスト番号取得通知</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.isManifestNoRegistAlert ? "有" : "無" }}
            </div>
          </div>
          <div class="c-inputWrap">
            <div class="c-inputWrap__label">
              <label>交付期限通知</label>
            </div>
            <div class="c-inputWrap__items">
              {{ routeInfo.isIssueDeadlineAlert ? "有" : "無" }}
            </div>
          </div>
        </div>
        <!--自動設定ここまで-->
      </div>

      <FloatButtons v-if="isDisplaySideMenu">
        <div class="left">
          <router-link to="/route" class="c-btn secondary small back"
            >一覧へ戻る</router-link
          >
        </div>
        <div class="center">
          <div
            v-if="routeInfo.isActive"
            @click="toggleDeactive()"
            class="c-btn secondary small"
          >
            無効化
          </div>
          <div v-else @click="toggleActive()" class="c-btn secondary small">
            有効化
          </div>
          <template v-if="!isCollector && !isDisposer">
            <div
              class="c-btn secondary small"
              @click="
                $router.push(`/route/duplicate?routeId=${$route.params.id}`)
              "
            >
              複製
            </div>
            <div
              class="c-btn secondary small"
              @click="$router.push(`/route/edit/${$route.params.id}`)"
            >
              修正
            </div>
          </template>
        </div>
      </FloatButtons>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";

import { API_URL, DOMAIN } from "@/const";

import { saveAs } from "file-saver";
import callApi from "./mixins/callApi";
import ToastMessage from "@/components/parts/ToastMessage";
import FloatButtons from "@/components/layout/FloatButtons";
import { dateJpYMDFormat } from "@/filter/index";
import {COLLECTOR_REGIST_TYPE} from "@/const/index";
export default {
  name: "route-detail",
  components: { FloatButtons, ToastMessage },
  mixins: [callApi],
  data() {
    return {
      errMsgs: [],
      toastMsg: "",
      wasteType: DOMAIN.WASTE_TYPE,
      isDisplaySideMenu: true,
      collectorRegistType: COLLECTOR_REGIST_TYPE,
    };
  },
  computed: {
    // {arg: "hoge"}の配列をhogeのみを抽出して"、"つなぎで返す
    listItem: function () {
      return function (objArray, arg) {
        const tempArray = [];
        for (const obj of objArray) {
          tempArray.push(obj[`${arg}`]);
        }
        return tempArray.join("、");
      };
    },
    licenceExpiredDate() {
      if (
        this.routeInfo.routeDisposalCompanyInfo.disposalLicenceInfo.length == 0
      ) {
        return "-";
      }

      return this.routeInfo.routeDisposalCompanyInfo.disposalLicenceInfo.map(
        (disposalLicenceInfo) => {
          var displayLicenceExpiredDate = "-";
          if (disposalLicenceInfo.licenceNo != null) {
            displayLicenceExpiredDate = `${
              disposalLicenceInfo.licenceNo
            } ${dateJpYMDFormat(disposalLicenceInfo.licenceExpiredDate)}まで`;
          }
          return displayLicenceExpiredDate;
        }
      );
    },
    isHighClass() {
      return this.$store.getters["userInfo/isHighClass"];
    },
    isCollectProxy() {
      return this.$store.getters["userInfo/isCollectProxy"];
    },
    isCollector() {
      return this.$store.getters["userInfo/isCollector"];
    },
    isCba() {
      return this.$store.getters["userInfo/isCba"];
    },
    isGenerator() {
      return this.$store.getters["userInfo/isGenerator"];
    },
    isDisposer() {
      return this.$store.getters["userInfo/isDisposer"];
    },
  },
  mounted() {
    this.routeDetailApi(this.$route.params.id);
    this.isDisplaySideMenu =
      this.$route.query.isDisplaySideMenu == "false" ? false : true;
  },
  methods: {
    toggleFavorite() {
      this.routeInfo.isFavorite = !this.routeInfo.isFavorite;
      axios.put(API_URL.ROUTE.FAVORITE + this.$route.params.id, {
        isFavorite: this.routeInfo.isFavorite,
      });
    },
    toggleActive() {
      axios
        .patch(API_URL.ROUTE.ACTIVATE, {
          isActive: true,
          targetIdList: [{ id: this.routeInfo.routeId }],
        })
        .then(() => {
          this.toastMsg = "有効化しました。";
          this.routeDetailApi(this.$route.params.id);
          setTimeout(() => {
            this.toastMsg = "";
          }, 5000);
        })
        .catch((err) => {
          this.errMsgs.push("ルートを有効化できませんでした");
          console.log(err);
        });
    },
    toggleDeactive() {
      axios
        .patch(API_URL.ROUTE.ACTIVATE, {
          isActive: false,
          targetIdList: [{ id: this.routeInfo.routeId }],
        })
        .then(() => {
          this.toastMsg = "無効化しました。";
          this.routeDetailApi(this.$route.params.id);
          setTimeout(() => {
            this.toastMsg = "";
          }, 5000);
        })
        .catch((err) => {
          this.errMsgs.push("ルートを無効化できませんでした");
          console.log(err);
        });
    },
    // WDSファイルダウンロード
    downloadWDS(wasteInfoId, wdsFileName) {
      axios
        .get(API_URL.MANIFEST.WDS_DOWNLOAD + wasteInfoId, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type,
          });
          saveAs(blob, wdsFileName);
        })
        .catch((err) => {
          console.log(err);
          if (err.response && err.response.status) {
            if (err.response.status == 403) {
              this.$router.push("/error/403");
            }
          }
        });
    },
  },
};
</script>
<style scoped lang="scss">
.u-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.u-w100 {
  width: 100%;
}

.u-mgl0 {
  margin-left: 0rem;
}

.u-mgb0 {
  margin-bottom: 0rem;
}

.u-mgt30 {
  margin-top: 3rem;
}

.u-mgr0 {
  margin-right: 0rem;
}
</style>
