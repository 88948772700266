import moment from "moment";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";
import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";

export default {
  data() {
    return {
      validate: {
        collectionDate: { isValid: true, errMsg: "" },
        collectionTime: { isValid: true, errMsg: "" },
        registMember: { isValid: true, errMsg: "" },
        generateMember: { isValid: true, errMsg: "" },
        jwnetItem: { isValid: true, errMsg: "" },
        route: { isValid: true, errMsg: "" },
        wasteName: { isValid: true, errMsg: "" },
        detailWasteName: { isValid: true, errMsg: "" },
        wasteQuantity: { isValid: true, errMsg: "" },
        wasteUnitTypeId: { isValid: true, errMsg: "" },
        quantityConfirmBusinessTypeId: { isValid: true, errMsg: "" },
        shapeTypeId: { isValid: true, errMsg: "" },
        shapeQuantity: { isValid: true, errMsg: "" },
        anyGenerateStoreName: { isValid: true, errMsg: "" },
        zipCode: { isValid: true, errMsg: "" },
        address: { isValid: true, errMsg: "" },
        prefecturesId: { isValid: true, errMsg: "" },
        contactNo: [{ isValid: true, errMsg: "" },{ isValid: true, errMsg: "" },{ isValid: true, errMsg: "" }],
        remarks: [{ isValid: true, errMsg: "" },{ isValid: true, errMsg: "" },{ isValid: true, errMsg: "" },{ isValid: true, errMsg: "" },{ isValid: true, errMsg: "" }],
        collectCost: [{ isValid: true, errMsg: "" }],
        disposalCost: [{ isValid: true, errMsg: "" }],
        valuablesCost: [{ isValid: true, errMsg: "" }],
        conversionFactorKg: { isValid: true, errMsg: "" },
        conversionFactorM3: { isValid: true, errMsg: "" },
        conversionFactorLiter: { isValid: true, errMsg: "" },
        conversionFactorUnitNumber: { isValid: true, errMsg: "" },
        buildingName: { isValid: true, errMsg: "" }
      },
    };
  },
  methods: {
    validatesCheck() {
      const v = this.validate;
      const w = this.formValue.wasteInfo;
      const m = this.formValue.manifestInfo;
      const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      let promise = null;

      if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length && !v.zipCode.isValid) {

        promise = new Promise((resolve) => {
          // 何かの処理
          v.zipCode.errMsg = "正しい値を入力してください。";
          resolve(false);
        });

      } else {
        if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode}`;
          promise = axios
              .get(url, {adapter: axiosJsonpAdapter})  // Promise型
              .then((res) => {
                let isValidForm = true;
                const results = res.data.results;
                if (results != null) {
                  if (v.prefecturesId.isValid) {
                    var prefectureFlg = false;
                    results.forEach((address) => {
                      const selectedPrefectureTemp = this.prefectures.find(p => p.id === this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId).name
                      if (selectedPrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    });
                    if (!prefectureFlg) {
                      v.prefecturesId.errMsg = "郵便番号と都道府県が一致していません。";
                      isValidForm = false;
                    }
                  }
                  if (v.address.isValid) {
                    var addressFlg = false;
                    results.forEach((address) => {
                      if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address === address.address2) {
                        addressFlg = true;
                      }
                    });
                    if (!addressFlg) {
                      v.address.errMsg = "郵便番号と市区町村が一致していません。";
                      isValidForm = false;
                    }
                  }
                }

                return isValidForm;
              })
              .catch((err) => {
                console.log(err);
              });
        } else {
          promise = new Promise((resolve) => {
            resolve(true);
          });
        }
      }

      promise = promise.then((prevIsValidForm) => {
        let isValidForm = prevIsValidForm;

        if (m.generateMember) {
          if (m.generateMember.name && m.generateMember.name.length > 12) {
            v.generateMember.errMsg = "12文字以内で入力してください。"
            isValidForm = false;
          }
        }
        if (m.registMember) {
          if (m.registMember.name && m.registMember.name.length > 12) {
            v.registMember.errMsg = "12文字以内で入力してください。"
            isValidForm = false;
          }
        }

        if (!v.collectionDate.isValid || !m.collectionDate) {
          v.collectionDate.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        const collectionDate = this.formValue.manifestInfo.collectionDate;
        if (this.routeDetailInfo
            && collectionDate
            && !this.routeDetailInfo.isEntrustUpdate
            && (collectionDate < this.routeDetailInfo.activeStartDate
                || (this.routeDetailInfo.activeEndDate && collectionDate > this.routeDetailInfo.activeEndDate))
        ){
          v.collectionDate.errMsg = "ルート利用期間外です。契約、ルートの有効期間を確認して下さい。";
          isValidForm = false;
        }

        if (!v.registMember.isValid) {
          v.registMember.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.generateMember.isValid) {
          v.generateMember.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.jwnetItem.isValid) {
          v.jwnetItem.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!this.formValue.manifestInfo.routeId) {
          v.route.errMsg = "正しい値を入力してください。";
          v.route.isValid = false;
          isValidForm = false;
        }
        if (!v.wasteName.isValid) {
          v.wasteName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (w.detailWasteName && !v.detailWasteName.isValid) {
          v.detailWasteName.errMsg = "詳細名称は30文字以内で入力してください。";
          isValidForm = false;
        }

        if (w.wasteQuantity) {
          if (!wasteQuantityRegexp.test(w.wasteQuantity)) {
            v.wasteQuantity.errMsg =
                "整数5桁、小数点1桁、小数部3桁で入力してください。";
            isValidForm = false;
          }
          if (w.wasteQuantity == 0.0) {
            v.wasteQuantity.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
        } else {
          v.wasteQuantity.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.wasteUnitTypeId.isValid) {
          v.wasteUnitTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.quantityConfirmBusinessTypeId.isValid) {
          v.quantityConfirmBusinessTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.shapeTypeId.isValid) {
          v.shapeTypeId.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (this.formValue.collectCost && !v.collectCost.isValid) {
          v.collectCost.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (this.formValue.disposalCost && !v.disposalCost.isValid) {
          v.disposalCost.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (this.formValue.valuablesCost && !v.valuablesCost.isValid) {
          v.valuablesCost.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (w.shapeQuantity) {
          if (String(w.shapeQuantity).length > 5) {
            v.shapeQuantity.errMsg =
                "荷姿数量は5桁以内の数値で入力してください。";
            isValidForm = false;
          }
          if (!Number.isInteger(Number(w.shapeQuantity))) {
            v.shapeQuantity.errMsg = "荷姿数量は整数で入力してください。";
            isValidForm = false;
          }
          if(Number(w.shapeQuantity) <= 0) {
            v.shapeQuantity.errMsg = "荷姿数量は0より大きい値を設定してください。";
            isValidForm = false;
          }
        }

        v.remarks.forEach((re, index) => {
          re.errMsg = ""
          const r = this.formValue.remarksInfo[index];
          if (r.detail) {
            if (!this.isValidByteLength(r.detail, 50)) {
              re.errMsg = "備考は50バイト以内で入力してください。";
              isValidForm = false;
            } else if (
              PROHIBITED_CHARACTERS_REGEX.test(r.detail) ||
              !this.isContainValidCharacter(r.detail)
            ) {
              re.errMsg = "登録できない文字が含まれています。";
              isValidForm = false;
            }
          }
        });
        if (v.contactNo) {
          v.contactNo.forEach((cv, index) => {
            cv.errMsg = ""
            const f = this.formValue.contactNoInfo[index];
            if (f.contactNo !== "") {
              if (f.contactNo.length && !cv.isValid) {
                cv.errMsg = "半角英数字で入力してください。";
                isValidForm = false;
              }
              if (!halfAlphaRegexp.test(f.contactNo)) {
                cv.errMsg = "半角英数字で入力してください。";
                isValidForm = false;
              }
              if (
                  encodeURIComponent(f.contactNo).replace(/%../g, "x").length > 20
              ) {
                cv.errMsg = "20バイト以内で入力してください。";
                isValidForm = false;
              }
            }
          });
        }

        isValidForm = this.validateAnyGenerateStore(isValidForm)
        return isValidForm;
      });

      return promise;
    },

    runValidate() {
      return this.validateCollectionDateForIssue() && this.validatesCheck();
    },

    // 半角は1Byte・全角2Byte文字を分けてカウントして、Byteに変換する(Byte数はShift-jis)
    countByteByString(string) {
      let byteSize = 0;
      // 半角・全角判定
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9/]*$/);

      // 文字列１つ１つを取得し、半角か全角か判定する
      for (let i = 0; i < string.length; i++) {
        let character = string.charAt(i);

        if (halfAlphaRegexp.test(character)){
          byteSize += 1;
        } else {
          byteSize += 2;
        }
      }
      return byteSize;
    },

    // 交付時に引き渡し日が未来日でエラーとする
    validateCollectionDateForIssue() {
      const today = moment();
      const collectionDate = moment(this.formValue.manifestInfo.collectionDate);
      if (collectionDate > today) {
        this.validate.collectionDate.errMsg =
          "引き渡し日は本日以前の日付を指定してください。";
        return false;
      }
      return true;
    },

    validatesCheckForSave() {
      const v = this.validate;
      const w = this.formValue.wasteInfo;
      const m = this.formValue.manifestInfo;
      const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      let promise = null;

      if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length && !v.zipCode.isValid) {

        promise = new Promise((resolve) => {
          // 何かの処理
          v.zipCode.errMsg = "正しい値を入力してください。";
          resolve(false);
        });

      } else {
        if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode}`;
          promise = axios
              .get(url, {adapter: axiosJsonpAdapter})  // Promise型
              .then((res) => {
                let isValidForm = true;
                const results = res.data.results;
                if (results != null) {
                  if (v.prefecturesId.isValid) {
                    var prefectureFlg = false;
                    results.forEach((address) => {
                      const selectedPrefectureTemp = this.prefectures.find(p => p.id === this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId).name
                      if (selectedPrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    });
                    if (!prefectureFlg) {
                      v.prefecturesId.errMsg = "郵便番号と都道府県が一致していません。";
                      isValidForm = false;
                    }
                  }
                  if (v.address.isValid) {
                    var addressFlg = false;
                    results.forEach((address) => {
                      if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address === address.address2) {
                        addressFlg = true;
                      }
                    });
                    if (!addressFlg) {
                      v.address.errMsg = "郵便番号と市区町村が一致していません。";
                      isValidForm = false;
                    }
                  }
                }

                return isValidForm;
              })
              .catch((err) => {
                console.log(err);
              });
        } else {
          promise = new Promise((resolve) => {
            resolve(true);
          });
        }
      }

      promise = promise.then((prevIsValidForm) => {
        let isValidForm = prevIsValidForm;
        if (m.generateMember) {
          if (m.generateMember.name && m.generateMember.name.length > 12) {
            v.generateMember.errMsg = "12文字以内で入力してください。"
            isValidForm = false;
          }
        }
        if (m.registMember) {
          if (m.registMember.name && m.registMember.name.length > 12) {
            v.registMember.errMsg = "12文字以内で入力してください。"
            isValidForm = false;
          }
        }

        if (w.wasteQuantity) {
          if (!wasteQuantityRegexp.test(w.wasteQuantity)) {
            v.wasteQuantity.errMsg = "整数5桁、小数点1桁、小数部3桁で入力してください。";
            isValidForm = false;
          }
          if (w.wasteQuantity == 0.0) {
            v.wasteQuantity.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
        }
        if ((w.wasteQuantity && !w.wasteUnitTypeId) || (!w.wasteQuantity && w.wasteUnitTypeId)) {
          v.wasteUnitTypeId.errMsg = "廃棄物数量の数量、単位いずれかのみの設定はできません。未設定もしくは、両方の設定をしてください。";
          isValidForm = false;
        }
        if (this.formValue.collectCost && !v.collectCost.isValid) {
          v.collectCost.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }

        if (this.formValue.disposalCost && !v.disposalCost.isValid) {
          v.disposalCost.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (this.formValue.valuablesCost && !v.valuablesCost.isValid) {
          v.valuablesCost.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (w.shapeQuantity) {
          if (String(w.shapeQuantity).length > 5) {
            v.shapeQuantity.errMsg =
                "荷姿数量は5桁以内の数値で入力してください。";
            isValidForm = false;
          }
          if (!Number.isInteger(Number(w.shapeQuantity))) {
            v.shapeQuantity.errMsg = "荷姿数量は整数で入力してください。";
            isValidForm = false;
          }
          if(Number(w.shapeQuantity) <= 0) {
            v.shapeQuantity.errMsg = "荷姿数量は0より大きい値を設定してください。";
            isValidForm = false;
          }
        }
        v.remarks.map((re, index) => {
          const r = this.formValue.remarksInfo[index];
          if (r.detail) {
            if (!this.isValidByteLength(r.detail, 50)) {
              re.errMsg = "備考は50文字以内で入力してください。";
              isValidForm = false;
            } else if (
              PROHIBITED_CHARACTERS_REGEX.test(r.detail) ||
              !this.isContainValidCharacter(r.detail)
            ) {
              re.errMsg = "登録できない文字が含まれています。";
              isValidForm = false;
            }
          }
        });
        if (v.contactNo) {
          v.contactNo.map((cv, index) => {
            const f = this.formValue.contactNoInfo[index];
            if (f.contactNo !== "") {
              if (f.contactNo.length && !cv.isValid) {
                cv.errMsg = "半角英数字で入力してください。";
                isValidForm = false;
              }
              if (!halfAlphaRegexp.test(f.contactNo)) {
                cv.errMsg = "半角英数字で入力してください。";
                isValidForm = false;
              }
              if (
                  encodeURIComponent(f.contactNo).replace(/%../g, "x").length > 20
              ) {
                cv.errMsg = "20バイト以内で入力してください。";
                isValidForm = false;
              }
            }
          });
        }
        if (w.detailWasteName && !v.detailWasteName.isValid) {
          v.detailWasteName.errMsg = "詳細名称は30文字以内で入力してください。";
          isValidForm = false;
        }

       isValidForm = this.validateAnyGenerateStore(isValidForm)

        return isValidForm;
      });


      return promise;
    },

    runValidateForSave() {
      return this.validatesCheckForSave();
    },

    validateAnyGenerateStore(prevIsValidForm) {
      const v = this.validate;
      let isValidForm = prevIsValidForm;
      // 画面側で、任意事業場の項目に１つでも入力値があると、「事業場名」、「郵便番号」、「都道府県」、「市区町村」は、入力しないとエラーとする
      if (this.formValue.anyGenerateStoreInfo.anyGenerateStoreName.length
        || this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length
        || this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId.length
        || this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length
        || this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length
      ) {
      if (!v.anyGenerateStoreName.isValid) {
        v.anyGenerateStoreName.errMsg = "60文字以内で入力してください。";
        isValidForm = false;
      }
      if (!this.formValue.anyGenerateStoreInfo.anyGenerateStoreName.length) {
        v.anyGenerateStoreName.errMsg = "任意事業場を設定する場合は必須入力になります。";
        isValidForm = false;
      }
      if (!this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length) {
        v.zipCode.errMsg = "任意事業場を設定する場合は必須入力になります。";
        isValidForm = false;
      }
      if (!this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.prefecturesId) {
        v.prefecturesId.errMsg = "任意事業場を設定する場合は必須入力になります。";
        isValidForm = false;
      }
      if (!this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
        v.address.errMsg = "任意事業場を設定する場合は必須入力になります。";
        isValidForm = false;
      }
      if (!v.buildingName.isValid && this.formValue.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length) {
        v.buildingName.errMsg = "40文字以内で入力してください。";
        isValidForm = false;
      }
    }
    return isValidForm
    }
  },
};
