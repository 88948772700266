import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";

export default {
  data() {
    return {
      validate: {
        generateStoreId: { isValid: false, errMsg: "" },
        collectionDate: { isValid: true, errMsg: "" },
        routesCount: { isValid: true, errMsg: "" },
        routes: {
          'routeId': {
            detailWasteName : { isValid: true, errMsg: "" },
            wasteQuantity: { isValid: true, errMsg: "" },
            wasteUnitTypeId: { isValid: true, errMsg: "" },
            shapeQuantity: { isValid: true, errMsg: "" },
            anyGenerateStoreName: { isValid: true, errMsg: "" },
            zipCode: { isValid: true, errMsg: "" },
            prefecture: { isValid: true, errMsg: "" },
            address: { isValid: true, errMsg: "" },
            contactNo: [
              { isValid: true, errMsg: "" }
            ],
            remarks: [
              { isValid: true, errMsg: "" }
            ],
            buildingName: { isValid: true, errMsg: "" },
          },
          // NOTE:ルート選択のたびに増える
        },
        generateMemberName: { isValid: true, errMsg: "" },
      },
    };
  },
  methods: {
    validatesCheck: async function () {
      const validate = this.validate;
      const wasteQuantityRegexp = new RegExp(/^(\d{1,5}|0)(\.\d{1,3})?$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      let promise = null;
      const routes = this.formValue.routes;
      
      const selectedRoutes = Object.keys(routes).filter(routeId => routeId !== 'routeId');
      if (selectedRoutes.length === 0) {
        validate.routesCount.errMsg = "ルートを1つ以上選択してください。";
        return Promise.resolve(false);
      } else validate.routesCount.errMsg = "";

      const promiseGenerateStoreInfo = (formRoute) => new Promise((resolve) => {
        const v = validate.routes[formRoute.routeId];
        const vCopied = Object.assign({}, JSON.parse(JSON.stringify(v)));
        const selectedPrefectureTemp = this.selectedPrefectureTemp.find(pref => pref.routeId === formRoute.routeId);

        if (formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length && !v.zipCode.isValid) {
            v.zipCode = {
              ...vCopied.zipCode,
              errMsg: "正しい値を入力してください。"
            };
            resolve(false);
        } else {
          if (formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
            const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode}`;
            const resApi = axios
              .get(url, {adapter: axiosJsonpAdapter})  // Promise型
              .then((res) => {
                let isValidForm = true;
                const results = res.data.results;
                if (results != null) {
                  if (v.prefecture.isValid) {
                    var prefectureFlg = false;
                    results.forEach((address) => {
                      if (selectedPrefectureTemp.pref === address.address1) {
                        prefectureFlg = true;
                      }
                    });
                    if (!prefectureFlg) {
                      v.prefecture = {
                        ...vCopied.prefecture,
                        errMsg: "郵便番号と都道府県が一致していません。"
                      };
                      isValidForm = false;
                    }
                  }
                  if (v.address.isValid) {
                    var addressFlg = false;
                    results.forEach((address) => {
                      if (formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.address === address.address2) {
                        addressFlg = true;
                      }
                    });
                    if (!addressFlg) {
                      v.address = {
                        ...vCopied.address,
                        errMsg: "郵便番号と市区町村が一致していません。"
                      };
                      isValidForm = false;
                    }
                  }
                }
  
                return isValidForm;
              })
              .catch((err) => {
                console.log(err);
              });
              resolve(resApi);
          } else {
            resolve(true);
          }
        }
      });

      const promiseOther = (formRoute, prevIsValidForm) => new Promise((resolve) => {
        let isValidForm = prevIsValidForm;

        const v = validate.routes[formRoute.routeId];
        const vCopied = Object.assign({}, JSON.parse(JSON.stringify(v)));
        const w = formRoute.wasteInfo
        const selectedPrefectureTemp = this.selectedPrefectureTemp.find(pref => pref.routeId === formRoute.routeId);

        if (w.detailWasteName && !vCopied.detailWasteName.isValid) {
          // NOTE: isValidがfalseになってしまうときがあったので再度バリデーションチェック(原因特定できず)
          if (!this.validateMaxLen(w.detailWasteName, 30)) {
            v.detailWasteName = {
              ...vCopied.detailWasteName,
              errMsg: "詳細名称は30文字以内で入力してください。" 
            }
            isValidForm = false;
          }
        }

        if (w.wasteQuantity) {
          if (!wasteQuantityRegexp.test(w.wasteQuantity)) {
            v.wasteQuantity = {
              ...vCopied.wasteQuantity,
              errMsg: "整数5桁、小数点1桁、小数部3桁で入力してください。"
            };
            isValidForm = false;
          }
          if (w.wasteQuantity == 0.0) {
            v.wasteQuantity = {
              ...vCopied.wasteQuantity,
              errMsg: "正しい値を入力してください。"
            };
            isValidForm = false;
          }
        }
        
        if ((w.wasteQuantity && !w.wasteUnitTypeId) || (!w.wasteQuantity && w.wasteUnitTypeId)) {
          v.wasteUnitTypeId = {
            ...vCopied.wasteUnitTypeId,
            errMsg: "廃棄物数量の数量、単位いずれかのみの設定はできません。未設定もしくは、両方の設定をしてください。"
          };
          isValidForm = false;
        }
        
        if (w.shapeQuantity) {
          if (String(w.shapeQuantity).length > 5) {
            v.shapeQuantity = {
              ...vCopied.shapeQuantity,
              errMsg: "荷姿数量は5桁以内の数値で入力してください。" 
            }
            isValidForm = false;
          }
          if (!Number.isInteger(Number(w.shapeQuantity))) {
            v.shapeQuantity = {
              ...vCopied.shapeQuantity,
              errMsg: "荷姿数量は整数で入力してください。"
            };
            isValidForm = false;
          }
          if(Number(w.shapeQuantity) <= 0) {
            v.shapeQuantity = {
              ...vCopied.shapeQuantity,
              errMsg: "荷姿数量は0より大きい値を設定してください。"
            };
            isValidForm = false;
          }
        }
        
        if (formRoute.routeId !== "" && formRoute.routeId !== null) {
          v.contactNo = vCopied.contactNo.map((cv, index) => {
            const f = formRoute.contactNoInfo.contactNoList[index];
            if (f.contactNo !== "") {
              if (f.contactNo.length && !cv.isValid) {
                isValidForm = false;
                return {
                  ...cv,
                  errMsg: "半角英数字で入力してください。"
                };
              } else if (!halfAlphaRegexp.test(f.contactNo)) {
                isValidForm = false;
                return {
                  ...cv,
                  errMsg: "半角英数字で入力してください。"
                };
              } else if (encodeURIComponent(f.contactNo).replace(/%../g, "x").length > 20) {
                isValidForm = false;
                return {
                  ...cv,
                  errMsg: "20バイト以内で入力してください。"
                };
              }
            }

            return {
              ...cv,
              errMsg: ""
            };
          });
        }

        v.remarks = vCopied.remarks.map((re, index) => {
          const r = formRoute.remarksInfo.remarksList[index];
          if (r.remarks) {
            if (!this.isValidByteLength(r.remarks, 50)) {
              isValidForm = false;
              return {
                ...re,
                errMsg: "備考は50バイト以内で入力してください。"
              };
            } else if (
              PROHIBITED_CHARACTERS_REGEX.test(r.remarks) ||
              !this.isContainValidCharacter(r.remarks)
            ) {
              isValidForm = false;
              return {
                ...re,
                errMsg: "登録できない文字が含まれています。"
              };
            }
          }
          return {
            ...re,
            errMsg: ""
          };
        });

        // 画面側で、任意事業場の項目に１つでも入力値があると、「事業場名」、「郵便番号」、「都道府県」、「市区町村」は、入力しないとエラーとする
        if (formRoute.anyGenerateStoreInfo.anyGenerateStoreName.length
            || formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length
            || selectedPrefectureTemp.pref
            || formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length
            || formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length
            ) {
          if (!v.anyGenerateStoreName.isValid) {
            v.anyGenerateStoreName = {
              ...vCopied.anyGenerateStoreName,
              errMsg: "60文字以内で入力してください。"
            };
            isValidForm = false;
          }
          if (!formRoute.anyGenerateStoreInfo.anyGenerateStoreName.length) {
            v.anyGenerateStoreName = {
              ...vCopied.anyGenerateStoreName,
              errMsg: "任意事業場を設定する場合は必須入力になります。"
            };
            isValidForm = false;
          }
          if (!formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.zipCode.length) {
            v.zipCode = {
              ...vCopied.zipCode,
              errMsg: "任意事業場を設定する場合は必須入力になります。"
            };
            isValidForm = false;
          }
          if (!selectedPrefectureTemp || !selectedPrefectureTemp.pref) {
            v.prefecture = {
              ...vCopied.prefecture,
              errMsg: "任意事業場を設定する場合は必須入力になります。"
            };
            isValidForm = false;
          }
          if (!formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.address.length) {
            v.address = {
              ...vCopied.address,
              errMsg: "任意事業場を設定する場合は必須入力になります。"
            };
            isValidForm = false;
          }
          if (!v.buildingName.isValid && formRoute.anyGenerateStoreInfo.anyGenerateStoreAddress.buildingName.length) {
            v.buildingName = {
              ...vCopied.buildingName,
              errMsg: "40文字以内で入力してください。"
            };
            isValidForm = false;
          }
        }

        resolve(isValidForm);
      }); 
      
      const checkGenerateStoreInfos = await Promise.all(routes.map(formRoute => promiseGenerateStoreInfo(formRoute)));
      const hasFalse = checkGenerateStoreInfos.find(bool => !bool) === false;

      promise = hasFalse ? Promise.resolve(false) : Promise.resolve(true);

      promise = promise.then(async (prevIsValidForm) => {
        let isValidForm = prevIsValidForm;

        const collectionDate = this.formValue.collectionDate;
        const routeDetailInfos = this.routeDetailInfos;
        if (collectionDate && routeDetailInfos && routeDetailInfos.length > 0) {
          routeDetailInfos.map(routeInfo => {
            if (!routeInfo.isEntrustUpdate
              && (collectionDate < routeInfo.activeStartDate
                  || (routeInfo.activeEndDate && collectionDate > routeInfo.activeEndDate))
            ) {
              validate.collectionDate.errMsg = "ルート利用期間外です。契約、ルートの有効期間を確認して下さい。";
              isValidForm = false;
            }
          })
        }

        if (!this.formValue.generateStoreId) {
          validate.generateStoreId.isValid = false;
          validate.generateStoreId.errMsg = "必須項目です、ご選択ください。";
          isValidForm = false;
        }

        if (this.formValue.manifestInfo.generateMember && this.formValue.manifestInfo.generateMember.name) {
          if (!validate.generateMemberName.isValid) {
            validate.generateMemberName.errMsg = "12文字以内で入力してください。";
            isValidForm = false;
          }
        }
        
        // 各ルート
        const routes = this.formValue.routes;
        const checkOthers = await Promise.all(routes.map(formRoute => promiseOther(formRoute, isValidForm)));
        const hasFalseOther = checkOthers.find(bool => !bool) === false;
        if (!isValidForm) return false;
        return hasFalseOther ? false : true;;
      });
      
      return promise;
    },

    runValidate() {
      return this.validatesCheck();
    },

    // 半角は1Byte・全角2Byte文字を分けてカウントして、Byteに変換する(Byte数はShift-jis)
    countByteByString(string) {
      let byteSize = 0;
      // 半角・全角判定
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9/]*$/);

      // 文字列１つ１つを取得し、半角か全角か判定する
      for (let i = 0; i < string.length; i++) {
        let character = string.charAt(i);

        if (halfAlphaRegexp.test(character)){
          byteSize += 1;
        } else {
          byteSize += 2;
        }
      }
      return byteSize;
    },
  },
};
