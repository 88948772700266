<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="交付前マニフェスト" />
    <AppSideMenu />
    <main class="l-main">
      <ToastMessage :toastMsg.sync="toastMsg" />
      <!-- 検索フォーム ============================================================ -->
      <SearchForm
        @search="getManifestSearchApi"
      />

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />

        <div v-if="manifestList.length" class="l-flex cols l-item">
          <div class="u-txt-bold">選択中 {{ checkCount }} 件</div>
          <div class="l-flex cols" :class="{ disabled: !checkCount }">
            <div class="c-btn primary small" :class="{ disabled: isCallApiProcess }" @click="clickIssueHandler">交付</div>
            <div class="c-btn primary small" @click="showChecksheetDownloadModal">受渡確認票印刷</div>
            <div class="c-btn secondary small" :class="{ disabled: checkedUnderReserveCount || isCallApiProcess || sealedManifestCount}" @click="isCancelConfirmModalShow = true">取消</div>
          </div>
          <div
            class="c-btn secondary small upload"
            style="margin-left: auto;!important;width: 16rem;"
            @click="onClickCsvUploadModalDisplayButton"
          >
            CSVアップロード
          </div>
          <div
              class="c-btn secondary small download"
              style="width: 16rem;"
              @click="$refs.csvdownloadmodal.updateCSVModal(true)"
              @click_backup="clickCSVSearchHandler"
          >
            CSVダウンロード
          </div>
        </div>

        <div class="c-infoList__wrap">
          <div class="c-infoList scroll has_calendar l-box">
            <div class="c-infoList__row head">
              <div class="c-infoList__cell cb">
                <div class="c-checkbox">
                  <input
                      id="check-all-manifests"
                      type="checkbox"
                      v-model="isAllChecked"
                      @change="changeAllCheckHandler"
                  />
                  <label class="c-checkbox__label" for="check-all-manifests">
                    <span class="c-checkbox__box"></span>
                  </label>
                </div>
              </div>
              <div class="c-infoList__cell md-s">マニフェスト番号</div>
              <div class="c-infoList__cell full-date">
                <a @click="clickSortChangeHandler">
                    <span
                        class="c-infoList__cell__sort"
                        :class="{
                        asc: sortParams.type.toLowerCase() === 'asc',
                        desc: sortParams.type.toLowerCase() === 'desc',
                      }"
                    >引き渡し日</span>
                </a>
              </div>
              <div class="c-infoList__cell sm">廃棄物区分</div>
              <div class="c-infoList__cell sm">廃棄物名称</div>
              <div class="c-infoList__cell sm">詳細名称</div>
              <div class="c-infoList__cell md">数量</div>
              <div class="c-infoList__cell sm">荷姿</div>
              <div class="c-infoList__cell sm">排出事業場</div>
              <div class="c-infoList__cell md">運搬事業者</div>
              <div class="c-infoList__cell sm">処分場</div>
              <div class="c-infoList__cell" :class="{ 'md': isBulkEdit, 'sm': !isBulkEdit }">引き渡し担当者</div>
              <div class="c-infoList__cell cb"></div>
            </div>

            <!-- 検索結果あり -->
            <template v-if="manifestList.length">
              <template v-for="(manifest, index) of manifestList">
                <div class="c-infoList__row list" :class="{ 'inline-edit-mode': manifest.isEdit, 'highlight-line': checkHighlightLine(manifest.manifestId) }" :key="'manifest-' + index"
                >
                  <div class="c-infoList__cell cb text-dotted">
                    <div class="c-checkbox">
                      <input
                          :id="'checkbox-' + index"
                          type="checkbox"
                          v-model="manifest.isChecked"
                          @change="changeManifestCbHandler(index, manifest)"
                      />
                      <label class="c-checkbox__label" :for="'checkbox-' + index">
                        <span class="c-checkbox__box"></span>
                      </label>
                    </div>
                  </div>
                  <div @click="toDetail(manifest.manifestId)" class="c-infoList__cell md-s text-dotted">
                    <router-link @click.native="setManifestReservePage()" :to="`/manifest/${manifest.manifestId}`">
                      {{ manifest.manifestNo | blankText }}
                      {{ manifest.manifestRegistType == manifestRegistType.COLLECTOR_REGIST ?  "（運代）" : "" }}
                      {{ manifest.manifestRegistType == manifestRegistType.DISPOSER_REGIST ? "（処代）" : "" }}
                    </router-link>
                  </div>

                  <!-- 引き渡し日 -->
                  <div class="c-infoList__cell full-date c-tooltip" @click="toDetail(manifest.manifestId)">
                    <div class="c-infoText">
                      <div>
                        <template v-if="manifest.isEdit">
                          <InputDate class="inline-edit-mode"
                              :value.sync="manifest.editCollectionDate"
                              placeholder="0000/00/00"
                              :validation="validateJustBlank"
                              :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.isValid"
                              :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editCollectionDate.errMsg"
                          />
                        </template>
                        <template v-else>
                          {{ manifest.collectionDate | dateJpMDddFormat }}
                        </template>
                      </div>
                    </div>
                    <div v-if="manifest.collectionDateLimit" class="c-status" :class="manifest.collectionDateLimit">
                      <div class="c-infoList__popup">
                        <span v-if="manifest.collectionDateLimit === limitStatus.INFO">交付時は入力が必要です<br></span>
                        <span v-if="manifest.collectionDateLimit === limitStatus.WARNING">交付期限が迫っています<br></span>
                        <span v-if="manifest.collectionDateLimit === limitStatus.DANGER">交付期限を超過しています<br></span>
                      </div>
                    </div>
                  </div>

                  <div
                      @click="toDetail(manifest.manifestId)"
                      class="c-infoList__cell sm text-dotted">
                    {{ manifest.wasteType != null ? manifest.wasteType.name : "" }}
                  </div>
                  <div
                      @click="toDetail(manifest.manifestId)"
                      class="c-infoList__cell sm text-dotted">
                    {{ manifest.wasteName != null ? manifest.wasteName.name : "" }}
                  </div>
                  <div
                      @click="toDetail(manifest.manifestId)"
                      class="c-infoList__cell sm text-dotted">
                    {{ manifest.detailWasteName != null ? manifest.detailWasteName : "" }}
                  </div>

                  <!-- 数量 -->
                  <div class="c-infoList__cell md c-tooltip" @click="toDetail(manifest.manifestId)">
                    <div class="c-infoText">
                      <div>
                        <template v-if="manifest.isEdit">
                          <div class="c-inputWrap__items__cols search-wrap inline-edit-mode">
                            <InputText class="sm inline-edit-mode"
                                       inputType="number"
                                       :value.sync="manifest.editWasteQuantity"
                                       :validation="validateNumberOrFloat"
                                       :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantity.isValid"
                                       :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantity.errMsg"
                            />

                            <Selectbox class="sm inline-edit-mode"
                                       :value.sync="manifest.editWasteQuantityUnitTypeId"
                                       :validation="validateJustBlank"
                                       :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantityUnitTypeId.isValid"
                                       :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editWasteQuantityUnitTypeId.errMsg"
                            >
                              <option :value="null"></option>
                              <option
                                  v-for="(opt, index) in wasteUnitOpt"
                                  :key="'wu' + index"
                                  :value="opt.id"
                              >
                                {{ opt.name }}
                              </option>
                            </Selectbox>
                          </div>
                        </template>
                        <template v-else>
                          <template v-if="!manifest.wasteQuantity || !manifest.wasteUnitType">-</template>
                          <template v-else>{{ manifest.wasteQuantity | formatFloat }} {{ manifest.wasteUnitType.name }}</template>
                        </template>
                      </div>
                    </div>
                    <div v-if="!manifest.wasteQuantity || !manifest.wasteUnitType" class="c-status info">
                      <div class="c-infoList__popup">
                        <span>交付時は入力が必要です<br></span>
                      </div>
                    </div>
                  </div>

                  <!-- 荷姿 -->
                  <div class="c-infoList__cell sm c-tooltip" @click="toDetail(manifest.manifestId)">
                    <div class="c-infoText">
                      <div>
                        <template v-if="manifest.isEdit">
                          <Selectbox class="sm inline-edit-mode"
                                     :value.sync="manifest.editShapeTypeId"
                                     :validation="validateJustBlank"
                                     :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editShapeTypeId.isValid"
                                     :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editShapeTypeId.errMsg"
                          >
                            <option
                                v-for="(opt, index) in manifest.shapeOpt"
                                :key="'shape-' + index"
                                :value="opt.id"
                            >
                              {{ opt.name }}
                            </option>
                          </Selectbox>
                        </template>
                        <template v-else>
                          {{ manifest.shapeType ? manifest.shapeType.name : "-" }}
                        </template>
                      </div>
                    </div>
                    <div v-if="!manifest.shapeType" class="c-status info" >
                      <div class="c-infoList__popup">
                        <span>交付時は入力が必要です<br></span>
                      </div>
                    </div>
                  </div>

                  <!-- 排出事業場 -->
                  <div
                      @click="toDetail(manifest.manifestId)"
                      class="c-infoList__cell sm text-dotted">
                    {{ manifest.generateStore.name }}
                  </div>

                  <!-- 運搬事業者 -->
                  <div
                      @click="toDetail(manifest.manifestId)"
                      class="c-infoList__cell md c-tooltip"
                  >
                    <CommaText :items="manifest.collectCompany" arg="name" />
                  </div>

                  <!-- 処分事業者 -->
                  <div
                      @click="toDetail(manifest.manifestId)"
                      class="c-infoList__cell sm text-dotted">
                    {{ manifest.disposalSite != null ? manifest.disposalSite.name : "" }}
                  </div>

                  <!-- 引き渡し担当者 -->
                  <div class="c-infoList__cell c-tooltip" :class="{ 'md': isBulkEdit, 'sm': !isBulkEdit }" @click="toDetail(manifest.manifestId)">
                    <div class="c-infoText">
                      <div>
                        <template v-if="manifest.isEdit">
                          <Selectbox class="inline-edit-mode"
                                     :value.sync="manifest.editGenerateMemberId"
                                     :validation="validateJustBlank"
                                     :isValid.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editGenerateMemberId.isValid"
                                     :errMsg.sync="validate.manifestList.find(v => v.manifestId === manifest.manifestId).editGenerateMemberId.errMsg"
                          >
                            <option
                                v-if="manifest.generateMember && !manifest.generateMember.id"
                                :value="null"
                            >
                              {{ manifest.generateMember.name }}
                            </option>
                            <option
                                v-for="(opt, index) in generateMemberOpt"
                                :key="'generate-member-' + index"
                                :value="opt.id"
                            >
                              {{ opt.name }}
                            </option>
                          </Selectbox>
                        </template>
                        
                        <template v-else>
                          {{ manifest.generateMember ? manifest.generateMember.name : "-" }}
                        </template>
                      </div>
                    </div>
                    <div v-if="!manifest.generateMember" class="c-status info">
                      <div class="c-infoList__popup">
                        <span>交付時は入力が必要です<br></span>
                      </div>
                    </div>
                  </div>

                  <div
                      @click="toDetail(manifest.manifestId)"
                      class="c-infoList__cell cb"></div>
                </div>
              </template>
            </template>

            <!-- 検索結果なし -->
            <template v-else>
              <div class="c-infoList__row list">
                <div class="c-infoList__cell">
                  検索条件に一致するマニフェストはありません。
                </div>
              </div>
            </template>

          </div>

          <div v-if="manifestList.length" class="c-infoList__floated">
            <div
                v-for="(manifest, editIndex) of manifestList"
                :key="'edit-' + editIndex"
                class="c-infoList__cell"
            >
              <template v-if="((manifest.manifestStatusType.id === manifestStatus.RESERVED || manifest.manifestStatusType.id === manifestStatus.NOT_RESERVE) && manifest.routeId !== null)">
                <span class="c-infoList__cell" :class="{ 'inline-edit': !manifest.isEdit, 'eraser': manifest.isEdit }"
                  @click="clickEditHandler(editIndex, manifest)"></span>
              </template>
            </div>
          </div>
        </div>

        <!-- 一括編集 -->
        <div v-if="isBulkEdit">
          <div class="l-btns l-container">
            <div class="center float">
              <div class="c-btn secondary small" @click="cancelBulkEdit">キャンセル</div>
              <div class="c-btn primary mid" :class="{ disabled: isCallApiProcess }" @click="submitBulkEdit">保存</div>
            </div>
          </div>
        </div>

        <Pagination v-if="manifestList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getManifestSearchApi(searchedParam, true)"
        />
      </div>

      <CSVDownloadModal
        ref="csvdownloadmodal"
        :paginationParams="paginationParams"
        @downloadCSVHandler="downloadCSVHandler"
      />
    </main>

    <!-- 登録モーダル -->
    <div class="c-modal lg" v-show="isChecksheetDownloadModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">

        <div class="c-modal__head">
          <h5 class="c-lead-s">受渡確認票印刷ダウンロード</h5>
          <span class="c-modal__head__close" @click="closeModalHandler"></span>
        </div>
        <div class="c-modal__body">
          <p class="u-txt-center">受け渡し確認票をダウンロードする形式を選択してください</p>

          <p class="u-txt-center" v-if="checksheetDownloadMessage">{{ checksheetDownloadMessage }}</p>

          <div
          v-for="cdt in checksheetDownloadTypes"
          :key="cdt.id"
          class="c-modal__foot"
          >
            <div>
              <div class="c-btn primary download-sm" :class="{ disabled: isDisableChecksheetDownload }" @click="checksheetDownloadHandler(cdt.value)">{{ cdt.label }}</div>
            </div>
          </div>
        </div>
        <div class="c-modal__foot">
          <div class="c-btn secondary small" @click="closeModalHandler">閉じる</div>
        </div>
      </div>
    </div>

    <AppFooter />

    <ManifestCsvUploadModal v-if="isDisplayCsvUploadModal" 
      v-bind:onClickCloseButton="hideCsvUploadModal"
      v-bind:onClickUploadButton="onClickUploadCsv"
      v-bind:onClickUploadRegisterCsvButton="onClickUploadRegisterCsv"
    />

    <!-- 取消確認モーダル -->
    <div class="c-modal" v-show="isCancelConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          マニフェストを取り消します。<br />
          よろしいですか？
        </div>
        <ModalFooterButtons :processing="isCallApiProcess">
          <div class="c-btn secondary small" @click="isCancelConfirmModalShow = false">戻る</div>
          <div class="c-btn primary small" @click="clickCancelHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import { API_URL, DOMAIN } from "@/const";
import axios from "axios";
import SearchForm from "./components/SearchForm.vue";
import search from "./mixins/search";
import Pagination from "@/components/parts/Pagination";
import { saveAs } from "file-saver";
import ToastMessage from "@/components/parts/ToastMessage";
import {DATE_LIMIT_STATUS} from "@/const/dateLimit";
import validation from "@/mixin/validation";
import editValidate from "./mixins/editValidate";
import InputDate from "@/components/parts/input/InputDate";
import callApi from "./mixins/callApi";
import CommaText from "@/components/parts/CommaText";
import Selectbox from "@/components/parts/input/Selectbox";
import InputText from "@/components/parts/input/InputText";
import CSVDownloadModal from "../components/CSVDownloadModal";
import manifestCallApi from "../mixins/callApi";
import ManifestCsvUploadModal from "../components/ManifestCsvUploadModal";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "manifest-reserve",
  components: {
    InputText,
    Selectbox,
    InputDate,
    SearchForm,
    Pagination,
    ToastMessage,
    CSVDownloadModal,
    ManifestCsvUploadModal,
    CommaText,
    ModalFooterButtons
  },
  mixins: [search, validation, editValidate, callApi, manifestCallApi],
  data() {
    return {
      isAllChecked: false,
      checkCount: 0,
      checkedUnderReserveCount: 0,
      sealedManifestCount: 0,
      manifestList: [],
      // 検索内容別で持つ。ページ移動でもAPI叩くため
      searchedParam: {},
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "collection_date",
        type: "asc",
      },
      errMsgs: [],
      toastMsg: "",
      manifestStatus: DOMAIN.MANIFEST_STATUS,
      manifestRegistType: DOMAIN.MANIFEST_REGIST_TYPE,
      limitStatus: DATE_LIMIT_STATUS,
      isBulkEdit: false,
      isDisplayCsvUploadModal: false,
      isChecksheetDownloadModalShow: false,
      checksheetDownloadTypes: [
        {
          label: "PDFダウンロード(複数一括)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF,
        },
        {
          label: "PDFダウンロード(単票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET,
        },
        {
          label: "PDFダウンロード(連票)",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET,
        },
        {
          label: "EXCELダウンロード",
          value: DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.EXCEL_ZIP,
        },
      ],
      isDisableChecksheetDownload: false,
      checksheetDownloadMessage: "",
      isCallApiProcess: false, // 一括交付、削除ボタンの処理中フラグ
      bundleIssueErrorList: [],
      isCancelConfirmModalShow: false
    };
  },
  methods: {
    closeModalHandler() {
      this.isChecksheetDownloadModalShow = false;
    },

    // 受渡確認票印刷押下
    showChecksheetDownloadModal() {
      this.isChecksheetDownloadModalShow = true;
    },

    // 受渡確認票をダウンロード
    checksheetDownloadHandler(downloadTypeId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      this.isDisableChecksheetDownload = true;
      this.checksheetDownloadMessage = `${this.checksheetDownloadTypes.find(t => t.value === downloadTypeId).label}を行なっています。少々お待ちください。`;
      axios
        .post(API_URL.MANIFEST.DELIVERY_CHECKSHEET
            , { downLoadTypeId: downloadTypeId, manifestIdList: this.getCheckedManifestIdList() }
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          if (downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_ONE_SHEET) {
            saveAs(blob, `受渡確認表(単票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.PDF_THREE_SHEET) {
            saveAs(blob, `受渡確認表(連票)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else if(downloadTypeId === DOMAIN.MANIFEST_CHECKSHEET_DOWNLOAD_TYPE.BUNDLE_PDF) {
            saveAs(blob, `PDFダウンロード(複数一括)_${moment().format("YYYYMMDDHHmmss")}.pdf`);
          } else {
            saveAs(blob, `受渡確認表_${moment().format("YYYYMMDDHHmmss")}.zip`);
          }
        })
        .then(() => {
          // ダウンロードボタンは活生にする
          this.isDisableChecksheetDownload = false;
          this.checksheetDownloadMessage = "";
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });      
    },

    // マニフェスト全件チェックボックス
    changeAllCheckHandler() {
      if (this.isAllChecked) {
        this.manifestList = this.manifestList.map((manifest) => {
          manifest.isChecked = true;
          return manifest;
        });
      } else {
        this.manifestList = this.manifestList.map((manifest) => {
          manifest.isChecked = false;
          return manifest;
        });
      }
    },

    // 期限ステータスチェック
    checkLimitStatus(date, issueAlertType) {
      if (!date) return DATE_LIMIT_STATUS.INFO;
      if (issueAlertType === 2) {
        return DATE_LIMIT_STATUS.DANGER;
      } else if (issueAlertType === 1) {
        return DATE_LIMIT_STATUS.WARNING;
      } else return DATE_LIMIT_STATUS.NOT_YET;
    },

    // 各マニフェストのチェックボックス
    changeManifestCbHandler(selectedIndex, manifest) {
      this.$set(this.manifestList, selectedIndex, manifest);
      this.isAllChecked = this.manifestList.length === this.manifestList.filter(m => m.isChecked).length;
    },

    // 引き渡し日ソート変更
    clickSortChangeHandler() {
      this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";

      // リセットする
      this.$store.commit("searchParamManifestsReserve/setManifestReservePage", 1);
      this.getManifestSearchApi(this.searchedParam, false);
    },

    getCheckedManifestIdList() {
      const checkedManifests = this.manifestList.filter(
        (manifest) => manifest.isChecked
      );
      const manifestIdList = checkedManifests.map((manifest) => {
        return { manifestId: manifest.manifestId };
      });
      return manifestIdList;
    },
    /* 期限切れのマニフェストのチェック */
    expiredManifestValidate() {
      // 選択されたデータ且つ編集可能なデータを抽出
      this.manifestList.filter(
        (manifest) => {
          // マニフェストステータス 11,12が対象
          if(manifest.isChecked && manifest.manifestStatusType.id < 19 && manifest.manifestStatusType.id !== 10){
              return true;
          }
        }
      );
      const expiredManifests = this.manifestList.filter(m => m.isChecked).filter(m => m.expirationDate && moment().isAfter(m.expirationDate) && m.manifestStatusType.id === DOMAIN.MANIFEST_STATUS.RESERVED)
      expiredManifests.map( m => {
         this.bundleIssueErrorList.push(m.manifestId)
      })
      return expiredManifests.length === 0;
    },
    /*
     * 対象データが交付可能なデータか確認する
     * 一覧データで確認できない箇所はAPI側でキャッチする
     */
    issueDataValidate() {
      // 配列情報を初期化
      this.bundleIssueErrorList = [];
      // 選択されたデータ且つ編集可能なデータを抽出
      const checkedManifests = this.manifestList.filter(
        (manifest) => {
          // マニフェストステータス 11,12が対象
          if(manifest.isChecked && manifest.manifestStatusType.id < 19 && manifest.manifestStatusType.id !== 10){
              return true;
          }
        }
      );
      // 選択された各種交付対象データの内容が正しいかチェックする
      checkedManifests.forEach(cm => {
        let isValidData = true;
        // 引き渡し日チェック
        if(!cm.collectionDate) {
          isValidData = false;
        }
        // 引き渡し担当者チェック
        if(!cm.generateMember || !cm.generateMember.name) {
          isValidData = false;
        }
        // 登録担当者チェック
        if(!cm.registMember || !cm.registMember.name) {
          isValidData = false;
        }
        // ルート選択チェック
        if(!cm.routeId) {
          isValidData = false;
        }
        // 廃棄物種類チェック★
        if (cm.jwnetItemType) {
          if (!cm.jwnetItemType.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }
        // 廃棄物名称チェック★
        if (cm.wasteName) {
          if(!cm.wasteName.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }
        // 詳細名称チェック
        if (cm.detailWasteName) {
          //30文字以内チェック
          if (cm.detailWasteName.length >= 30) {
            isValidData = false;
          }
        }
        // 廃棄物数量チェック
        if(!cm.wasteQuantity) {
          isValidData = false;
        }
        // 廃棄物数量単位チェック
        if(cm.wasteUnitType) {
          if (!cm.wasteUnitType.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }
        // 荷姿チェック
        if(cm.shapeType) {
          if (!cm.shapeType.name) {
            isValidData = false;
          }
        } else {
          isValidData = false;
        }

        if(!isValidData) {
          this.bundleIssueErrorList.push(cm.manifestId);
        }
      });
      // チェック項目で引っかかったマニフェスト情報がなかった場合、交付APIを呼ぶ
      return this.bundleIssueErrorList.length === 0;
    },
    // 交付押下
    clickIssueHandler() {
      this.errMsgs = [];
      this.toastMsg = "";
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      if(!this.issueDataValidate() && !this.expiredManifestValidate()){
        this.errMsgs.push("交付できないマニフェストが含まれます。マニフェストの設定情報をご確認ください。");
        return;
      } else if (!this.issueDataValidate()) {
        this.errMsgs.push("必要な情報が不足しています。詳細画面で内容を修正してください。");
        return;
      } else if (!this.expiredManifestValidate()) {
        this.errMsgs.push("マニフェストの有効期限が切れています。");
        return;
      }
      this.isCallApiProcess = true;
      axios.put(API_URL.MANIFEST.BUNDLE_ISSUE
            , { manifestList: this.getCheckedManifestIdList() }
        )
        .then(() => {
          // リロード
          this.getManifestSearchApi(this.$store.getters["searchParamManifestsReserve/get"], true).then(() =>{
            this.isCallApiProcess = false;
          });
          // 成功時のメッセージの設定
          this.toastMsg = `${this.getCheckedManifestIdList().length}件の交付を行いました。`;
        })
        .catch((err) => {
          this.isCallApiProcess = false;
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },

    // cs202201：CSVフォーマット関連
    /**
     * CSVフォーマット選択モーダル内ダウンロードボタン押下時
     */
    downloadCSVHandler(selectID) {
      // 2022.03.09 全項目とフォーマット指定のAPIを一本化
      this.clickCSVSearchByFormatHandler(selectID);
    },

    // CSVダウンロード用の検索条件パラメーター
    getCSVDownloadSearchParam() {
      let generateStoreIdList = [];
      if (this.searchedParam.generateStoreIdList) {
        this.searchedParam.generateStoreIdList.forEach (
            generateStoreId => {
              if (generateStoreId) {
                generateStoreIdList.push(generateStoreId);
              }
            }
        )
      }
      const isNoDateShow =  this.$store.getters["searchParamManifestsReserve/isNoDateShow"];
      return {
        searchConditionId: isNoDateShow ? String(DOMAIN.MANIFEST_SEARCH_CONDITION.BEFORE_ISSUE_NODATE) : String(DOMAIN.MANIFEST_SEARCH_CONDITION.BEFORE_ISSUE),
        wasteName: this.searchedParam.wasteName === "" ? null : this.searchedParam.wasteName,
        collectCompanyId: this.searchedParam.collectCompanyId === "" ? null : this.searchedParam.collectCompanyId,
        generateCompanyId: this.searchedParam.generateCompanyId === "" ? null : this.searchedParam.generateCompanyId,
        collectionDateFrom: this.searchedParam.collectionDateFrom === "" ? null : this.searchedParam.collectionDateFrom,
        collectionDateTo: this.searchedParam.collectionDateTo === "" ? null : this.searchedParam.collectionDateTo,
        isOnlyEmptyRegist: this.searchedParam.isOnlyEmptyRegist,
        generateStoreId: generateStoreIdList,
        manifestSearchType: this.searchedParam.status,
        disposalCompanyId: this.searchedParam.disposalCompanyId === "" ? null : this.searchedParam.disposalCompanyId,
        disposalSiteId: this.searchedParam.disposalSiteId === "" ? null : this.searchedParam.disposalSiteId,
        manifestNo: this.searchedParam.manifestNo === "" ? null : this.searchedParam.manifestNo,
        routeName: this.searchedParam.routeName === "" ? null : this.searchedParam.routeName,
        routeId: this.searchedParam.routeId === "" ? null : this.searchedParam.routeId,
        jwnetItemTypeId: this.searchedParam.jwnetItemTypeId === "" ? null : this.searchedParam.jwnetItemTypeId,
        cbaItemId: this.searchedParam.cbaItemId === "" ? null : this.searchedParam.cbaItemId,
        wasteTypeList: this.searchedParam.wasteType,
        jwnetItemTypeFieldList: this.searchedParam.jwnetItemTypeField,
        contactNo: this.searchedParam.contactNo === "" ? null : this.searchedParam.contactNo,
        remarks: this.searchedParam.remarks === "" ? null : this.searchedParam.remarks,
        manifestLabel: this.searchedParam.manifestLabel === "" ? null : this.searchedParam.manifestLabel,
        isMine: this.searchedParam.isMine,
        isActive: this.searchedParam.isActive,
        sort: this.sortParams.column + ":" + this.sortParams.type
      };
    },

    // CSVダウンロード押下
    clickCSVSearchHandler() {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
        .post(API_URL.MANIFEST.CSV_DOWNLOAD
            , this.getCSVDownloadSearchParam()
            , { responseType: "blob" }
        )
        .then((res) => {
          const blob = new Blob([res.data], {
            type: res.data.type
          });
          saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          this.$refs.csvdownloadmodal.updateCSVModal(false);
        });
    },

    // フォーマット指定したCSVダウンロード押下
    clickCSVSearchByFormatHandler(csvformatId) {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      axios
          .post(API_URL.MANIFEST.CSV_DOWNLOAD_BY_FORMAT
              , {
                manifestSearchFileDownloadInfo: this.getCSVDownloadSearchParam(),
                csvformatId: csvformatId
              }
              , { responseType: "blob" }
          )
          .then((res) => {
            const blob = new Blob([res.data], {
              type: res.data.type
            });
            saveAs(blob, `マニフェスト検索結果_${moment().format("YYYYMMDDHHmmss")}.csv`);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
          })
          .catch((err) => {
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            this.$refs.csvdownloadmodal.finishedDownloadCSVHandler();
            this.$refs.csvdownloadmodal.updateCSVModal(false);
          });
    },

    // 取消押下 manifestStatusId: 90は「削除」
    clickCancelHandler() {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      const manifestIdList = this.getCheckedManifestIdList();
      this.isCallApiProcess = true;

      axios
        .patch(API_URL.MANIFEST.STATUS_UPDATE, {
          manifestIdList: manifestIdList,
          manifestStatusId: DOMAIN.MANIFEST_STATUS.DELETE,
        })
        .then(() => {
          // リロード
          this.getManifestSearchApi(this.$store.getters["searchParamManifestsReserve/get"], true).then(() => {
            this.isCallApiProcess = false;
            this.isCancelConfirmModalShow = false;
          });
        })
        .catch((err) => {
          this.isCallApiProcess = false;
          this.isCancelConfirmModalShow = false;
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
        });
    },

    toDetail(manifestId) {
      this.setManifestReservePage()
      if (this.manifestList.filter(m => m.isEdit).length) return;
      const transitionSource = location.pathname;
      this.$router.push(`/manifest/${manifestId}?transitionSource=${transitionSource}`);
    },

    // 編集ペンアイコン押下
    clickEditHandler(selectedIndex, manifest) {
      manifest.isEdit = !manifest.isEdit;
      manifest.editCollectionDate = manifest.collectionDate;
      manifest.editWasteQuantity = manifest.wasteQuantity;
      manifest.shapeTypeId = manifest.shapeType ? manifest.shapeType.id : null;
      manifest.editShapeTypeId = manifest.shapeType ? manifest.shapeType.id : null;
      manifest.generateMemberId = manifest.generateMember ? manifest.generateMember.id : null;
      manifest.editGenerateMemberId = manifest.generateMember ? manifest.generateMember.id : null;
      manifest.editWasteQuantityUnitTypeId = manifest.wasteUnitType ? manifest.wasteUnitType.id : null;
      this.$set(this.manifestList, selectedIndex, manifest);

      if (manifest.isEdit) {
        const editValidate = JSON.parse(JSON.stringify(this.dummyValidate));
        editValidate.manifestId = manifest.manifestId;
        this.validate.manifestList.push(editValidate);
        this.getGenerateMemberOptApi(manifest.generateStore.id);
        this.getShapeOpt(manifest)
      } else {
        const deleteIndex = this.validate.manifestList.findIndex(v => v.manifestId === manifest.manifestId);
        this.validate.manifestList.splice(deleteIndex, 1);
      }
    },

    // 一括編集のキャンセル
    cancelBulkEdit() {
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      this.manifestList = this.manifestList.map(manifest => {
        manifest.isEdit = false;
        return manifest;
      });
    },

    // 一括編集の確定
    submitBulkEdit() {
      this.isCallApiProcess = true
      this.errMsgs = [];
      // ハイライトエラーを初期化
      this.bundleIssueErrorList = [];

      if (!this.runValidate()) {
        this.isCallApiProcess = false
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      const list = this.manifestList.filter(m => m.isEdit).map(m => {
        let editGenerateMemberName = null
        if (m.editGenerateMemberId) {
          const selectedGenerateMember = this.generateMemberOpt.find(gm => gm.id === m.editGenerateMemberId)
          editGenerateMemberName = selectedGenerateMember.name
        } else {
          editGenerateMemberName = m.generateMember.name
        }
        return {
          manifestId: m.manifestId,
          collectionDate: m.editCollectionDate,
          wasteQuantity: m.editWasteQuantity,
          wasteQuantityUnitTypeId: m.editWasteQuantityUnitTypeId,
          shapeTypeId: m.editShapeTypeId,
          generateMember: {
            id : m.editGenerateMemberId,
            name: editGenerateMemberName,
          },
        };
      });
      axios
          .put(API_URL.MANIFEST.BULK_EDIT, { manifestReserveEditList: list })
          .then(() => {
            // リロード
            this.getManifestSearchApi(this.$store.getters["searchParamManifestsReserve/get"], true).then(() => {
              this.isCallApiProcess = false
            })
            // 成功時のメッセージの設定
            this.toastMsg = `${list.length}件の編集を行いました。`;
            window.scrollTo({ top: 0, behavior: "smooth" });
          })
          .catch((err) => {
            this.isCallApiProcess = false
            this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
            window.scrollTo({ top: 0, behavior: "smooth" });
          });
    },

    hideCsvUploadModal() {
      this.isDisplayCsvUploadModal = false;
    },
    onClickCsvUploadModalDisplayButton() {
      this.isDisplayCsvUploadModal = true;
    },

    onClickUploadCsv(csvFile) {
      return this.postManifestCsvupload(csvFile)
        .then(this.onUpload);
    },
    onClickUploadRegisterCsv(csvFile) {
      return this.postManifestCsvregister(csvFile)
        .then(this.onUpload);
    },

    onUpload() {
      this.hideCsvUploadModal();
      this.toastMsg = "正常にアップロードしました。";
      // 再検索を行う
      this.$store.commit("searchParamManifestsReserve/setManifestReservePage", 1);
      this.getManifestSearchApi(this.$store.getters["searchParamManifestsReserve/get"], false);
    },
    // 一括交付条件に満たしていないデータをハイライトする
    checkHighlightLine(id) {
      return this.bundleIssueErrorList.find(n => n === id);
    },
    setManifestReservePage() {
      this.$store.commit("searchParamManifestsReserve/setManifestReservePage", this.paginationParams.page);
    },
    addRequestToLocation(requestObj) {
      const param = new URLSearchParams(requestObj)
      history.replaceState(
      {},
      null,
      this.$route.path + '?' + param
    )
    }
  },
  beforeRouteEnter(to, from, next) {
    next( (vm) => {
      if(from.path.includes("/manifest")) {
        let page = vm.$store.getters["searchParamManifestsReserve/getManifestReservePage"]
        vm.paginationParams.page = page
      } else {
        // リセットする
        vm.$store.commit("searchParamManifestsReserve/setManifestReservePage", 1);
      }
    })
  },

  watch: {
    manifestList: {
      handler: function () {
        this.manifestList.forEach(manifest => {
          manifest.collectionDateLimit = this.checkLimitStatus(manifest.collectionDate, manifest.issueAlertType);
        });

        this.checkCount = this.manifestList.filter(m => m.isChecked).length;
        this.isBulkEdit = this.manifestList.filter(m => m.isEdit).length;
        this.checkedUnderReserveCount = this.manifestList.filter(m => m.isChecked).filter(m => (m.manifestStatusType.id === DOMAIN.MANIFEST_STATUS.UNDER_RESERVE)).length;
        //確定状況チェック
        this.sealedManifestCount = this.manifestList.filter(m => m.isChecked).filter(m => m.isEditable === false).length   
      },
      deep: true,
    },
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.collectionDateTo) {
      this.$store.commit("searchParamManifestsReserve/set", {
        wasteName: "",
        collectCompanyId: "",
        generateCompanyId: "",
        collectionDateFrom: "",
        collectionDateTo: this.$route.query.collectionDateTo,
        status: [],
        generateStoreIdList: [null],
        disposalCompanyId: "",
        disposalSiteId: "",
        manifestNo: "",
        contactNo: "",
        jwnetItemTypeId: "",
        cbaItemId: "",
        routeName: "",
        routeId: "",
        wasteType: [],
        jwnetItemTypeField: "",
        isOnlyEmptyRegist: "",
        remarks: "",
        manifestLabel: "",
        isMine: true,
        isActive: true,
      });
      this.$store.commit("searchParamManifestsReserve/setIsNoCollectionDate", false);
    }
  },

  mounted() {
    this.getManifestSearchApi(this.$store.getters["searchParamManifestsReserve/get"], false);
    this.getWasteUnitApi();
    this.getShapeApi();
  },
};
</script>
