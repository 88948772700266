export default {
  data() {
    return {
      // routeInfo  from callApi.js
      routeCompanyName: {},
      routeTempVal: "",
      collectCompanyId: "",
      disposalCompanyId: "",
      jwnetItemTypeName: "",
      routeOpt: [],

      // 一時的に使用用
      // 絞り込み後のルート（画面遷移時はAPI返り値全件を代入）
      narrowedRoutesList: [],
    };
  },

  computed: {},

  methods: {
    initSelectedRoute() {
      this.routeDetailInfo.routeName = "";
      this.jwnetItemTypeName = "";
      this.routeCompanyName.generateStore = "";
      this.routeCompanyName.disposalSite = "";
      this.routeCompanyName.collectCompany = "";
      this.formValue.manifestInfo.routeId = null;
      this.formValue.generateStore = null;
      this.collectCompanyId = null;
      this.disposalCompanyId = null;
      this.selectedRoute = null;
      this.formValue.wasteInfo.wasteNameId = "";
      this.formValue.wasteInfo.wasteQuantity = "";
      this.formValue.wasteInfo.wasteUnitTypeId = "";
      this.formValue.wasteInfo.quantityConfirmBusinessTypeId = "";
      this.formValue.wasteInfo.shapeTypeId = "";
      this.formValue.wasteInfo.shapeQuantity = "";
      this.formValue.wasteInfo.disposalWayTypeId = "";
      this.formValue.contactNoInfo = [];
      this.formValue.remarksInfo = [];
      this.validate.contactNo = [];
      this.validate.remarks = [];

    },

    // 廃棄物種類のchange処理
    jwnetItemChangeHandler() {
      this.setSelectedJwnetItem();
      const selectedjwnetItemId = this.formValue.routeInfo.jwnetItem.id;
      if (selectedjwnetItemId == 0) {
        this.narrowedRoutesList = this.routesInfo;
      } else {
        const newRouteList = this.routesInfo.filter(
          (routeInfo) => routeInfo.jwnetItemType.id == selectedjwnetItemId
        );
        this.narrowedRoutesList = newRouteList;
      }
      if (this.formValue.manifestInfo.routeId) {
        const confirmRoute = this.narrowedRoutesList.filter(
          (r) => r.routeId === this.formValue.manifestInfo.routeId
        );
        if (confirmRoute.length == 0 || selectedjwnetItemId == 0) {
          this.initSelectedRoute();
        } else {
          this.onChangeRouteHandler();
        }
      } else {
        this.initSelectedRoute();
      }
    },

    // 選択された廃棄物種類をdataの値にセット
    setSelectedJwnetItem() {
      this.selectedJwnetItem = this.jwnetItemsOpt.filter(
        (ji) => ji.id === this.formValue.routeInfo.jwnetItem.id
      )[0];
      if (!this.selectedJwnetItem.isHarmful) {
        this.formValue.harmfulInfo = [{ harmfulTypeId: null }];
      }
    },

    /**
     * ルートの選択後に各会社情報を一時データに代入
     */
    getRouteCompanyName() {
      this.routesInfo.map((routeInfo) => {
        if (routeInfo.routeId == this.formValue.routeInfo.routeId) {
          this.routeCompanyName.generateStore = routeInfo.generateStore.name;
          this.routeCompanyName.disposalCompany = routeInfo.disposalCompany.name;
          this.jwnetItemTypeName = routeInfo.jwnetItemType.name;
          routeInfo.collectCompanys.map((collectCompany) => {
            if (collectCompany.id == this.collectCompanyId) {
              this.routeCompanyName.collectCompany = collectCompany.name;
            }
          });
        }
      });
    },

    // 送信時はheaderを削除
    setAddedLabelData() {
      const foremattedLabelData = this.routeLabels.map((routeLabel) => {
        return {
          id: routeLabel.labelId,
          header: routeLabel.labelHeader,
          labelValue: routeLabel.labelDefaultValue,
        };
      });
      this.formValue.labelInfo.labels = [...foremattedLabelData];
    },

    // ルート情報APIで取得した値を適用
    setRouteInfoValToFormVal() {
      const f = this.formValue;
      const r = this.routeDetailInfo;
      const v = this.validate;

      f.wasteInfo.jwnetItemTypeId = r.jwnetItemTypeInfo.id; // 廃棄物種類
      f.wasteInfo.wasteNameId = r.wasteNameInfo.id; // 廃棄物名称Id
      f.wasteInfo.detailWasteName = r.detailWasteName;
      f.manifestInfo.conversionFactorKg = r.conversionFactorKg;
      f.manifestInfo.conversionFactorLiter = r.conversionFactorLiter;
      f.manifestInfo.conversionFactorM3 = r.conversionFactorM3;
      f.manifestInfo.conversionFactorUnitNumber = r.conversionFactorUnitNumber;
      f.wasteInfo.wasteQuantity = r.plansQuantity; // 廃棄物数量
      f.wasteInfo.wasteUnitTypeId = r.plansWasteUnitType.id; // 廃棄物単価
      f.wasteInfo.quantityConfirmBusinessTypeId =
        r.quantityConfirmBusinessType.id; // 数量確定者
      if (r.shapeType) {
        f.wasteInfo.shapeTypeId = r.shapeType.id; // 荷姿
      }
      f.wasteInfo.shapeQuantity = r.shapeQuantity; // 荷姿数量

      // 連絡番号
      f.contactNoInfo.contactNoList = [];
      v.contactNo = [];
      r.contactNoInfo.forEach((contactNo) => {
        f.contactNoInfo.contactNoList.push(contactNo);
        v.contactNo.push({ isValid: true, errMsg: "" });
      });
      while (f.contactNoInfo.contactNoList.length < 3) {
        let index = f.contactNoInfo.contactNoList.length + 1 // IDは利用しないので、一旦インデックスで設定
        f.contactNoInfo.contactNoList.push({ id: index, contactNo: ""});
        v.contactNo.push({ isValid: true, errMsg: "" });
      }

      // 備考
      f.remarksInfo.remarksList = [];
      v.remarks = [];
      if (r.remarksInfo.length > 0) {
        r.remarksInfo.forEach((remark) => {
          f.remarksInfo.remarksList.push(remark);
          v.remarks.push({ isValid: true, errMsg: "" });
        });
      }
      while (f.remarksInfo.remarksList.length < 5) {
        let index = f.remarksInfo.remarksList.length + 1 // IDは利用しないので、一旦インデックスで設定
        f.remarksInfo.remarksList.push({ id: index, detail: ""});
        v.remarks.push({ isValid: true, errMsg: "" });
      }

      this.routeLabels = r.labelInfo; // ラベル

      // CBA品目IDを元にCBA品目別廃棄物名称選択肢取得APIを呼び出す
      this.getWasteNameCBAItemApi(r.cbaItemInfo.id);

      // 換算係数の設定
      f.manifestInfo.conversionFactorKg = r.conversionFactorKg
      f.manifestInfo.conversionFactorM3 = r.conversionFactorM3
      f.manifestInfo.conversionFactorLiter = r.conversionFactorLiter
      f.manifestInfo.conversionFactorUnitNumber = r.conversionFactorUnitNumber
    },
  },
};
