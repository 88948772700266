<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="CBA加入登録（排出）" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>

        <!-- 企業情報 ======================================================================== -->
        <div
          class="l-contents l-each"
          v-for="(company, companyIndex) in createGenerateCompanyList"
          :key="'company-' + companyIndex"
        >
          <div class="l-box u-space-btwn">
            <h3 class="c-lead icon">
              排出事業者情報
              <span v-if="companyIndex">{{ companyIndex + 1 }}</span>
            </h3>
            <div
              v-if="companyIndex"
              class="c-btn secondary sm delete"
              @click="deleteFormHandler(company.formId)"
            >
              削除
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業者名</label>
              </div>
              <div class="c-inputWrap__items">
                <div>
                  <InputText
                    :value.sync="company.companyName"
                    inputType="text"
                    placeholder="会社名/営業所名"
                    tooltip="100文字以内で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="100"
                    :isValid.sync="
                      getFormValidate(company.formId).companyName.isValid
                    "
                    :errMsg.sync="
                      getFormValidate(company.formId).companyName.errMsg
                    "
                  />
                </div>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="company.addressInfo.zipCode"
                        inputType="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="
                          getFormValidate(company.formId).zipCode.isValid
                        "
                        :errMsg.sync="
                          getFormValidate(company.formId).zipCode.errMsg
                        "
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandlerInput(company.formId)"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="company.addressInfo.prefecturesId"
                        :validation="validateJustBlank"
                        :isValid.sync="
                          getFormValidate(company.formId).prefecture.isValid
                        "
                        :errMsg.sync="
                          getFormValidate(company.formId).prefecture.errMsg
                        "
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.name"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市区町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="company.addressInfo.address"
                        inputType="text"
                        placeholder="市区町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          getFormValidate(company.formId).address.isValid
                        "
                        :errMsg.sync="
                          getFormValidate(company.formId).address.errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="company.addressInfo.buildingName"
                        inputType="text"
                        placeholder="番地建物名号室"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          getFormValidate(company.formId).building.isValid
                        "
                        :errMsg.sync="
                          getFormValidate(company.formId).building.errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
                <!-- /.c-inputWrap -->
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="company.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="
                    getFormValidate(company.formId).tel.isValid
                  "
                  :errMsg.sync="getFormValidate(company.formId).tel.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="company.fax"
                  inputType="number"
                  placeholder="FAX番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="
                    getFormValidate(company.formId).fax.isValid
                  "
                  :errMsg.sync="getFormValidate(company.formId).fax.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>JWNETID(加入者番号)</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="company.jwnetContractNo"
                    inputType="text"
                    placeholder="JWNETID(加入者番号)"
                    tooltip="7桁の半角英数字で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="7"
                    :isValid.sync="
                      getFormValidate(company.formId).jwnetContractNo
                        .isValid
                    "
                    :errMsg.sync="
                      getFormValidate(company.formId).jwnetContractNo.errMsg
                    "
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>EDI利用確認キー</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="company.ediKey"
                  inputType="text"
                  placeholder="EDI利用確認キー"
                  tooltip="8桁の半角英数字で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="8"
                  :isValid.sync="
                    getFormValidate(company.formId).ediKey.isValid
                  "
                  :errMsg.sync="
                    getFormValidate(company.formId).ediKey.errMsg
                  "
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>担当者名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="company.accountInfo.accountName"
                  inputType="text"
                  placeholder="氏名"
                  tooltip="全角12文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="12"
                  :isValid.sync="getFormValidate(company.formId).accountName.isValid"
                  :errMsg.sync="getFormValidate(company.formId).accountName.errMsg"
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>アカウントメールアドレス</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="company.accountInfo.accountMail"
                  inputType="mail"
                  placeholder="メールアドレス"
                  tooltip="メールアドレスを入力してください"
                  :validation="validateMail"
                  :isValid.sync="
                    getFormValidate(company.formId).accountMail.isValid
                  "
                  :errMsg.sync="
                    getFormValidate(company.formId).accountMail.errMsg
                  "
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業所/事業場情報</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                  :id="'csv-file-' + companyIndex"
                  type="file"
                  accept=".csv"
                  @change="(e) => fileChange(e, companyIndex)"
                  @click="(e) => e.target.value = null"
                />
                <label
                  class="c-btn secondary upload sm"
                  :for="'csv-file-' + companyIndex"
                  :class="{ disabled: processing }"
                >
                  CSVアップロード
                </label>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label"></div>
              <div class="c-inputWrap__items">
                <p v-if="csvFileName[companyIndex]" class="l-area">
                  {{ csvFileName[companyIndex] }}
                </p>
                <p v-for="(msg, i) in csvErrorMsgs[companyIndex]" :key="'err-msg-' + i" class="c-input__errMsg">{{ msg }}</p>
                <div
                    class="c-accordion"
                    v-for="(factory, factoryIndex) in company.createGenerateFactoryList"
                    :key="companyIndex + '-factory-' + factoryIndex"
                >
                  <input
                      :id="companyIndex + '-factory-acc-' + factoryIndex"
                      class="c-accordion__cb"
                      type="checkbox"
                  />
                  <div class="c-accordion__head">
                    <label
                        class="c-accordion__head__lbl"
                        :for="companyIndex + '-factory-acc-' + factoryIndex"
                    ></label>
                    <div class="c-accordion__head__text">
                      {{ factory.factoryName }}
                    </div>
                  </div>
                  <div class="c-accordion__body">
                    <div
                        class="c-infoText"
                        v-for="(store, storeIndex) in factory.createGenerateStoreList"
                        :key="companyIndex + '-store-' + factoryIndex + '-' + storeIndex"
                    >
                      <div class="c-infoText__text">{{ store.storeName }}</div>
                      <div class="c-infoText__tip">
                        <div class="c-dl">
                          <dt class="sm">住所</dt>
                          <dd>
                            〒{{ store.addressInfo.zipCode }}<br />
                            {{ store.addressInfo.prefecturesName }}
                            {{ store.addressInfo.address }}
                            {{ store.addressInfo.buildingName }}
                          </dd>
                        </div>
                        <div class="c-dl">
                          <dt class="sm">電話番号</dt>
                          <dd>{{ store.tel | blankText }}</dd>
                        </div>
                        <div class="c-dl">
                          <dt class="sm">FAX番号</dt>
                          <dd>{{ store.fax | blankText }}</dd>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.l-contents -->
        <!-- v-for ここまで -->

        <div class="l-each sm l-box">
          <div class="c-btn secondary func add" @click="addFormHandler">
            排出事業者情報追加
          </div>
        </div>

        <div class="l-btns">
          <div class="c-btn secondary small" @click="backHandler">戻る</div>
          <div class="c-btn primary mid" @click="checkRequiredHandler"
               :class="{ disabled: processing }">
            登録
          </div>
        </div>
      </div>
      <!-- /.l-container -->
    </main>

    <AddressSelectModal
      :isShow.sync="isAddressSelectModalShow"
      :addresses="addressResults"
      :targetForm.sync="targetForm"
    />

    <!-- 登録モーダル -->
    <div class="c-modal" v-show="isConfirmModalShow">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          契約情報を登録します。<br />
          よろしいですか？
          <div class="c-modal__body__alert">
            <span v-if="duplicatedNameInfoList.length">
              <br />
              下記の名称はすでに存在しています。このまま進めてよろしいですか？
              <br />
              <br />
            </span>
            <div
                v-for="(d, index) in duplicatedNameInfoList"
                :key="index"
            >
              <span :for="index">
                {{ duplicationNameCheckTarget.filter(it => it.targetId === d.duplicationNameCheckTargetId)[0].displayName }}: {{ d.name }}
              </span>
            </div>
          </div>
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="closeModalHandler">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import axios from "axios";

import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import AddressSelectModal from "../components/AddressSelectModal.vue";

import validation from "@/mixin/validation";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import csvUpload from "../mixins/form/csvUpload";
import addressSearch from "../mixins/form/addressSearch"; // 住所検索処理
import formValidation from "../mixins/form/formValidation";
import {API_URL, DUPLICATION_CHECK} from "@/const";
import common from "@/mixin/common";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "step2-input",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    AddressSelectModal,
  },
  mixins: [
    validation,
    formValue,
    callApi,
    csvUpload,
    addressSearch,
    formValidation,
    common,
  ],
  data() {
    return {
      contractId: null,
      isConfirmModalShow: false,
      errMsgs: [],
      targetForm: {},
      postData: [], // POSTするデータ。formValue.jsで定義しているものを「次へ」押下時にフォーマットしたもの。
      csvFileName: [""],
      processing: false,
      duplicationNameCheckTarget: DUPLICATION_CHECK.NAME,
    };
  },

  methods: {
    // 排出事業者情報追加を押下  post用のformのOBJとバリデーション用のPBJを追加
    addFormHandler() {
      const newFormId =
        this.createGenerateCompanyList[
          this.createGenerateCompanyList.length - 1
        ].formId + 1;
      const newList = {
        formId: newFormId,
        generateContractId: this.contractId,
        companyName: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          address: "",
          buildingName: "",
        },
        tel: "",
        fax: "",
        jwnetContractNo: "",
        ediKey: "",
        accountInfo: {
          accountName: "",
          accountMail: "",
        },
        file: null,
      };

      const newValidate = {
        formId: newFormId,
        companyName: { isValid: true, errMsg: "" },
        zipCode: { isValid: true, errMsg: "" },
        prefecture: { isValid: true, errMsg: "" },
        address: { isValid: true, errMsg: "" },
        building: { isValid: true, errMsg: "" },
        tel: { isValid: true, errMsg: "" },
        fax: { isValid: true, errMsg: "" },
        jwnetContractNo: { isValid: true, errMsg: "" },
        ediKey: { isValid: true, errMsg: "" },
        accountName: { isValid: true, errMsg: "" },
        accountMail: { isValid: true, errMsg: "" },
      };

      this.createGenerateCompanyList = [
        ...this.createGenerateCompanyList,
        newList,
      ];
      this.validate = [...this.validate, newValidate];
    },

    // form削除 post用のformのOBJとバリデーション用のPBJを削除
    deleteFormHandler(formId) {
      this.createGenerateCompanyList = this.createGenerateCompanyList.filter(
        (list) => list.formId != formId
      );
      this.validate = this.validate.filter((list) => list.formId != formId);
    },

    /**
     * formデータをフォーマット
     * 不要なformIdを削除
     * 都道府県をIDに変換
     * フォーマットされたデータを返す(Promise)
     */
    formatPostData() {
      return new Promise((resolve) => {
        let newData = JSON.parse(
          JSON.stringify(this.createGenerateCompanyList)
        ); // deep copy

        newData.map((company) => {
          this.$delete(company, "formId");
          // アカウント情報
          if (!company.accountInfo.accountName || !company.accountInfo.accountMail) {
            delete company.accountInfo;
          }
          // 住所の全角変換
          company.addressInfo.address = this.toFullWidthStringForAddressInfo(company.addressInfo.address);
          company.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(company.addressInfo.buildingName);
          if (Array.isArray(company.createGenerateFactoryList)) {
            company.createGenerateFactoryList.forEach(factory => {
              if (Array.isArray(factory.createGenerateStoreList)) {
                factory.createGenerateStoreList.forEach(store => {
                  store.addressInfo.address = this.toFullWidthStringForAddressInfo(store.addressInfo.address);
                  store.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(store.addressInfo.buildingName);
                });
              }
            });
          }

          this.prefectures.map((prefecture) => {
            if (prefecture.name === company.addressInfo.prefecturesId) {
              company.addressInfo.prefecturesId = prefecture.id;
            }
          });
        });

        resolve(newData);
      });
    },

    // post
    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      axios
        .post(API_URL.GENERATOR.CREATE_COMPANY, { createGenerateCompanyList: this.postData })
        .then(() => {
          // 排出事業者契約一覧画面
          this.$router.push("/generator/contract/");
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isConfirmModalShow = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    // 次へ押下
    async checkRequiredHandler() {
      this.errMsgs = [];
      this.duplicatedNameInfoList = [];
      this.duplicationNameCheckInfoList = [];

      const isValid = await this.runValidate();
      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      if (this.createGenerateCompanyList.length) {
        this.createGenerateCompanyList.forEach((gc) => {
          this.duplicationNameCheckInfoList.push({
            duplicationNameCheckTargetId: this.duplicationNameCheckTarget[1].targetId,
            name: gc.companyName
          });
          if (gc.createGenerateFactoryList.length) {
            gc.createGenerateFactoryList.forEach((gf) => {
              this.duplicationNameCheckInfoList.push({
                duplicationNameCheckTargetId: this.duplicationNameCheckTarget[2].targetId,
                name: gf.factoryName
              });
              if (gf.createGenerateStoreList.length) {
                gf.createGenerateStoreList.forEach((gs) => {
                  this.duplicationNameCheckInfoList.push({
                    duplicationNameCheckTargetId: this.duplicationNameCheckTarget[3].targetId,
                    name: gs.storeName
                  });
                });
              }
            });
          }
        }
      )};
      this.checkDuplicationName(this.duplicationNameCheckInfoList)

      // 都道府県をIDに変えてthis.formに追加してからpost
      this.formatPostData().then((res) => {
        this.isConfirmModalShow = true;
        this.postData = res;
      });
    },

    // 戻る押下（遷移元に戻る）
    backHandler() {
      this.$router.push(`/generator/contract/step1/edit/${this.contractId}?from=step2-input`);
    },

    // モーダル内戻る押下
    closeModalHandler() {
      this.postData = [];
      this.isConfirmModalShow = false;
    },
    // モーダル内登録押下
    registerHandler() {
      this.postForm();
    },
  },

  mounted() {
    this.contractId = Number(this.$route.query.generateContractId);

    if (this.contractId) {
      this.createGenerateCompanyList[0].generateContractId = this.contractId;
      this.createGenerateCompanyList[0].createGenerateFactoryList = [];
    } else {
      this.$router.push('/generator/contract/step1/input');
    }

    this.getPrefecturesApi();
  },
};
</script>
