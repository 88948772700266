import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";
export default {
  data() {
    return {
      validate: {
        collectFactoryList: [
          {
            name: { isValid: true, errMsg: "" },
            addressInfo: {
              zipCode: { isValid: true, errMsg: "" },
              prefectures: { isValid: true, errMsg: "" },
              address: { isValid: true, errMsg: "" },
              buildingName: { isValid: true, errMsg: "" },
            },
            tel: { isValid: true, errMsg: "" },
            fax: { isValid: true, errMsg: "" },
            jwnetContractNo: { isValid: true, errMsg: "" },
            ediKey: { isValid: true, errMsg: "" },
            publicConfirmationNo: { isValid: true, errMsg: "" },
          },
        ],
      },
      isValidForm: {
        collectFactory: true,
      },
    };
  },
  methods: {
    async collectFactoryValidateCheck() {
      let isValidForm = true;
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      for (let i = 0; i < this.validate.collectFactoryList.length; i++) {
        const v = this.validate.collectFactoryList[i];
        const targetForm = this.form.collectFactoryList[i];
        // 事業者名の禁則文字のチェック
        if (PROHIBITED_CHARACTERS_REGEX.test(targetForm.name)) {
          v.name.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
        if (!v.name.isValid) {
          v.name.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.addressInfo.zipCode.isValid) {
          v.addressInfo.zipCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${targetForm.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.addressInfo.prefectures.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (targetForm.addressInfo.selectedPrefectureTemp === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    v.addressInfo.prefectures.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.addressInfo.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (targetForm.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    v.addressInfo.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (!v.addressInfo.prefectures.isValid) {
          v.addressInfo.prefectures.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.addressInfo.address.isValid) {
          v.addressInfo.address.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetForm.addressInfo.buildingName && !v.addressInfo.buildingName.isValid) {
          v.addressInfo.buildingName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.tel.isValid) {
          v.tel.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetForm.fax && !v.fax.isValid) {
          v.fax.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.jwnetContractNo.isValid) {
          v.jwnetContractNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(targetForm.jwnetContractNo)) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は半角英数字で入力してください。";
          isValidForm = false;
        }
        if (targetForm.ediKey && !v.ediKey.isValid) {
          v.ediKey.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetForm.ediKey && !halfAlphaRegexp.test(targetForm.ediKey)) {
          v.ediKey.errMsg = "EDI利用確認キーは半角英数字で入力してください。";
          isValidForm = false;
        }
        if (targetForm.publicConfirmationNo && !v.publicConfirmationNo.isValid) {
          v.publicConfirmationNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }
      return isValidForm;
    },
    async runValidate() {
      let isValidForm = false;
      this.isValidForm.collectFactory = await this.collectFactoryValidateCheck();
      for (let k in this.isValidForm) {
        if (this.isValidForm[k]) {
          isValidForm = true;
        } else {
          isValidForm = false;
          break;
        }
      }

      return isValidForm;
    },
  },
};
