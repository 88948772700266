import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";
export default {
  data() {
    return {
      validate: [
        {
          formId: 1,
          companyName: { isValid: true, errMsg: "", isRequired: false },
          zipCode: { isValid: true, errMsg: "", isRequired: false },
          prefecture: { isValid: true, errMsg: "", isRequired: false },
          address: { isValid: true, errMsg: "", isRequired: false },
          building: { isValid: true, errMsg: "", isRequired: false },
          tel: { isValid: true, errMsg: "", isRequired: false },
          fax: { isValid: true, errMsg: "", isRequired: true },
          jwnetContractNo: { isValid: true, errMsg: "", isRequired: false },
          ediKey: { isValid: true, errMsg: "", isRequired: false },
          accountName: { isValid: true, errMsg: "", isRequired: true },
          accountMail: { isValid: true, errMsg: "", isRequired: true },
          generateFactoryInfoList: [
            {
              factoryId: 1,
              name: { isValid: true, errMsg: "", isRequired: true },
              generateStoreInfoList: [
                {
                  storeId: 1,
                  name: { isValid: true, errMsg: "", isRequired: true },
                  zipCode: { isValid: true, errMsg: "", isRequired: true },
                  prefecture: { isValid: true, errMsg: "", isRequired: true },
                  address: { isValid: true, errMsg: "", isRequired: true },
                  tel: { isValid: true, errMsg: "", isRequired: true },
                  fax: { isValid: true, errMsg: "", isRequired: false },
                }
              ],
            }
          ],
        },
      ],
    };
  },
  methods: {
    getFormValidate(formId) {
      return this.validate.filter((v) => v.formId === formId)[0];
    },

    async check() {
      let isValidForm = true;
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);

      for (let i = 0; i < this.validate.length; i++) {
        const v = this.validate[i];
        const form = this.createGenerateCompanyList.filter(
          (f) => f.formId === v.formId
        )[0];
        // 事業者名の禁則文字のチェック
        if (PROHIBITED_CHARACTERS_REGEX.test(form.companyName)) {
          v.companyName.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
        if (!v.companyName.isValid) {
          v.companyName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.zipCode.isValid || !form.addressInfo.zipCode) {
          v.zipCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${form.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (form.addressInfo.prefecturesId === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (form.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (!v.prefecture.isValid) {
          v.prefecture.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.address.isValid) {
          v.address.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (form.accountInfo.buildingName && !v.building.isValid) {
          v.building.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.tel.isValid) {
          v.tel.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (form.fax && !v.fax.isValid) {
          v.fax.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.jwnetContractNo.isValid) {
          v.jwnetContractNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(form.jwnetContractNo)) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は半角英数字で入力してください。";
          isValidForm = false;
        }
        if (form.ediKey && !v.ediKey.isValid) {
          v.ediKey.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (form.ediKey && !halfAlphaRegexp.test(form.ediKey)) {
          v.ediKey.errMsg = "EDI利用確認キーは半角英数字で入力してください。";
          isValidForm = false;
        }

        if (!v.accountName.isValid) {
          v.accountName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        // 担当者名の文字数の制限: 全角12文字以内のチェック
        if (form.accountInfo.accountName) {
          if (form.accountInfo.accountName.length > 12) {
            v.accountName.errMsg = "全角12文字以内で入力してください。";
            isValidForm = false;
          }
        }
        if (form.accountInfo.accountMail.length > 0 && !v.accountMail.isValid) {
          v.accountMail.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
      }

      return isValidForm;
    },

    async runValidate() {
      let isValidForm = false;
      isValidForm = await this.check();

      return isValidForm;
    },
  },
};
