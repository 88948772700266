<template>
  <div class="c-errMsg" v-if="errMsgs && errMsgs.length">
    <ul class="c-errMsg__list">
      <li v-for="(msg, index) in errMsgs" :key="index" v-html="msg"></li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    errMsgs: {
      type: Array,
    },
  },
};
</script>
