<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="運搬事業者登録" />
    <AppSideMenu />
    <main class="l-main">
      <div class="l-container">
        <ErrorMessage :errMsgs="errMsgs" />
        <div class="c-form__reqTxt l-item">：入力必須</div>
        <div
          v-for="(collectFactory, index) in form.collectFactoryList"
          :key="'factory-' + index"
          class="l-contents l-box"
        >
          <div class="l-box u-space-btwn">
            <h3 class="c-lead icon">運搬事業所情報{{ index + 1 }}</h3>
            <div
              v-if="index !== 0"
              class="c-btn secondary sm delete"
              @click="clickDeleteButton(index)"
            >
              削除
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-checkbox">
                <input
                  :id="'isSC-' + index"
                  type="checkbox"
                  v-model="isSameCompanyList[index]"
                  @change="checkIsSameCompany(index)"
                />
                <label class="c-checkbox__label" :for="'isSC-' + index">
                  <span class="c-checkbox__box"></span>
                  企業情報と同じ
                </label>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>事業所名</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.name"
                  inputType="text"
                  placeholder="事業所名"
                  tooltip="100文字以内で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="100"
                  :isValid.sync="validate.collectFactoryList[index].name.isValid"
                  :errMsg.sync="validate.collectFactoryList[index].name.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>住所</label>
              </div>
              <div class="c-inputWrap__items">
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>郵便番号</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div class="c-inputWrap__items__cols center sm-mgn">
                      <InputText
                        :value.sync="collectFactory.addressInfo.zipCode"
                        inputType="number"
                        placeholder="1234567(ハイフンなし)"
                        tooltip="7桁の半角数字で入力してください。"
                        :validation="validateNumberEqualLen"
                        :validateArg="7"
                        :isValid.sync="
                          validate.collectFactoryList[index].addressInfo.zipCode
                            .isValid
                        "
                        :errMsg.sync="
                          validate.collectFactoryList[index].addressInfo.zipCode
                            .errMsg
                        "
                      />
                      <div
                        class="c-btn secondary sm"
                        @click="searchAddressHandler(collectFactory.addressInfo)"
                      >
                        住所自動入力
                      </div>
                    </div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>都道府県</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <Selectbox
                        class=""
                        name="prefecture"
                        :value.sync="collectFactory.addressInfo.selectedPrefectureTemp"
                        :validation="validateJustBlank"
                        :isValid.sync="
                          validate.collectFactoryList[index].addressInfo.prefectures
                            .isValid
                        "
                        :errMsg.sync="
                          validate.collectFactoryList[index].addressInfo.prefectures
                            .errMsg
                        "
                      >
                        <option
                          v-for="prefecture in prefectures"
                          :key="prefecture.id"
                          :value="prefecture.name"
                        >
                          {{ prefecture.name }}
                        </option>
                      </Selectbox>
                    </div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>市町村</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="collectFactory.addressInfo.address"
                        inputType="text"
                        placeholder="市町村"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          validate.collectFactoryList[index].addressInfo.address
                            .isValid
                        "
                        :errMsg.sync="
                          validate.collectFactoryList[index].addressInfo.address
                            .errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="c-inputWrap">
                  <div class="c-inputWrap__label">
                    <label>番地建物名号室</label>
                  </div>
                  <div class="c-inputWrap__items">
                    <div>
                      <InputText
                        :value.sync="collectFactory.addressInfo.buildingName"
                        inputType="text"
                        placeholder="番地建物名号室"
                        tooltip="100文字以内で入力してください"
                        :validation="validateMaxLen"
                        :validateArg="100"
                        :isValid.sync="
                          validate.collectFactoryList[index].addressInfo
                            .buildingName.isValid
                        "
                        :errMsg.sync="
                          validate.collectFactoryList[index].addressInfo
                            .buildingName.errMsg
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>電話番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.tel"
                  inputType="number"
                  placeholder="電話番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.collectFactoryList[index].tel.isValid"
                  :errMsg.sync="validate.collectFactoryList[index].tel.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>FAX番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.fax"
                  inputType="number"
                  placeholder="FAX番号"
                  tooltip="半角数値で入力してください"
                  :validation="validateNumberMaxLen"
                  :validateArg="15"
                  :isValid.sync="validate.collectFactoryList[index].fax.isValid"
                  :errMsg.sync="validate.collectFactoryList[index].fax.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label required">
                <label>JWNETID(加入者番号)</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.jwnetContractNo"
                  inputType="text"
                  placeholder="JWNETID(加入者番号)"
                  tooltip="最大7文字の半角英数字で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="7"
                  :isValid.sync="validate.collectFactoryList[index].jwnetContractNo.isValid"
                  :errMsg.sync="validate.collectFactoryList[index].jwnetContractNo.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>EDI利用確認キー</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                    :value.sync="collectFactory.ediKey"
                    inputType="text"
                    placeholder="EDI利用確認キー"
                    tooltip="最大8文字の半角英数字で入力してください"
                    :validation="validateMaxLen"
                    :validateArg="8"
                    :isValid.sync="validate.collectFactoryList[index].ediKey.isValid"
                    :errMsg.sync="validate.collectFactoryList[index].ediKey.errMsg"
                />
              </div>
            </div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>公開確認番号</label>
              </div>
              <div class="c-inputWrap__items">
                <InputText
                  :value.sync="collectFactory.publicConfirmationNo"
                  inputType="number"
                  placeholder="公開確認番号"
                  tooltip="最大6文字の半角英数字で入力してください"
                  :validation="validateMaxLen"
                  :validateArg="6"
                  :isValid.sync="
                    validate.collectFactoryList[index].publicConfirmationNo.isValid
                  "
                  :errMsg.sync="
                    validate.collectFactoryList[index].publicConfirmationNo.errMsg
                  "
                />
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label">
                <label>営業所</label>
              </div>
              <div class="c-inputWrap__items">
                <input
                    :id="'csv-file-' + index"
                    type="file"
                    accept="text/csv"
                    @change="(e) => fileChange(e, index)"
                    @click="(e) => e.target.value = null"
                />
                <label
                    class="c-btn secondary upload sm"
                    :for="'csv-file-' + index"
                >
                  CSVアップロード
                </label>
              </div>
            </div>

            <div class="c-inputWrap">
              <div class="c-inputWrap__label"></div>
              <div class="c-inputWrap__items">
                <p v-if="csvFileName" class="l-area">
                  {{ csvFileName }}
                </p>
                <p v-for="(msg, i) in csvErrorMsgs" :key="'err-msg-' + i" class="c-input__errMsg">{{ msg }}</p>
                <div
                    class="c-accordion"
                    v-for="(office, officeIndex) in collectFactory.collectOfficeList"
                    :key="'officeList-' + officeIndex"
                >
                  <input
                      :id="'officeList-acc-' + officeIndex"
                      class="c-accordion__cb"
                      type="checkbox"
                  />
                  <div class="c-accordion__head">
                    <label
                        class="c-accordion__head__lbl"
                        :for="'officeList-acc-' + officeIndex"
                    ></label>
                    <div class="c-accordion__head__text">
                      {{ office.name }}
                    </div>
                  </div>
                  <div class="c-accordion__body">
                    <div class="c-infoText">
                      <div class="c-dl-wide">
                        <dt class="sm">住所</dt>
                        <dd>
                          〒{{ office.addressInfo.zipCode }}
                          {{ office.addressInfo.prefecturesName }}
                          {{ office.addressInfo.address }}
                          {{ office.addressInfo.buildingName }}
                        </dd>
                      </div>
                      <div class="c-dl-wide">
                        <dt class="sm">電話番号</dt>
                        <dd>{{ office.tel | blankText }}</dd>
                      </div>
                      <div class="c-dl-wide">
                        <dt class="sm">FAX番号</dt>
                        <dd>{{ office.fax | blankText }}</dd>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="l-each sm l-box">
          <div class="c-btn secondary func add" @click="addCollectfactory">
            運搬事業所を追加
          </div>
        </div>
      </div>

      <!-- 削除確認モーダル -->
      <div class="c-modal" v-show="isDisplayDeleteModal">
        <div class="c-modal__overlay"></div>
        <div class="c-modal__content">
          <div class="c-modal__body">
            運搬事業所情報を削除します。<br />
            よろしいですか？
          </div>
          <div class="c-modal__foot">
            <div
              class="c-btn secondary small"
              @click="isDisplayDeleteModal = false"
            >
              戻る
            </div>
            <div class="c-btn primary small" @click="deleteCollectfactory">
              はい
            </div>
          </div>
        </div>
      </div>

      <div class="l-btns l-container">
        <div class="left">
          <div class="c-btn secondary small back" @click="backHandler">戻る</div>
        </div>
        <div class="center">
          <div class="c-btn primary mid" @click="checkRequiredHandler(false)">登録</div>
          <div class="c-btn primary" @click="checkRequiredHandler(true)">続けてCBA加入登録へ</div>
        </div>
      </div>
    </main>

    <AddressSelectModal
        :isShow.sync="isAddressSelectModalShow"
        :addresses="addressResults"
        @selectAddress="selectAddressHandler"
    />

    <!-- 登録確認モーダル -->
    <!-- 続けてCBA加入登録へ確認モーダル -->
    <div class="c-modal" v-show="isDisplayRegisterModal || isDisplayNextModal">
      <div class="c-modal__overlay"></div>
      <div class="c-modal__content">
        <div class="c-modal__body">
          <span v-if="isDisplayRegisterModal">
          運搬事業所情報を登録します。<br />
          よろしいですか？
          </span>
          <span v-else-if="isDisplayNextModal">
          運搬事業所情報を登録し、 CBA加入登録の入力に進みます。<br />
          よろしいですか？
          </span>
          <div class="c-modal__body__alert">
            <span v-if="duplicatedNameInfoList.length">
              <br />
              下記の名称はすでに存在しています。このまま進めてよろしいですか？
              <br />
              <br />
            </span>
            <div
                v-for="(d, index) in duplicatedNameInfoList"
                :key="index"
            >
              <span :for="index">
                {{ duplicationNameCheckTarget.filter(it => it.targetId === d.duplicationNameCheckTargetId)[0].displayName }}: {{ d.name }}
              </span>
            </div>
          </div>
        </div>
        <ModalFooterButtons :processing="processing">
          <div class="c-btn secondary small" @click="isDisplayRegisterModal = false;isDisplayNextModal = false;">戻る</div>
          <div class="c-btn primary small" @click="registerHandler">はい</div>
        </ModalFooterButtons>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import InputText from "@/components/parts/input/InputText";
import Selectbox from "@/components/parts/input/Selectbox";
import validation from "@/mixin/validation";
import formValidation from "../mixins/form/formValidation";
import formValue from "../mixins/formValue";
import callApi from "../mixins/callApi";
import axios from "axios";
import common from "@/mixin/common";
import {API_URL, COOKIE_INFO, DUPLICATION_CHECK} from "@/const";
import csvUpload from "../mixins/form/csvUpload";
import addressSearch from "../mixins/form/addressSearch";
import AddressSelectModal from "../components/AddressSelectModal";
import ModalFooterButtons from "@/components/layout/ModalFooterButtons";

export default {
  name: "collector-step2-input",
  components: {
    ModalFooterButtons,
    InputText,
    Selectbox,
    AddressSelectModal,
  },
  mixins: [validation, formValidation, formValue, callApi, addressSearch, csvUpload, common],
  data() {
    return {
      errMsgs: [],
      isSameCompanyList: [ false ],
      csvFileName: "",
      // step1で一時保存された仮データ
      tempData: {
        name: "tempName",
        addressInfo: {
          zipCode: "tempZipcode",
          prefecturesId: 2,
          selectedPrefectureTemp: "京都",
          address: "tempAddress",
          buildingName: "tempBuildingName",
        },
        tel: "tempTel",
        fax: "tempFax",
      },
      deleteIndex: null,
      isDisplayDeleteModal: false,
      isDisplayRegisterModal: false,
      isDisplayNextModal: false,
      collectorId: null,
      postData: null, // this.formをフォーマットして最終的にpostするデータ
      processing: false,
      duplicationNameCheckTarget: DUPLICATION_CHECK.NAME,
    };
  },

  methods: {
    // 企業情報と同じチェックボックス押下
    checkIsSameCompany(index) {
      const v = this.form.collectFactoryList[index];
      // step1で一時保存されたデータを代入
      if (this.isSameCompanyList[index]) {
        v.name = this.tempData.name;
        v.addressInfo.zipCode = this.tempData.addressInfo.zipCode;
        v.addressInfo.prefecturesId = this.tempData.addressInfo.prefecturesId;
        v.addressInfo.selectedPrefectureTemp = this.tempData.addressInfo.selectedPrefectureTemp;
        v.addressInfo.address = this.tempData.addressInfo.address;
        v.addressInfo.buildingName =
          this.tempData.addressInfo.buildingName;
        v.tel = this.tempData.tel;
        v.fax = this.tempData.fax;
      } else {
        v.name = "";
        v.addressInfo.zipCode = "";
        v.addressInfo.prefecturesId = "";
        v.addressInfo.selectedPrefectureTemp = "";
        v.addressInfo.address = "";
        v.addressInfo.buildingName = "";
        v.tel = "";
        v.fax = "";
      }
    },
    // 運搬事業所を追加ボタン押下
    addCollectfactory() {
      const newList = {
        name: "",
        addressInfo: {
          zipCode: "",
          prefecturesId: null,
          selectedPrefectureTemp: "",
          address: "",
          buildingName: "",
        },
        tel: "",
        fax: "",
        jwnetContractNo: "",
        ediKey: "",
        publicConfirmationNo: "",
      };
      const newValidate = {
        name: { isValid: true, errMsg: "" },
        addressInfo: {
          zipCode: { isValid: true, errMsg: "" },
          prefectures: { isValid: true, errMsg: "" },
          address: { isValid: true, errMsg: "" },
          buildingName: { isValid: true, errMsg: "" },
        },
        tel: { isValid: true, errMsg: "" },
        fax: { isValid: true, errMsg: "" },
        jwnetContractNo: { isValid: true, errMsg: "" },
        ediKey: { isValid: true, errMsg: "" },
        publicConfirmationNo: { isValid: true, errMsg: "" },
      };

      this.form.collectFactoryList = [...this.form.collectFactoryList, newList];
      this.isSameCompanyList.push(false);
      this.validate.collectFactoryList = [
        ...this.validate.collectFactoryList,
        newValidate,
      ];
    },
    // 削除ボタン押下
    clickDeleteButton(index) {
      this.deleteIndex = index;
      this.isDisplayDeleteModal = true;
    },
    // 削除モーダルはいボタン押下
    deleteCollectfactory() {
      this.form.collectFactoryList.splice(this.deleteIndex, 1);
      this.isSameCompanyList.splice(this.deleteIndex, 1);
      this.validate.collectFactoryList.splice(this.deleteIndex, 1);
      this.isDisplayDeleteModal = false;
    },


    postForm() {
      this.errMsgs = [];
      this.processing = true; // 2重submit防止
      axios.post(API_URL.COLLECTOR.POST2, this.postData)
        .then(() => {
          // tr-001へ遷移
          this.$cookies.set(COOKIE_INFO.MESSAGE, "運搬事業者情報を登録しました。");
          this.isDisplayRegisterModal ?  this.$router.push('/operate') : this.$router.push(`/collector/cbacontract/input/${this.collectorId}`);
        })
        .catch((err) => {
          this.errMsgs.push(err.response && err.response.data ? err.response.data.message : err.message);
          this.processing = false; // 2重submit解除
          this.isDisplayRegisterModal = false;
          this.isDisplayNextModal = false;
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },

    /**
     * formデータをフォーマット
     * 不要なformId、disposalSiteDataを削除
     * 都道府県をIDに変換
     * フォーマットされたデータを返す(Promise)
     */
    formatPostData() {
      return new Promise((resolve) => {
        let newData = JSON.parse(JSON.stringify(this.form)); // deep copy
        newData.collectCompanyId = this.collectorId;

        newData.collectFactoryList.forEach(factory => {
          // 住所の全角変換
          factory.addressInfo.address = this.toFullWidthStringForAddressInfo(factory.addressInfo.address);
          factory.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(factory.addressInfo.buildingName);
          if (!factory.fax) delete factory.fax;
          this.prefectures.map((prefecture) => {
            if (prefecture.name === factory.addressInfo.selectedPrefectureTemp) {
              // delete this.form.addressInfo.selectedPrefectureTemp;
              factory.addressInfo.prefecturesId = prefecture.id;
            }
          });
          if (factory.collectOfficeList) {
            factory.collectOfficeList.forEach(collectOffice => {
              collectOffice.addressInfo.address = this.toFullWidthStringForAddressInfo(collectOffice.addressInfo.address);
              collectOffice.addressInfo.buildingName = this.toFullWidthStringForAddressInfo(collectOffice.addressInfo.buildingName);
            })
          }
        });
        resolve(newData);
      });
    },

    // 次へ押下
    async checkRequiredHandler(isNextCBAContract) {
      this.errMsgs = [];
      this.duplicatedNameInfoList = [];
      this.duplicationNameCheckInfoList = [];

      const isValid = await this.runValidate();
      if (!isValid) {
        this.processing = false;
        this.errMsgs.push("入力に誤りがあります。");
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }

      if (this.form.collectFactoryList.length) {
        this.form.collectFactoryList.forEach((cf) => {
          this.duplicationNameCheckInfoList.push({
            duplicationNameCheckTargetId: this.duplicationNameCheckTarget[5].targetId,
            name: cf.name
          });
          if (cf.collectOfficeList && cf.collectOfficeList.length) {
            cf.collectOfficeList.forEach((co) => {
              this.duplicationNameCheckInfoList.push({
                duplicationNameCheckTargetId: this.duplicationNameCheckTarget[6].targetId,
                name: co.name,
              });
            });
          }
        }
        )};
      this.checkDuplicationName(this.duplicationNameCheckInfoList)

      // 都道府県をIDに変える、不要なプロパティ消す
      this.formatPostData().then((res) => {
        isNextCBAContract ? this.isDisplayNextModal = true : this.isDisplayRegisterModal = true;
        this.postData = res;
      });
    },
    // 戻るボタン押下
    backHandler() {
      const redirectFrom = "step2-input";
      this.$router.push(`/collector/step1/edit/${this.collectorId}?from=${redirectFrom}`);
    },
    // モーダル内戻る押下
    closeConfirmModalHandler() {
      this.isDisplayRegisterModal = false;
      this.isDisplayNextModal = false;
      this.postData = null;
    },
    // 登録ボタン押下
    // 続けてCBA加入登録モーダルのはい押下時
    registerHandler() {
      this.postForm();
    },
  },

  mounted() {
    this.collectorId = Number(this.$route.query.collectorId);
    if (this.collectorId) {
      this.getCollectorApi(this.collectorId);
    } else {
      this.$router.push('/collector/step1/input');
    }
    this.getPrefecturesApi();
  },
};
</script>
