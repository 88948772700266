import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      routeInfo: {
        routeId: 0,
        routeName: "",
        isFavorite: true,
        generateStoreInfo: {
          id: 0,
          name: ""
        },
        isChangeStore: true,
        wasteTypeInfo: {
          id: 0,
          name: ""
        },
        manifestTypeInfo: {
          id: 0,
          name: ""
        },
        jwnetItemTypeInfo: {
          isSpecial: true,
          isHarmful: true,
          id: 0,
          name: ""
        },
        cbaItemInfo: {
          id: 0,
          name: ""
        },
        constitutionItemInfo: [
          {
            id: 0,
            name: ""
          }
        ],
        wasteNameInfo: {
          id: 0,
          name: ""
        },
        plansQuantity: 0,
        plansWasteUnitType: {
          id: 0,
          name: ""
        },
        limitQuantity: 0,
        limitWasteUnitType: {
          id: 0,
          name: ""
        },
        quantityConfirmBusinessType: {
          id: 0,
          name: ""
        },
        shapeType: {
          id: 0,
          name: ""
        },
        disposalWayType: {
          id: 0,
          name: ""
        },
        harmfulInfo: [
          {
            id: 0,
            name: ""
          }
        ],
        shapeQuantity: 0,
        collectUnitPriceList: [
          {
            sectionNo: 1,
            collectUnitPrice: 0,
            collectPriceWasteUnitTypeId: 0
          }
        ],
        disposalUnitPrice: 0,
        disposalPriceWasteUnitType: {
          id: 0,
          name: ""
        },
        valuablesUnitPrice: 0,
        conversionFactorKg: 0,
        conversionFactorM3: 0,
        conversionFactorLiter: 0,
        conversionFactorUnitNumber: 0,
        collectTargetQuantityTypeInfo: {
          id: 0,
          name: ""
        },
        disposalTargetQuantityTypeInfo: {
            id: 0,
            name: ""
          },
        contactNoInfo: [
          {
            id: 0,
            contactNo: ""
          }
        ],
        remarksInfo: [
          {
            id: 0,
            remarks: ""
          }
        ],
        wdsFileName: "",
        routeCollectCompanyInfo: [
          {
            collectCompanyInfo: {
              id: 0,
              name: ""
            },
            collectFactoryInfo: {
              id: 0,
              name: "",
              jwnetId: ""
            },
            loadingPlaceList: [
              {
                licenceNo: 0,
                licenceMunicipalityName: "",
                licenceExpiredDate: "2021-08-11"
              }
            ],
            unloadingPlaceList: [
              {
                licenceNo: 0,
                licenceMunicipalityName: "",
                licenceExpiredDate: "2021-08-11"
              }
            ],
            destinationInfo: {
              destinationTypeInfo: {
                id: 0,
                name: ""
              },
              id: 0,
              name: "",
              jwnetId: "",
              storeCode: "",
              addressInfo: {
                zipCode: "",
                prefecturesId: 0,
                prefecturesName: "",
                address: "",
                buildingName: ""
              }
            },
            transshipmentJwnetItem: [
              {
                id: 0,
                name: ""
              }
            ]
          }
        ],
        routeDisposalCompanyInfo: {
          disposalCompanyInfo: {
            id: 0,
            name: ""
          },
          disposalLicenceInfo: [
            {
              licenceId: 0,
              licenceNo: "",
              licenceExpiredDate: "2021-08-11",
              municipalityName: ""
            }
          ],
          destinationInfo: {
            destinationTypeInfo: {
              id: 0,
              name: ""
            },
            id: 0,
            name: "",
            jwnetId: "",
            storeCode: "",
            addressInfo: {
              zipCode: "",
              prefecturesId: 0,
              prefecturesName: "",
              address: "",
              buildingName: ""
            }
          },
          disposalJwnetItem: [
            {
              id: 0,
              name: ""
            }
          ]
        },
        labelInfo: [
          {
            labelId: 0,
            labelHeader: "",
            labelTypeId: 0,
            labelDefaultValue: "",
            labelUnit: ""
          }
        ],
        isAutoApproval: true,
        isAutoIssueAfterCollectionDate: true,
        autoIssueExecDateType: {
          id: 0,
          name: ""
        },
        autoIssueExecTime: "",
        isCollectReportAlert: true,
        isAutoIssue: true,
        isDisposalReportAlert: true,
        isActive: true,
        memberInfo: {
          registerMember:{
          name:""
          },
          reportMember:{
          name:""
          }
        },
        checkSheetInfo: {
          registerJwnet: false,
          generatorEmail:{
              mail:""
          },
          collectorEmail:{
              mail:""
          },
          disposerEmail:{
              mail:""
          },
          emails:[
              {email:""},
              {email:""},
              {email:""},
              {email:""},
              {email:""}
          ],
          qrInfos:[
              {qrInfo:""},
              {qrInfo:""}
          ]
        },
      },
      wasteUnitTypes: [],
      quantityConfirmMembers: [],
      shapeTypes: [],
      disposalWayTypes: [],
      harmfulTypes: [],
      targetQuantityTypes: [],
      wasteTypeInfo: {},
      manifestTypeInfo: {},
      notiMailList: [
        {email:""},
        {email:""},
        {email:""},
      ]
    };
  },
  methods: {
    routeDetailApi(routeId) {
      return axios
        .get(API_URL.ROUTE.DETAIL + routeId)
        .then((res) => {
          const data = res.data;
          this.routeInfo = data;
          this.isFavorite = data.isFavorite;

          // 編集可能なデータのみ格納 - 基本情報
          this.formEdit.routeName = data.routeName;
          this.formEdit.isChangeStore = data.isChangeStore;
          this.formEdit.detailWasteName = data.detailWasteName;
          this.formEdit.plansQuantity = data.plansQuantity;
          this.formEdit.plansWasteUnitTypeId = data.plansWasteUnitType ? data.plansWasteUnitType.id : 0;
          this.formEdit.limitQuantity = data.limitQuantity;
          this.formEdit.limitWasteUnitTypeId = data.limitWasteUnitType ? data.limitWasteUnitType.id : 0;
          if (data.quantityConfirmBusinessType) {
            this.formEdit.quantityConfirmBusinessTypeId = data.quantityConfirmBusinessType.id;
          }
          this.formEdit.shapeTypeId = data.shapeType ? data.shapeType.id : null;
          this.formEdit.shapeQuantity = data.shapeQuantity;
          this.formEdit.disposalWayTypeId = data.disposalWayType ? data.disposalWayType.id : null;
          this.formEdit.harmfulInfo = [];
          // 有害物質リスト
          if (data.harmfulInfo.length) {
            data.harmfulInfo.forEach((elem) => {
              this.formEdit.harmfulInfo.push({ harmfulTypeId: elem.id });
            });
          }
          // 運搬単価
          this.formEdit.collectUnitPriceList = [];
          this.validate.collectUnitPriceList = [];
          if (data.collectUnitPriceList.length > 0) {
            data.collectUnitPriceList.forEach((c) => {
              this.formEdit.collectUnitPriceList.push({
                sectionNo: c.sectionNo,
                collectUnitPrice: c.collectUnitPrice,
                collectPriceWasteUnitTypeId: c.collectPriceWasteUnitType ? c.collectPriceWasteUnitType.id : 0
              });
              this.validate.collectUnitPriceList.push({ isValid: true, errMsg: "" });
            });
          }
          this.formEdit.collectConversionFactor = data.collectConversionFactor;
          this.formEdit.disposalUnitPrice = data.disposalUnitPrice;
          this.formEdit.disposalPriceWasteUnitTypeId = data.disposalPriceWasteUnitType ? data.disposalPriceWasteUnitType.id : 0;
          this.formEdit.valuablesUnitPrice = data.valuablesUnitPrice;
          this.formEdit.disposalConversionFactor = data.disposalConversionFactor;
          this.formEdit.conversionFactorKg = data.conversionFactorKg;
          this.formEdit.conversionFactorM3 = data.conversionFactorM3;
          this.formEdit.conversionFactorLiter = data.conversionFactorLiter;
          this.formEdit.conversionFactorUnitNumber = data.conversionFactorUnitNumber;
          this.formEdit.collectTargetQuantityTypeId = data.collectTargetQuantityTypeInfo ? data.collectTargetQuantityTypeInfo.id : 0;
          this.formEdit.disposalTargetQuantityTypeId = data.disposalTargetQuantityTypeInfo ? data.disposalTargetQuantityTypeInfo.id : 0;
          // 連絡番号
          this.formEdit.routeContactNoList = [];
          this.validate.routeContactNoList = [];
          data.contactNoInfo.forEach((elem) => {
            this.formEdit.routeContactNoList.push({ id: elem.id, contactNo: elem.contactNo });
            this.validate.routeContactNoList.push({ isValid: true, errMsg: "" });
          });
          while (this.formEdit.routeContactNoList.length < 3) {
            this.formEdit.routeContactNoList.push({ id: null, contactNo: "" });
            this.validate.routeContactNoList.push({ isValid: true, errMsg: "" });
          }
          // 備考
          this.formEdit.routeRemarksList = [];
          this.validate.routeRemarksList = [];
          data.remarksInfo.forEach((elem) => {
            this.formEdit.routeRemarksList.push({ id: elem.id, remarks: elem.remarks });
            this.validate.routeRemarksList.push({ isValid: true, errMsg: "" });
          });
          while (this.formEdit.routeRemarksList.length < 5) {
            this.formEdit.routeRemarksList.push({ id: null, remarks: "" });
            this.validate.routeRemarksList.push({ isValid: true, errMsg: "" });
          }
          this.formEdit.wdsFileName = data.wdsFileName;
          // 編集可能なデータのみ格納 - ラベル
          this.formEdit.labelList = [];
          this.validate.labelList = [];
          if (data.labelInfo.length > 0) {
            data.labelInfo.forEach((elem) => {
              this.formEdit.labelList.push({
                labelHeader: elem.labelHeader,
                labelTypeId: elem.labelTypeId,
                labelDefaultValue: elem.labelDefaultValue,
                labelUnit: elem.labelUnit,
              });

              this.validate.labelList.push({
                labelHeader: { isValid: true, errMsg: "" },
                labelDefaultValue: { isValid: true, errMsg: "" },
                labelUnit: { isValid: true, errMsg: "" },
              });
            });
          }

          // 編集可能なデータのみ格納 - 自動設定
          this.formEdit.isAutoApproval = data.isAutoApproval;
          this.formEdit.isAutoIssueAfterCollectionDate = data.isAutoIssueAfterCollectionDate;
          if (data.autoIssueExecDateType) {
            this.formEdit.autoIssueExecDateTypeId = data.autoIssueExecDateType.id;
            this.formEdit.autoIssueExecTime = data.autoIssueExecTime;
          }
          this.formEdit.isAutoIssue = data.isAutoIssue;
          this.formEdit.isNecessityCommonCollectReport = data.isNecessityCommonCollectReport;
          this.formEdit.isCollectReportAlert = data.isCollectReportAlert;
          this.formEdit.isDisposalReportAlert = data.isDisposalReportAlert;

          // 担当者情報
          this.formEdit.registMemberName = data.registMemberName;
          this.validate.registMemberName = {};
          this.formEdit.generateMemberName = data.generateMemberName;
          this.validate.generateMemberName = {};
          this.formEdit.collectorRegistType = data.routeDeliveryConfirmation.collectorRegistType;

          // 受渡確認票の確認できる項目
          this.routeDeliveryConfirmation = data.routeDeliveryConfirmation;

          //最終処分場リスト
          this.finalDisposalSiteList = data.finalDisposalSiteList

          //予約・報告通知メール
          let notiMailList = []
          let notiMailValidateList = []
    
          notiMailList.push(data.notiMail1)
          notiMailValidateList.push({ isValid: true, errMsg: "" })
    
          notiMailList.push(data.notiMail2)
          notiMailValidateList.push({ isValid: true, errMsg: "" })
    
          notiMailList.push(data.notiMail3)
          notiMailValidateList.push({ isValid: true, errMsg: "" })

          this.formEdit.notiMailList = notiMailList
          this.validate.notiMailList = notiMailValidateList

          // 予約マニフェスト番号取得通知フラグ
          this.formEdit.isManifestNoRegistAlert = data.isManifestNoRegistAlert
          // 交付期限通知フラグ
          this.formEdit.isIssueDeadlineAlert = data.isIssueDeadlineAlert
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 404) {
              this.$router.push("/error/404");
              return;
            }
          }
          console.log(err);
        });
    },
    wasteUnitApi() {
      return axios
        .get(API_URL.DOMAIN.WASTE_UNIT)
        .then((res) => {
          this.wasteUnitTypes = res.data.wasteUnitTypes;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    quantityConfirmMembersApi(routeId) {
      return axios
        .get(`${API_URL.MANIFEST.CONFIRM_MEMBER}/${routeId}`)
        .then((res) => {
          this.quantityConfirmMembers = res.data.quantityConfirmMembers;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート荷姿選択肢取得API    API_URL.ROUTE.ROUTE_SHAPE_TYPE
    shapeTypesApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_SHAPE_TYPE + routeId)
        .then((res) => {
          this.shapeTypes = res.data.shapeTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // ルート処分方法選択肢取得API    API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE
    disposalWayTypesApi(routeId) {
      axios
        .get(API_URL.ROUTE.ROUTE_DISPOSAL_WAY_TYPE + routeId)
        .then((res) => {
          this.disposalWayTypes = res.data.disposalWayTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 有害物質名選択肢取得API    API_URL.DOMAIN.HARMFUL_TYPE
    getharmfulTypes() {
      axios
        .get(API_URL.DOMAIN.HARMFUL_TYPE)
        .then((res) => {
          this.harmfulTypes = res.data.harmfulTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    targetQuantityTypesApi() {
      return axios
        .get(API_URL.DOMAIN.TARGET_QUANTITY_TYPE)
        .then((res) => {
          this.targetQuantityTypes = res.data.targetQuantityTypeList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
